/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { message } from 'antd';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import * as Yup from 'yup';

import errorHandler from '~/Utils/errorHandler';

import Logo from '~/components/Logo';
import FormControl from '~/components/Form/FormControl';
import BaseLayout from '~/pages/_layouts/base';
import api from '~/services/api';
import Button from '~/components/Button';

import { Container, ButtonGlobalStyle } from './styles';

const schema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
  password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Senhas diferentes'),
});

export default function Remember(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { token } = props.match.params;

  async function handleSubmit(values, { setErrors }) {
    try {
      await api.put(`/password/reset/${token}`, values);
      message.success(t('messages:successRedefined'));
      history.push('/');
    } catch (error) {
      setErrors(errorHandler(error));
    }
  }

  return (
    <BaseLayout>
      <ButtonGlobalStyle />

      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{}}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ errors, isSubmitting }) => (
          <Container>
            <Logo isLogin height="45px" width="202px" margin="0 0 40px 0" />
            <FormControl field="email" error={errors.email}>
              <Input type="email" name="email" placeholder={t('fields:reset.email.placeholder')} />
            </FormControl>
            <FormControl field="password" rror={errors.password}>
              <Input type="password" name="password" placeholder={t('fields:reset.password.placeholder')} />
            </FormControl>
            <FormControl field="password_confirmation">
              <Input
                type="password"
                name="password_confirmation"
                placeholder={t('fields:reset.passwordConfirmation.placeholder')}
              />
            </FormControl>
            <Button size="large" block type="submit" loading={isSubmitting} color="primary">
              {t('fields:reset.submit')}
            </Button>
            <Link to="/">{t('screens:resetPassword.back')}</Link>
          </Container>
        )}
      </Formik>
    </BaseLayout>
  );
}
