/* eslint-disable react/prop-types */
import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import Home from '~/pages/Home';
import Congress from '~/pages/Congress';
import Course from '~/pages/Course';
import Abstract from '~/pages/Abstract';
import AbstractForm from '~/pages/Abstract/form';
import Subscription from '~/pages/Subscription';
import ShowSubscription from '~/pages/Subscription/showSubscription';
import SubscriptionCourse from '~/pages/Subscription/course';
import MySubscriptions from '~/pages/MySubscriptions';
import Movement from '~/pages/Movement';
import MyOrders from '~/pages/MyOrders';
import Payment from '~/pages/Payment';
import NewCourse from '~/pages/NewCourse';

export default function OtherRoutes({ other }) {
  return (
    <BrowserRouter>
      <Route exact path="/" component={Home} />

      <Route exact path="/congressos" component={Congress} />
      <Route exact path="/cursos" component={Course} />
      <Route exact path="/congressos/cursos/:id" component={NewCourse} />

      <Route exact path="/inscricao/:slug" component={Subscription} />
      <Route exact path="/inscricao/detalhes/:slug" component={ShowSubscription} />

      <Route exact path="/inscricao/curso/:slug" component={SubscriptionCourse} />

      <Route exact path="/resumos" component={Abstract} />
      <Route exact path="/resumos/novo" component={AbstractForm} />
      <Route exact path="/resumos/:id/edicao" component={AbstractForm} />

      <Route exact path="/minhas-inscricoes" component={MySubscriptions} />
      <Route exact path="/financeiro" component={Movement} />

      <Route exact path="/pagamento" component={Payment} />
      <Route exact path="/minhas-ordens" component={MyOrders} />

      {other}
    </BrowserRouter>
  );
}

OtherRoutes.propTypes = {
  other: PropTypes.oneOfType([PropTypes.any]),
};

OtherRoutes.defaultProps = {
  other: null,
};
