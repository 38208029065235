import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ddd;
  border-radius: 6px;
  padding: 40px;

  .content {
    padding: 1rem 2rem 3rem 2rem;
  }

  h1 {
    text-transform: initial;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 2rem;
    color: ${darken(0.5, '#a1a1a1')};
    z-index: 10;
  }

  h2 {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Montserrat';
    color: #a1a1a1;
  }

  .ll-btn {
    margin-top: 20px;
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {
    .error-code {
      left: -45vw;
      font-size: 80vw;
      transform: rotate(90deg);
      margin-bottom: 0;
    }
  }
`;
