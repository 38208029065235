import refuseCodes from '~/data/refuseCodes.json';
import api from '~/services/api';

export const statusColor = status => {
  switch (status) {
    case 'AUTHORIZED':
    case 'PAID':
    case 'AVAILABLE':
    case 'DEBITED':
      return 'green';
    case 'IN_ANALYSIS':
    case 'WAITING':
    case 'UP_FOR_GRABS':
    case 'TEMPORARY_RETENTION':
      return 'orange';
    case 'EXPIRED':
    case 'CANCELED':
    case 'DECLINED':
    case 'RETURNED':
      return 'red';
    default:
      return 'GREY';
  }
};

export const translateNameStatus = status => {
  switch (status) {
    case 'AUTHORIZED':
      return 'PAGO'; // 'AUTORIZADO';
    case 'PAID':
      return 'PAGO';
    case 'IN_ANALYSIS':
      return 'EM ANÁLISE';
    case 'WAITING':
      return 'AGUARDANDO';
    case 'EXPIRED':
      return 'EXPIRADO';
    case 'CANCELED':
      return 'CANCELADO';
    case 'DECLINED':
      return 'NEGADO';
    // pos-transactional
    case 'AVAILABLE':
      return 'PAGO'; // 'DISPONÍVEL';
    case 'UP_FOR_GRABS':
      return 'EM DISPUTA';
    case 'RETURNED':
      return 'DEVOLVIDO';
    case 'DEBITED':
      return 'PAGO'; // 'DEBITADO';
    case 'TEMPORARY_RETENTION':
      return 'RETENÇÃO TEMPORÁRIA';
    case null || undefined || '':
      return 'N/A';
    default:
      return status.toUpperCase();
  }
};

export const getRefuseMessage = code => {
  const message2 = refuseCodes.find(refuse => refuse.code.toString() === code.toString());
  if (message2) {
    return message2?.message;
  }
  return 'N/A';
};

export const handleReceiptClick = (price, description, date, referenceId) => {
  // Faz a requisição POST para a API
  const itemType = JSON.parse(referenceId).t;

  api
    .post('/orders/receipt', { price, description, date, itemType })
    .then(response => {
      // Abre o PDF em uma nova guia do navegador
      window.open(response.data.original, '_blank');
    })
    .catch(error => {
      // Lida com erros da requisição
      console.error('Erro ao enviar requisição de recibo:', error);
    });
};

export const handleReceiptClickMovement = (price, description, date, name, cpf, isMovement) => {
  api
    .post('/orders/receipt', { price, description, date, name, cpf, ...(isMovement && { isMovement }) })
    .then(response => {
      // Abre o PDF em uma nova guia do navegador
      window.open(response.data.original, '_blank');
    })
    .catch(error => {
      // Lida com erros da requisição
      console.error('Erro ao enviar requisição de recibo:', error);
    });
};
