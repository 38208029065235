import React from 'react';
import { Tag } from 'antd';
import { FaCoins } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import Button from '~/components/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function Annuity({ recordData }) {
  const { t } = useTranslation();
  const history = useHistory();
  console.log('recordData received in Annuity', recordData);

  if (recordData?.isAssociated) {
    return (
      <>
        <Tag color="green">
          <b>{t('fields:status')}</b> {t('fields:associated')}
        </Tag>
        <Tag color="green">
          <b>{t('fields:validity')}</b> {new Date(recordData.validity).toLocaleDateString()}
        </Tag>
        <Button
          color="primary"
          onClick={() => {
            history.push({
              pathname: '/pagamento',
              state: { type: 'annuity', typeId: recordData?.id },
            })
          }}
          style={{ marginTop: '0.5rem' }}
        >
          <FaCoins style={{ fontSize: '14px' }} /> Pagar próxima anuidade
        </Button>
      </>
    );
  }

  return (
    <>
      <Tag color="red">{t('fields:non_associated')}</Tag>
      <Button
        color="primary"
        onClick={() => {
          history.push({
            pathname: '/pagamento',
            state: { type: 'annuity', typeId: recordData?.id },
          })
        }}
        style={{ marginTop: '0.5rem' }}
      >
        <FaCoins style={{ fontSize: '14px' }} /> Pagar
      </Button>
    </>
  );
}

Annuity.propTypes = {
  recordData: PropTypes.shape({
    isAssociated: PropTypes.bool,
    validity: PropTypes.string,
    id: PropTypes.number,
  }),
};

Annuity.defaultProps = {
  recordData: {},
};