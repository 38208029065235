import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @media screen and (max-width: 575px) {
      .ant-page-header-heading-title {
        font-size: 100%;
      }
  }
  @media screen and (max-width: 380px) {
    .ant-tag.ant-tag-gold{
      font-size: 65%;
    }
  }
`;
