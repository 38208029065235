import styled, { createGlobalStyle } from 'styled-components';
import { Upload } from 'antd';

export const Global = createGlobalStyle`
  .address-grid .ant-col{
    padding: unset !important;
  }
  .profile-info-bool .form-control{
    align-items: center;
  }
`;

export const DivTitle = styled.h1`
  color: ${(props) => props.theme.color1};
`;

export const Container = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    border-radius: 50%;
  }
`;

export const GlobalStyles = createGlobalStyle`
  .ant-card-hoverable {
    border: unset;
  }
  .ant-card-head{
    background: ${(props) => props.theme.color1};

    .ant-typography{
      color: #fff;
    }
  }
`;
