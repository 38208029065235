/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Col, Tag, Card, Typography } from 'antd';
import DefaultLayout from '~/pages/_layouts/full/';
import PageTitle from '~/components/PageTitle';
import Row from '~/components/Row';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import { GlobalStyle } from './style';

export default function ShowSubscription(props) {

  const [loading, setLoading] = useState(false);
  const [congress, setCongress] = useState([]);
  const [recordData, setRecordData] = useState({});
  const [course, setCourse] = useState([]);
  const { Title, Paragraph } = Typography;

  const handleView = async () => {
    setLoading(true);
    try {
      // eslint-disable-next-line react/prop-types
      const { slug } = props.match.params;
      const response = await api.post('subscriptions/show', {
        congress_slug: slug,
      });
      const data = JSON.parse(response.data);
      setRecordData(data);

    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const isCheckoutZero = async (type, id) => {
    setLoading(true);
    try {
      const response = await api.post('/checkout/getDataDetails', { type, id });
      const { amount } = response.data.items[0];
      const isZeroAmount = parseFloat(amount / 100).toFixed(2) === '0.00';
      return isZeroAmount;
    } catch (error) {
      errorHandler(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const fetchCongress = async () => {
    setLoading(true);
    try {
      // eslint-disable-next-line react/prop-types
      const { slug } = props.match.params;
      const response = await api.get(`/congresses/show/${slug}`);
      const data = JSON.parse(response.data);
      const isZero = await isCheckoutZero('congress', data.id);
      data.free = isZero;
      setCongress(data);

      if (data.id) {
        const dataCourse = await api.get(`/courses/by-congress/${data.id}`);
        const courseData = dataCourse.data;

        setCourse(courseData);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleView();
    fetchCongress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultLayout>
      <PageTitle title={!loading && `Inscrição em ${congress.description && congress.description.toUpperCase()}`} />
      <GlobalStyle />
      <>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h3>
              Inscrição:{' '}
              <Tag color="gold">
                <b>{recordData.uuid && recordData.uuid.toUpperCase()}</b>
              </Tag>
            </h3>
            <h3>
              Inscrição realizada em:{' '}
              <b>
                {`${new Date(recordData.created_at).toLocaleDateString()} às
                              ${new Date(recordData.created_at).toLocaleTimeString()}`}
              </b>
            </h3>
            {recordData.certificate_issued_at && (
              <h3>
                Certificado emitido em:{' '}
                <b>
                  {`${new Date(recordData.certificate_issued_at).toLocaleDateString()} às
                              ${new Date(recordData.certificate_issued_at).toLocaleTimeString()}`}
                </b>
              </h3>
            )}
            <h3>
              Pago:{' '}
              <Tag color={recordData.paid_out ? 'green' : 'red'}>
                <b>{recordData.paid_out ? 'SIM' : 'NÃO'}</b>
              </Tag>
            </h3>
            <h3>
              Isento:{' '}
              <Tag color={recordData.tax_exempt ? 'green' : 'red'}>
                <b>{recordData.tax_exempt ? 'SIM' : 'NÃO'}</b>
              </Tag>
            </h3>
            <h3>
              Nome para o crachá: <b>{recordData.badge_name}</b>
            </h3>
            <h3>
              Nome da instituição para o crachá: <b>{recordData.badge_institution}</b>
            </h3>
            <h3>
              Estrangeiro: <b>{recordData.foreign ? 'SIM' : 'NÃO'}</b>
            </h3>
            <h3>
              Tradutor: <b>{recordData.translator ? 'SIM' : 'NÃO'}</b>
            </h3>
          </Col>
        </Row>
      </>
      {course.length > 0 && (
        <>
          <Typography style={{ width: '100%', marginTop: '60px' }}>
            <Title style={{ color: '#a4c75b' }}>Este congresso possui cursos!</Title>
            <Paragraph>
              Ao se inscrever no nosso congresso, você terá acesso aos cursos oferecidos dentro do evento. Após se
              inscrever no congresso, você poderá se inscrever nos cursos disponíveis. Essa é uma ótima oportunidade
              para expandir seus conhecimentos e se desenvolver ainda mais na sua área de interesse.
            </Paragraph>
          </Typography>
          <Card
            size="small"
            title={<strong style={{ color: '#a4c75b' }}>Curso(s)</strong>}
            style={{ width: '100%', marginTop: '30px' }}
          >
            {course.map((courseItem) => (
              <Card
                size="small"
                title={<strong style={{ color: '#a4c75b' }}>{courseItem.description}</strong>}
                style={{ width: '100%' }}
              >
                <Typography.Title key={courseItem.id} level={5} style={{ margin: 0, fontWeight: 'normal' }}>
                  {courseItem.professor && (
                    <p>
                      <strong style={{ color: '#a4c75b' }}>Professor:</strong> {courseItem.professor}
                    </p>
                  )}
                  {courseItem.info && (
                    <p>
                      <strong style={{ color: '#a4c75b' }}>Informações:</strong> {courseItem.info}
                    </p>
                  )}
                  {courseItem.description && (
                    <p>
                      <strong style={{ color: '#a4c75b' }}>Descrição:</strong> {courseItem.description}
                    </p>
                  )}
                </Typography.Title>
              </Card>
            ))}
          </Card>
        </>
      )}
    </DefaultLayout>
  );
}
