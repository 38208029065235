import React, { useState, useEffect } from 'react';
import { message, Spin, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import DefaultLayout from '~/pages/_layouts/full';
import errorHandler from '~/Utils/errorHandler';
import { Table, TableHeader } from '~/components/Table';
import { decrypt } from '~/Utils/index';
import PageTitle from '~/components/PageTitle';
import api from '~/services/api';
import Pagination from '~/components/Pagination';
import Box from '~/components/Box';
import { Global } from './styles';
import { expandedRowRender, tableColumns } from './table';

export default function MyOrders() {
  const { t } = useTranslation();
  const [personOrders, setPersonOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const person =
    JSON.parse(decrypt(localStorage.getItem('@Portal:person'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@Portal:person')));

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/orders/movements/person`, {
        params: {
          page,
          perPage,
          search,
        },
      });

      if (data.data?.length > 0) {
        console.log(data.data);
        setPersonOrders(data.data);
        setMeta(data.meta);
      } else {
        message.info('Nenhuma inscrição encontrada!');
        setPersonOrders([]);
      }
    } catch (error) {
      errorHandler(error);
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchScreenData();
  }, [page, perPage, search]);

  return (
    <DefaultLayout>
      <Global />
      <PageTitle title={person ? t('screens:orders.data.mine') : null} />
      <Spin spinning={loading}>
        <Box>
          <TableHeader>
            <Input.Search onSearch={value => setSearch(value)} className="search-field" />
          </TableHeader>
          <Table
            columns={tableColumns(t)}
            rowKey="id"
            expandable={{ expandedRowRender }}
            loading={loading}
            dataSource={personOrders}
            scroll={{ x: 800 }}
            pagination={false}
          />
          <Pagination
            onChange={value => {
              setPage(value);
            }}
            meta={meta}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </Box>
      </Spin>
    </DefaultLayout>
  );
}
