/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, Switch } from 'formik-antd';
import { Col, message, Spin, Tag } from 'antd';

import { useHistory } from 'react-router-dom';
import { FaCoins } from 'react-icons/fa';

import DefaultLayout from '~/pages/_layouts/full/';
import PageTitle from '~/components/PageTitle';
import Row from '~/components/Row';
import Box from '~/components/Box';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import FormActions from '~/components/Form/FormActions';

import checkout from '~/services/checkout';
import { decrypt } from '~/Utils';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import { loadPagseguroScripts } from '~/Utils/pagseguro';
import { GlobalStyle } from './style';

export default function SubscriptionCourse(props) {
  const person = JSON.parse(decrypt(localStorage.getItem('@Portal:person')));

  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState([]);
  const [recordData, setRecordData] = useState({});
  const [showForm, setShowForm] = useState(true);
  const history = useHistory();

  const handleView = async (showMessage = true) => {
    setLoading(true);

    try {
      // eslint-disable-next-line react/prop-types
      const { slug } = props.match.params;
      const response = await api.post('subscriptions/show', {
        course_slug: slug,
      });
      const data = JSON.parse(response.data);

      setRecordData(data);

      if (data.uuid) {
        if (showMessage) {
          message.success('Você já está inscrito neste curso!');
        }
        setShowForm(false);

        if (!document.getElementById('pgScript')) {
          await loadPagseguroScripts();
        }
      }
    } catch (error) {
      errorHandler(error);
    }

    setLoading(false);
  };

  const handleCheckoutCourse = async (id) => {
    setLoading(true);
    const data = await checkout('course', id);

    if (data) {
      // eslint-disable-next-line no-undef
      if (typeof PagSeguroLightbox !== 'function' || PagSeguroLightbox === undefined) {
        window.location.replace(data.url);
      }

      // eslint-disable-next-line no-undef
      const lightbox = PagSeguroLightbox(data.code);
      if (!lightbox) {
        window.location.replace(data.url);
      }
    }

    setLoading(false);
  };

  const fetchCourse = async () => {
    setLoading(true);
    try {
      // eslint-disable-next-line react/prop-types
      const { slug } = props.match.params;
      const response = await api.get(`/courses/${slug}`);
      const data = JSON.parse(response.data);
      setCourse(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    // console.log(values, 'setErrors', setErrors);
    try {
      await api.post('/subscriptions', {
        congress_id: course.congress.id,
        person_id: person.id,
        course_id: course.id,
        badge_name: values.badge_name,
        badge_institution: values.badge_institution,
        foreign: values.foreign,
        translator: values.translator,
      });
      message.success('Inscrição realizada com sucesso!');
      handleView(false);
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCourse();
    handleView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    person: person.name,
    congress: course.description,
    badge_name: '',
    badge_institution: '',
    foreign: false,
    translator: false,
  };

  return (
    <DefaultLayout>
      <PageTitle title={!loading && `Inscrição em ${course.description && course.description.toUpperCase()}`} />
      <GlobalStyle />
      <Formik
        onSubmit={handleSave}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ errors }) => (
          <Form>
            <Spin spinning={loading}>
              <Box>
                {showForm && course && person ? (
                  <>
                    <Row>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <FormControl field="person" error={errors.person_id} label="Nome">
                          <Input disabled placeholder={person.name} name="person" />
                        </FormControl>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <FormControl error={errors.course_id} label="Curso" field="course">
                          <Input disabled placeholder={course.description} name="course" />
                        </FormControl>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <FormControl error={errors.congress_id} label="Congresso" field="congress_id">
                          <Input disabled placeholder={course.congress && course.congress.description} name="course" />
                        </FormControl>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <FormControl label="Nome para o crachá" error={errors.badge_name} field="badge_name">
                          <Input name="badge_name" autoComplete="dontshow" />
                        </FormControl>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <FormControl
                          label="Nome da instituição para o crachá"
                          error={errors.badge_institution}
                          field="badge_institution"
                        >
                          <Input name="badge_institution" autoComplete="dontshow" />
                        </FormControl>
                      </Col>
                      <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                        <FormControl field="foreign" error={errors.foreign} label="Estrangeiro?">
                          <Switch name="foreign" />
                        </FormControl>
                      </Col>
                      <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                        <FormControl field="translator" error={errors.translator} label="Tradutor?">
                          <Switch name="translator" />
                        </FormControl>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <h3>
                          Inscrição:{' '}
                          <Tag color="gold">
                            <b>{recordData.uuid && recordData.uuid.toUpperCase()}</b>
                          </Tag>
                        </h3>
                        <h3>
                          Inscrição realizada em:{' '}
                          <b>
                            {`${new Date(recordData.created_at).toLocaleDateString()} às
                              ${new Date(recordData.created_at).toLocaleTimeString()}`}
                          </b>
                        </h3>
                        {recordData.certificate_issued_at && (
                          <h3>
                            Certificado emitido em:{' '}
                            <b>
                              {`${new Date(recordData.certificate_issued_at).toLocaleDateString()} às
                              ${new Date(recordData.certificate_issued_at).toLocaleTimeString()}`}
                            </b>
                          </h3>
                        )}

                        <h3>
                          Pago:{' '}
                          <Tag color={recordData.paid_out ? 'green' : 'red'}>
                            <b>{recordData.paid_out ? 'SIM' : 'NÃO'}</b>
                          </Tag>
                        </h3>

                        <h3>
                          Isento:{' '}
                          <Tag color={recordData.tax_exempt ? 'green' : 'red'}>
                            <b>{recordData.tax_exempt ? 'SIM' : 'NÃO'}</b>
                          </Tag>
                        </h3>

                        <h3>
                          Nome para o crachá: <b>{recordData.badge_name}</b>
                        </h3>
                        <h3>
                          Nome da instituição para o crachá: <b>{recordData.badge_institution}</b>
                        </h3>
                        <h3>
                          Estrangeiro: <b>{recordData.foreign ? 'SIM' : 'NÃO'}</b>
                        </h3>
                        <h3>
                          Tradutor: <b>{recordData.translator ? 'SIM' : 'NÃO'}</b>
                        </h3>
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <FormActions>
                    {showForm ? (
                      <Button type="submit" color="primary">
                        Salvar
                      </Button>
                    ) : (
                      course &&
                      recordData &&
                      !recordData.paid_out &&
                      !recordData.tax_exempt && (
                        <Button onClick={() => handleCheckoutCourse(course.id)} color="primary">
                          <FaCoins style={{ fontSize: '14px' }} /> Pagar
                        </Button>
                      )
                    )}
                    <Button onClick={() => history.push('/cursos')}> Cancelar </Button>
                  </FormActions>
                </Row>
              </Box>
            </Spin>
          </Form>
        )}
      </Formik>
    </DefaultLayout>
  );
}
