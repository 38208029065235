import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function Logo({ margin, height, isLogin }) {
  const defaultIcon = '/assets/images/header-logo.png';

  return (
    <Container className={`sbfv-logo ${isLogin ? 'from-login' : 'from-sys'}`} to="/" margin={margin}>
      {isLogin ? <img src={defaultIcon} alt="SBFV" /> : <img src={defaultIcon} alt="SBFV" />}
      {isLogin ? (
        <span>PORTAL</span>
      ) : (
        <div className="wrapper" style={{ height }}>
          <span>PORTAL</span>
        </div>
      )}
    </Container>
  );
}

Logo.propTypes = {
  height: PropTypes.string,
  margin: PropTypes.string,
  isLogin: PropTypes.bool,
};

Logo.defaultProps = {
  margin: '0',
  height: '0',
  isLogin: false,
};
