import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  PayPalScriptProvider,
  usePayPalCardFields,
  PayPalCardFieldsProvider,
  PayPalButtons,
  PayPalNameField,
  PayPalNumberField,
  PayPalExpiryField,
  PayPalCVVField,
} from '@paypal/react-paypal-js';
import errorHandler from '~/Utils/errorHandler';
import { message } from 'antd';
import api from '~/services/api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function Paypal({ price, setLoading, orderData }) {
  Paypal.propTypes = {
    price: PropTypes.number.isRequired,
    setLoading: PropTypes.func.isRequired,
    orderData: PropTypes.instanceOf.isRequired,
  };

  const history = useHistory();
  const [isPaying, setIsPaying] = useState(false);
  const initialOptions = {
    'client-id': 'AZTSOFNCNRHe1iqHHrhgQKd6Z1D_qAWr-tq_r1MzwVpNNIy0_kf_mjfbZSSCsEn30AbqmS1pFU9dPJ84',
    'enable-funding': 'venmo',
    'disable-funding': '',
    currency: 'USD',
    'data-page-type': 'product-details',
    components: 'buttons,card-fields',
    'data-sdk-integration-source': 'developer-studio',
  };

  const createOrder = async () => {
    try {
      const { data } = await api.post('/orders/paypal', {
        cart: [
          {
            sku: '1blwyeo8',
            quantity: 2,
            price,
          },
        ],
      });

      console.log(data);

      if (data.id) {
        return data.id;
      }

      const errorDetail = data?.details?.[0];
      const errorMessage = errorDetail
        ? `${errorDetail.issue} ${errorDetail.description} (${data.debug_id})`
        : JSON.stringify(data);

      throw new Error(errorMessage);
    } catch (error) {
      console.error(error);
      return `Could not initiate PayPal Checkout... ${error}`;
    }
  };

  const onApprove = async (record) => {
    setLoading(true);
    try {
      const { data } = await api.post(`/orders/paypal/${record.orderID}/capture`, orderData);

      const transaction =
        data?.original.purchase_units?.[0]?.payments?.captures?.[0] ||
        data?.original.purchase_units?.[0]?.payments?.authorizations?.[0];

      if (!transaction || transaction.status === 'DECLINED') {
        const errorMessage = `Transaction ${transaction?.status || 'DECLINED'}: ${transaction?.id}`;
        throw new Error(errorMessage);
      } else {
        message.success(`Transaction ${transaction.status}: ${transaction.id}`);
        history.push('/pagamento/obrigado');
      }
    } catch (error) {
      errorHandler(`Sorry, your transaction could not be processed...${error}`);
    }
    setLoading(false);
  };

  const onError = (error) => {
    errorHandler(error);
  };

  return (
    <div style={{ width: '300px' }}>
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          createOrder={createOrder}
          onApprove={onApprove}
          onError={onError}
          style={{
            shape: 'rect',
            color: 'silver',
            label: 'paypal',
            backgroundColor: 'black',
          }}
        />
        <PayPalCardFieldsProvider
          createOrder={createOrder}
          onApprove={onApprove}
          style={{
            input: {
              'font-size': '12px',
              'font-family': 'courier, monospace',
              'font-weight': 'lighter',
              color: '#ccc',
            },
            '.invalid': { color: 'purple' },
          }}
        >
          <PayPalNameField style={{ input: { color: 'blue' }, '.invalid': { color: 'purple' } }} />
          <PayPalNumberField />
          <PayPalExpiryField />
          <PayPalCVVField />
          <SubmitPayment isPaying={isPaying} setIsPaying={setIsPaying} />
        </PayPalCardFieldsProvider>
      </PayPalScriptProvider>
    </div>
  );
}

const SubmitPayment = ({ isPaying, setIsPaying }) => {
  SubmitPayment.propTypes = {
    isPaying: PropTypes.bool.isRequired,
    setIsPaying: PropTypes.func.isRequired,
  };

  // const { cardFieldsForm, fields } = usePayPalCardFields();
  const { cardFieldsForm } = usePayPalCardFields();

  const handleClick = async () => {
    if (!cardFieldsForm) {
      const childErrorMessage = 'Unable to find any child components in the <PayPalCardFieldsProvider />';

      throw new Error(childErrorMessage);
    }
    const formState = await cardFieldsForm.getState();

    if (!formState.isFormValid) {
      return alert('The payment form is invalid');
    }
    setIsPaying(true);

    return message.success('O pagamento foi feito!');
  };

  return (
    <button
      className={isPaying ? 'btn' : 'btn btn-primary'}
      type="button"
      style={{ float: 'right' }}
      onClick={handleClick}
    >
      {isPaying ? <div className="spinner tiny" /> : 'Pay'}
    </button>
  );
};
