/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, Switch } from 'formik-antd';
import { Col, message, Spin, Tag, Card, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { FaCoins } from 'react-icons/fa';
import DefaultLayout from '~/pages/_layouts/full/';
import PageTitle from '~/components/PageTitle';
import Row from '~/components/Row';
import Box from '~/components/Box';
import Button from '~/components/Button';
import FormControl from '~/components/Form/FormControl';
import FormActions from '~/components/Form/FormActions';
// import checkout from '~/services/checkout';
import { decrypt } from '~/Utils';
import errorHandler from '~/Utils/errorHandler';
import api from '~/services/api';
import { loadPagseguroScripts } from '~/Utils/pagseguro';
import { GlobalStyle } from './style';

export default function Subscription(props) {
  const person = JSON.parse(decrypt(localStorage.getItem('@Portal:person')));

  const [loading, setLoading] = useState(false);
  const [congress, setCongress] = useState([]);
  const [recordData, setRecordData] = useState({});
  const [showForm, setShowForm] = useState(true);
  const [course, setCourse] = useState([]);
  const { Title, Paragraph } = Typography;
  const history = useHistory();

  const handleView = async (showMessage = true) => {
    setLoading(true);
    try {
      // eslint-disable-next-line react/prop-types
      const { slug } = props.match.params;
      const response = await api.post('subscriptions/show', {
        congress_slug: slug,
      });
      const data = JSON.parse(response.data);

      setRecordData(data);

      if (data.uuid) {
        if (showMessage) {
          message.success('Você já está inscrito neste congresso!');
        }
        setShowForm(false);

        if (!document.getElementById('pgScript')) {
          await loadPagseguroScripts();
        }
      }
    } catch (error) {
      errorHandler(error);
    }

    setLoading(false);
  };

  const isCheckoutZero = async (type, id) => {
    setLoading(true);
    try {
      const response = await api.post('/checkout/getDataDetails', { type, id });
      const { amount } = response.data.items[0];
      const isZeroAmount = parseFloat(amount / 100).toFixed(2) === '0.00';
      return isZeroAmount;
    } catch (error) {
      errorHandler(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const fetchCongress = async () => {
    setLoading(true);
    try {
      // eslint-disable-next-line react/prop-types
      const { slug } = props.match.params;
      const response = await api.get(`/congresses/show/${slug}`);
      const data = JSON.parse(response.data);
      const isZero = await isCheckoutZero('congress', data.id);
      data.free = isZero;
      setCongress(data);

      if (data.id) {
        const dataCourse = await api.get(`/courses/by-congress/${data.id}`);
        const courseData = dataCourse.data;

        setCourse(courseData);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      await api.post('/subscriptions', {
        congress_id: congress.id,
        person_id: person.id,
        course_id: null,
        badge_name: values.badge_name,
        badge_institution: values.badge_institution,
        foreign: values.foreign,
        tax_exempt: congress.free,
        translator: values.translator,
      });
      handleView(false);
      message.success('Inscrição realizada com sucesso!');
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  useEffect(() => {
    handleView();
    fetchCongress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    person: person.name,
    congress: congress.description,
    badge_name: '',
    badge_institution: '',
    tax_exempt: false,
    foreign: false,
    translator: false,
  };

  return (
    <DefaultLayout>
      <PageTitle title={!loading && `Inscrição em ${congress.description && congress.description.toUpperCase()}`} />
      <GlobalStyle />
      <Formik
        onSubmit={handleSave}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ errors }) => (
          <Form>
            <Spin spinning={loading}>
              <Box>
                {showForm ? (
                  <>
                    <Row>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <FormControl field="person" label="Nome">
                          <Input disabled placeholder={person.name} name="person" />
                        </FormControl>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <FormControl error={errors.congress_id} label="Congresso" field="congress">
                          <Input disabled placeholder={congress.description} name="congress" />
                        </FormControl>
                      </Col>
                      <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                        <FormControl field="foreign" error={errors.foreign} label="Estrangeiro?">
                          <Switch name="foreign" />
                        </FormControl>
                      </Col>
                      <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                        {congress.translated ? (
                          <FormControl field="translator" error={errors.translator} label="Tradutor?">
                            <Switch name="translator" active={false} />
                          </FormControl>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <FormControl label="Nome para o crachá" error={errors.badge_name} field="badge_name">
                          <Input name="badge_name" autoComplete="dontshow" />
                        </FormControl>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                        <FormControl
                          label="Nome da instituição para o crachá"
                          error={errors.badge_institution}
                          field="badge_institution"
                        >
                          <Input name="badge_institution" autoComplete="dontshow" />
                        </FormControl>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <h3>
                          Inscrição:{' '}
                          <Tag color="gold">
                            <b>{recordData.uuid && recordData.uuid.toUpperCase()}</b>
                          </Tag>
                        </h3>
                        <h3>
                          Inscrição realizada em:{' '}
                          <b>
                            {`${new Date(recordData.created_at).toLocaleDateString()} às
                              ${new Date(recordData.created_at).toLocaleTimeString()}`}
                          </b>
                        </h3>
                        {recordData.certificate_issued_at && (
                          <h3>
                            Certificado emitido em:{' '}
                            <b>
                              {`${new Date(recordData.certificate_issued_at).toLocaleDateString()} às
                              ${new Date(recordData.certificate_issued_at).toLocaleTimeString()}`}
                            </b>
                          </h3>
                        )}

                        <h3>
                          Pago:{' '}
                          <Tag color={recordData.paid_out ? 'green' : 'red'}>
                            <b>{recordData.paid_out ? 'SIM' : 'NÃO'}</b>
                          </Tag>
                        </h3>

                        <h3>
                          Isento:{' '}
                          <Tag color={recordData.tax_exempt ? 'green' : 'red'}>
                            <b>{recordData.tax_exempt ? 'SIM' : 'NÃO'}</b>
                          </Tag>
                        </h3>

                        <h3>
                          Nome para o crachá: <b>{recordData.badge_name}</b>
                        </h3>
                        <h3>
                          Nome da instituição para o crachá: <b>{recordData.badge_institution}</b>
                        </h3>
                        <h3>
                          Estrangeiro: <b>{recordData.foreign ? 'SIM' : 'NÃO'}</b>
                        </h3>
                        <h3>
                          Tradutor: <b>{recordData.translator ? 'SIM' : 'NÃO'}</b>
                        </h3>
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <FormActions>
                    {showForm ? (
                      <Button type="submit" color="primary">
                        Salvar
                      </Button>
                    ) : (
                      congress &&
                      recordData &&
                      !recordData.paid_out &&
                      !recordData.tax_exempt && (
                        // <Button onClick={() => handleCheckoutCongress(congress.id)} color="primary">
                        //   <FaCoins style={{ fontSize: '14px' }} /> Pagar
                        // </Button>
                        <Button
                          onClick={() => {
                            const type = 'congress';
                            const { id } = congress;
                            const typeId = id;
                            history.push({
                              pathname: '/pagamento',
                              state: { type, typeId },
                            });
                          }}
                          color="danger"
                          // style={{ marginLeft: '0.5rem', backgroundColor: 'red' }}
                        >
                          <FaCoins style={{ fontSize: '14px' }} /> Pagar agora
                        </Button>
                      )
                    )}
                    <Button onClick={() => history.push('/congressos')}> Cancelar </Button>
                  </FormActions>
                </Row>
              </Box>
            </Spin>
          </Form>
        )}
      </Formik>
      {course.length > 0 && showForm && (
        <>
          <Typography style={{ width: '100%', marginTop: '60px' }}>
            <Title style={{ color: '#a4c75b' }}>Este congresso possui cursos!</Title>
            <Paragraph>
              Ao se inscrever no nosso congresso, você terá acesso aos cursos oferecidos dentro do evento. Após se
              inscrever no congresso, você poderá se inscrever nos cursos disponíveis. Essa é uma ótima oportunidade
              para expandir seus conhecimentos e se desenvolver ainda mais na sua área de interesse.
            </Paragraph>
          </Typography>
          <Card
            size="small"
            title={<strong style={{ color: '#a4c75b' }}>Curso(s)</strong>}
            style={{ width: '100%', marginTop: '30px' }}
          >
            {course.map((courseItem) => (
              <Card
                size="small"
                title={<strong style={{ color: '#a4c75b' }}>{courseItem.description}</strong>}
                style={{ width: '100%' }}
              >
                <Typography.Title key={courseItem.id} level={5} style={{ margin: 0, fontWeight: 'normal' }}>
                  {courseItem.professor && (
                    <p>
                      <strong style={{ color: '#a4c75b' }}>Professor:</strong> {courseItem.professor}
                    </p>
                  )}
                  {courseItem.info && (
                    <p>
                      <strong style={{ color: '#a4c75b' }}>Informações:</strong> {courseItem.info}
                    </p>
                  )}
                  {courseItem.description && (
                    <p>
                      <strong style={{ color: '#a4c75b' }}>Descrição:</strong> {courseItem.description}
                    </p>
                  )}
                </Typography.Title>
              </Card>
            ))}
          </Card>
        </>
      )}
    </DefaultLayout>
  );
}
