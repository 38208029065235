import { useCallback } from 'react';

export default function useClipboard() {
  const copyToClipboard = useCallback(async (text, setTextCopy) => {
    try {
      await navigator.clipboard.writeText(text);
      setTextCopy(true);
      console.log('Texto copiado para a área de transferência');
    } catch (err) {
      setTextCopy(false);
      console.log('Falha ao copiar o texto', err);
    }
  }, []);
  return copyToClipboard;
}