/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Modal, Spin, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import errorHandler from '~/Utils/errorHandler';
import Row from '~/components/Row';
import { ModalFooter } from '~/components/Modal';

export default function ImageModal({ visible, onClose, image }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchScreenData = async () => {
    try {
      setRecordData({ image });
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (visible && image) {
      fetchScreenData();
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title={recordData.image ? `${t('screens:congresses.modalImageTitle')}` : null}
      onCancel={onClose}
      style={{ maxWidth: '650px' }}
      visible={visible}
      loading={loading}
      centered
      width="90%"
      footer={<ModalFooter type="info" loading={loading} onCancel={onClose} cancelText="Voltar" />}
    >
      <Spin spinning={loading}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row>
              <img src={recordData.image} alt="Flyer" style={{ width: '100%', margin: '0 auto' }} />
            </Row>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
}
