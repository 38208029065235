import { message } from 'antd';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';

const checkout = async (type, id) => {
  try {
    const response = await api.post('/checkout', {
      type,
      id,
    });

    const { data } = response;

    console.log(data);

    return data;
  } catch (error) {
    if (type === 'annuity' && error.response && error.response.status === 417) {
      const data = JSON.parse(error.response.data);
      message.error(data.message);
    } else if (type === 'congress' && error.response && error.response.status === 417) {
      const data = JSON.parse(error.response.data);
      message.error(data.message);
    } else if (type === 'course' && error.response && error.response.status === 417) {
      const data = JSON.parse(error.response.data);
      message.error(data.message);
    } else {
      errorHandler(error);
    }
    return false;
  }
};

export default checkout;
