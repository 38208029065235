/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Modal, message, Spin, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import errorHandler from '~/Utils/errorHandler';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';

import api from '~/services/api';
import { DivTitle } from './styles';

const initialValues = {
  name: '',
  email: '',
};

export default function PasswordForm({ visible, onClose }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);

  const [loading, setLoading] = useState(false);

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      await api.put('/me', values);
      message.success(t('messages:success'));
      onClose();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/me`);
      setRecordData(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const userSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),

    password: Yup.string()
      .min(8)
      .when('isEdit', {
        is: (value) => value !== true,
        then: Yup.string().required(),
      }),
    confirmationPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Senhas diferentes')
      .when('isEdit', {
        is: (value) => value !== true,
        then: Yup.string().required(),
      }),
  });

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={userSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm }) => (
        <Modal
          title={recordData.id ? `${t('screens:editing')}: ${recordData.name}` : t('screens:users.title')}
          onCancel={onClose}
          style={{ maxWidth: '650px' }}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          centered
          width="90%"
          footer={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <ModalFooter onOk={submitForm} loading={loading || isSubmitting} onCancel={onClose} />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <DivTitle>{t('screens:profile.data.registrationData')}</DivTitle>
                    </Col>
                  </Row>
                  <FormControl
                    cols={{ xs: 24, sm: 24, md: 6, lg: 6, xl: 6 }}
                    style={{ display: 'none' }}
                    field="isEdit"
                  >
                    <Input name="isEdit" value style={{ display: 'none' }} />
                  </FormControl>
                  <Row>
                    <FormControl
                      cols={{ xl: 12, xs: 12, sm: 12 }}
                      error={errors.name}
                      field="name"
                      label={t('screens:users.data.name')}
                    >
                      <Input name="name" disabled />
                    </FormControl>
                    <FormControl
                      cols={{ xl: 12, xs: 12, sm: 12 }}
                      error={errors.email}
                      field="email"
                      label={t('screens:users.data.email')}
                    >
                      <Input name="email" disabled />
                    </FormControl>
                  </Row>
                  <Row>
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.password}
                      field="password"
                      label={t('screens:users.data.password')}
                      required
                    >
                      <Input name="password" type="password" />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.confirmationPassword}
                      field="confirmationPassword"
                      label={t('screens:users.data.confirmationPassword')}
                      required
                    >
                      <Input type="password" name="confirmationPassword" />
                    </FormControl>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
