/* eslint-disable react/prop-types */
import React from 'react';
import { Menu as AntMenu } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Button from '~/components/Button';
import { decrypt } from '~/Utils/index';
import { MenuGlobalStyle, Nav } from './styles';

export default function Menu() {
  const { t } = useTranslation();

  const { permissions: permissionList, roles: roleList } =
    JSON.parse(decrypt(localStorage.getItem('@Portal:person'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@Portal:person')));

  const checkAuth = (permission, role) => {
    let auth = false;
    if (roleList && !roleList.includes('@superAdmin')) {
      if (role) {
        auth = roleList && roleList.includes(role);
      } else if (permission) {
        auth = permissionList && permissionList.includes(permission);
      } else {
        return false;
      }
    } else {
      return true;
    }
    return auth;
  };

  const MenuItem = ({ permission = null, role = null, children, ...props }) =>
    checkAuth(permission, role) ? <AntMenu.Item {...props}>{children}</AntMenu.Item> : null;

  const SubMenu = ({ permission = null, role = null, children, ...props }) =>
    checkAuth(permission, role) && <AntMenu.SubMenu {...props}>{children}</AntMenu.SubMenu>;

  const renderSubMenuTitle = (label) => (
    <>
      <span>{label}</span>
      <MdKeyboardArrowDown size={13} className="ant-menu-submenu-caret" />
    </>
  );

  const renderMenu = (item) => {
    const { type } = item;
    if (type === 'divider') {
      return <AntMenu.Divider key={item.key} />;
    }
    if (type === 'sub') {
      return (
        <SubMenu key={item.key} permission={item.permission} title={renderSubMenuTitle(item.label)}>
          {item.children.map((subItem) => renderMenu(subItem))}
        </SubMenu>
      );
    }
    if (type === 'rightSub') {
      return (
        <SubMenu key={item.key} permission={item.permission} title={item.label}>
          {item.children.map((subItem) => renderMenu(subItem))}
        </SubMenu>
      );
    }
    return (
      <MenuItem key={item.key} permission={item.permission}>
        {item.click ? (
          <Button onClick={item.click}>
            {item.icon && item.icon}
            {item.label}
          </Button>
        ) : (
          <Link to={item.url}>
            {item.icon && item.icon}
            {item.label}
          </Link>
        )}
      </MenuItem>
    );
  };

  const menuItems = [
    {
      type: 'item',
      key: 'dashboard',
      permission: '@dashboard/view',
      label: t('menus:dashboard'),
      url: '/',
    },
    {
      type: 'item',
      key: 'congress',
      permission: '@congress/view',
      label: t('menus:congress'),
      url: '/congressos',
    },
    // {
    //   type: 'item',
    //   key: 'course',
    //   permission: '@course/view',
    //   label: t('menus:course'),
    //   url: '/cursos',
    // },
    {
      type: 'item',
      key: 'abstract',
      permission: '@abstract/view',
      label: t('menus:abstracts'),
      url: '/resumos',
    },
    // {
    //   type: 'item',
    //   key: 'finacial',
    //   permission: '@finacial/view',
    //   label: t('menus:finacial'),
    //   url: '/financeiro',
    // },
    {
      type: 'item',
      key: 'financial',
      permission: '@finacial/view',
      label: t('menus:financial'),
      url: '/minhas-ordens',
    },
    {
      type: 'item',
      key: 'my_subscriptions',
      permission: '@subscription/view',
      label: t('menus:my_subscriptions'),
      url: '/minhas-inscricoes',
    },
  ];

  return (
    <>
      <MenuGlobalStyle />
      <Nav>
        <AntMenu mode="horizontal" defaultSelectedKeys={['cadastros']}>
          {menuItems.map((item) => renderMenu(item))}
        </AntMenu>
      </Nav>
    </>
  );
}
