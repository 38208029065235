import styled, { createGlobalStyle } from 'styled-components';
import { darken } from 'polished';
// import { Form } from 'formik-antd';
import LLButton from '~/components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  background: #ddd;
  border-radius: 6px;
  padding: 40px;
`;

export const GlobalStyle = createGlobalStyle`

.modal-title {
  align-items: center;
    display: flex;
    svg {
      margin-right: 0.5rem;
      color: ${(props) => props.theme.color8};
    }
  }
  .ant-btn, .ant-btn:active, .ant-btn:focus, .ant-btn:disabled, .ant-btn:disabled:hover {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0;
    height: 40px;

    background: ${darken(0.09, '#0F4C81')};
    border: 0;
    border-radius: 4px;
    color: #fff;

    transition: background 0.2s;
  }

  .form-control.error{
    .ant-picker, .ant-select:not(.ant-select-customize-input) .ant-select-selector{
      background: rgba(255, 0, 0, 0.2);
    }
    .ant-select{
      background: unset;
    }
    .ant-picker-input input,
    .ant-select-input input {
      background: unset !important;
      background-color: unset !important;
    }
    input::placeholder{
      color: #0000009c;
    }
  }

  .ant-btn:disabled {
    opacity: 0.8;
  }
  .base-container{
    max-width: 1024px;

    & .ll-box{
      background: #ffffff73;
    }
  }
  .ant-radio-group{
    display: flex;
  }
  .ant-select-selector{
    .ant-select-selection-item{
      text-align:left;
    }
  }
  @media screen and (min-width: 1200px) {
    .base-wrapper{
      height: unset;
    }
    .base-container{
      padding-bottom: 30px;
    }
  }
  @media screen and (max-width: 1200px) {
    .base-container{
      height: 100%;
    }
    .register-container{
      margin: unset;
    }
  }
  @media screen and (max-width: 650px) {
    .register-container .ant-col-xs-8{
      flex: 0 0 100%;
      max-width: 100%;

    }
  }

  .register-container {
    .info-section{
      margin: 10px 0 30px 0;

      p{
        font-size: 14px;
        font-weight: 400;
        font-family: 'Montserrat';
        color: #a1a1a1;
      }
      h2{
        text-transform: initial;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 2rem;
        color: ${darken(0.5, '#a1a1a1')};
        z-index: 10;
      }
    }
  }
`;

export const Button = styled(LLButton)`
  background: ${darken(0.09, '#0F4C81')};

  &:hover {
    background: ${darken(0.12, '#0F4C81')};
  }
`;

export const DivTitle = styled.h1`
  text-align: left;
  color: ${(props) => props.theme.color1};
`;
