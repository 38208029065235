/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import { formatCPF } from '@brazilian-utils/brazilian-utils';
import { Form, Switch, Input, Select, DatePicker } from 'formik-antd';
import MaskedInput from 'antd-mask-input';
import cep from 'cep-promise';
import { Modal, message, Spin, Col } from 'antd';
import { FaCamera, FaRegTrashAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import locale from 'antd/es/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';

import errorHandler from '~/Utils/errorHandler';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import { decrypt, crypt } from '~/Utils/index';

import nationalities from '~/data/nationalities.json';
import api from '~/services/api';
import AnnuityCheckout from '~/components/Annuity';
import { ImagePreview, UploadStyle, Container, DivTitle, Global } from './styles';

const initialValues = {
  name: '',
  email: '',
  active: true,
  address: {
    zipcode: '',
    address: '',
    address_number: '',
    city: '',
    state: '',
    country: '',
    neighborhood: '',
    phone: '',
    phone_extension: '',
    fax: '',
    fax_extension: '',
    mobile: '',
  },
};

export default function UserForm({ visible, onClose }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);
  const [changedFile, setChangedFile] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [gift, setGift] = useState({});
  const [loading, setLoading] = useState(false);
  const { permissions } =
    JSON.parse(decrypt(localStorage.getItem('@Portal:person'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@Portal:person')));

  const updateProfileLocalStorage = async () => {
    try {
      const stored =
        JSON.parse(decrypt(localStorage.getItem('@Portal:person'))) !== null &&
        JSON.parse(decrypt(localStorage.getItem('@Portal:person')));

      const { data } = await api.get('me');
      const { name, avatar_url, avatar_id } = data;

      const cryptedPerson = crypt(
        JSON.stringify({
          ...stored,
          name,
          avatar_url,
          avatar_id,
        })
      );

      localStorage.setItem('@Portal:person', cryptedPerson);
    } catch (error) {
      errorHandler(error);
      message.error(t('messages:errorOnProfileUpdate'));
    }
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    try {
      await api.put('/me', values);
      if (changedFile === true) {
        if (imageUrl !== null) {
          const files = new FormData();
          files.append('file', file.originFileObj);
          await api.post(`me/${recordData.id}/avatar`, files);
        } else {
          await api.delete(`me/${recordData.id}/avatar`);
        }
      }
      updateProfileLocalStorage();
      message.success(t('messages:success'));
      onClose();
      window.location.reload();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/me`);
      setRecordData(data);
      // eslint-disable-next-line no-unused-expressions
      setImageUrl(data && data.avatar_url);
      setGift(data.giftData);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleFindAddress = async (value, values, setErrors, setValues) => {
    setLoading(true);
    console.log(values.address);
    try {
      if (!values.foreign) {
        // const address = await cep(value);
        const address = await cep(value, { timeout: 10000 });
        const data = {
          ...values,
          address: {
            ...values.address,
            zipcode: value,
            city: address.city,
            state: address.state,
            neighborhood: address.neighborhood === '' ? '-' : address.neighborhood,
            address: address.street,
            /* address: address.street ( values.address.address.toString() === "" ? address.street : values.address.address.toString() ), */
            country: 'Brasil',
          },
        };
        setValues(data);
      } else {
        const data = {
          ...values,
          address: {
            ...values.address,
            zipcode: value,
            city: values.address.city,
            state: values.address.state,
            neighborhood: values.address.neighborhood === '' ? '-' : values.address.neighborhood,
            address: values.address.address,
            /* address: address.street ( values.address.address.toString() === "" ? address.street : values.address.address.toString() ), */
            country: 'Brasil',
          },
        };
        setValues(data);
      }
    } catch (error) {
      message.error('Endereço não localizado!');
      setErrors({
        address: {
          zipcode: 'CEP não encontrado',
        },
      });
    }
    setLoading(false);
  };

  // const handleCheckoutAnnuity = async () => {
  //   setLoading(true);
  //   const data = await checkout('annuity', recordData.id);

  //   if (data) {
  //     // eslint-disable-next-line no-undef
  //     if (typeof PagSeguroLightbox !== 'function' || PagSeguroLightbox === undefined) {
  //       window.location.replace(data.url);
  //     }

  //     onClose();
  //     // eslint-disable-next-line no-undef
  //     const lightbox = PagSeguroLightbox(data.code);
  //     if (!lightbox) {
  //       window.location.replace(data.url);
  //     }
  //   }

  //   setLoading(false);
  // };

  const PhoneInput = (inputName, inputPlaceholder, inputMask) => (
    <Field
      name={inputName}
      render={({ field }) => <MaskedInput mask={inputMask} {...field} id={inputName} placeholder={inputPlaceholder} />}
    />
  );

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (avatar) => {
    const isJpgOrPng = avatar.type === 'image/jpeg' || avatar.type === 'image/jpg' || avatar.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Você só pode anexar arquivos do tipo: JPG/JPEG/PNG');
    }
    const isLt2M = avatar.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('O arquivo deve ser menor que 20MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    setLoading(true);
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file && info.file.originFileObj) {
      getBase64(info.file.originFileObj, (image) => {
        setImageUrl(image);
        setFile(info.file);
      });
    }

    setLoading(false);
  };

  const uploadButton = (
    <>
      <FaCamera size={24} />
      <div className="ant-upload-text">{t('messages:add')}</div>
    </>
  );

  const handleUpload = async () => {
    setChangedFile(true);
  };

  const uploadConfig = {
    name: 'file',
    action: handleUpload,
    listType: 'picture-card',
    showUploadList: false,
    beforeUpload,
    onChange: handleChange,
  };

  useEffect(() => {
    if (visible) {
      fetchScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const userSchema = Yup.object().shape({
    name: Yup.string().required(),
    dob: Yup.string().required(),
    email: Yup.string().email().required(),
    academic_title: Yup.string().nullable(),
    nationality: Yup.string().nullable(),
    tshirt_size: Yup.string().nullable(),
    specialization_1: Yup.string().nullable(),
    specialization_2: Yup.string().nullable(),
    specialization_3: Yup.string().nullable(),
    department: Yup.string().nullable(),
    gift: Yup.string().nullable(),
    company: Yup.string().nullable(),
    address: Yup.object().shape({
      zipcode: Yup.string()
        .test('cep', 'Inválido, tente outro CEP', (value) => {
          if (!value) {
            return false;
          }
          // if (value.slice(-3) === '000') {
          //   return false;
          // }
          return value.length === 8 && value.match(/[0-9]+/gi);
        })
        .required(),
      address: Yup.string().required(),
      address_number: Yup.string().required(),
      city: Yup.string().required(),
      state: Yup.string().required().max(2),
      country: Yup.string().required(),
      neighborhood: Yup.string().required(),
      phone: Yup.string()
        .nullable()
        .test('phone', 'Insira um formato de telefone válido.', (value) =>
          !value ? true : value?.replace(/\D/g, '')?.length === 10
        ),
      phone_extension: Yup.string().nullable(),
      fax: Yup.string().nullable(),
      fax_extension: Yup.string().nullable(),
      mobile: Yup.string()
        .when('phone', {
          is: (value) => !value,
          then: Yup.string().required(),
        })
        .test('mobile', 'Insira um formato de celular válido.', (value) => value?.replace(/\D/g, '')?.length === 11),
    }),
    // password: Yup.string().when('isEdit', {
    //   is: (value) => value !== true,
    //   then: Yup.string().required(),
    // }),
    // confirmationPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Senhas diferentes'),
    // office: Yup.string().required(),
    // city: Yup.string().required(),
    // uf: Yup.string().required(),
  });

  return (
    <Formik
      validateOnMount
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={userSchema}
    >
      {({ errors, isSubmitting, values, submitForm, resetForm, setErrors, setValues }) => (
        <Modal
          title={recordData.id ? `${t('screens:editing')}: ${recordData.name}` : t('screens:users.title')}
          onCancel={onClose}
          style={{ maxWidth: '1100px' }}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          centered
          width="90%"
          footer={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <ModalFooter
              type={permissions && !permissions.includes('@Portal/edit') && 'info'}
              onOk={submitForm}
              loading={loading || isSubmitting}
              onCancel={onClose}
            />
          }
        >
          <Global />
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <FormControl cols={{ xs: 24 }} required field="avatar" style={{ alignItems: 'center' }}>
                    <>
                      <UploadStyle />
                      {imageUrl ? (
                        <ImagePreview className="img-preview" size="100px" background={imageUrl}>
                          <div className="img-preview-wrapper">
                            <div
                              aria-hidden="true"
                              className="img-preview-delete"
                              onClick={() => {
                                setImageUrl(null);
                                setFile(null);
                                setChangedFile(true);
                              }}
                            >
                              <FaRegTrashAlt size={24} />
                              {t('messages:delete')}
                            </div>
                            <div className="img-preview-file" />
                          </div>
                        </ImagePreview>
                      ) : (
                        <Container {...uploadConfig} size="100px">
                          <Spin spinning={loading}>{imageUrl || uploadButton}</Spin>
                        </Container>
                      )}
                    </>
                  </FormControl>
                  <div className="profile-info-bool">
                    <FormControl cols={{ xs: 24 }} field="active" label={t('screens:profile.data.active')}>
                      <Switch name="active" checked={recordData.active} disabled />
                    </FormControl>
                    <FormControl cols={{ xs: 24 }} field="foreign" label={t('screens:profile.data.foreign')}>
                      <Switch name="foreign" checked={recordData.foreign} disabled />
                    </FormControl>
                    {recordData.is_evaluator && (
                      <FormControl
                        cols={{ xs: 24 }}
                        field="is_evaluator"
                        label={t('screens:profile.data.is_evaluator')}
                      >
                        <Switch name="is_evaluator" checked={recordData.is_evaluator} disabled />
                      </FormControl>
                    )}
                    {recordData.verified && (
                      <FormControl cols={{ xs: 24 }} field="verified" label={t('screens:profile.data.verified')}>
                        <Switch name="verified" checked={recordData.verified} disabled />
                      </FormControl>
                    )}
                    <FormControl cols={{ xs: 24 }} field="is_student" label={t('screens:profile.data.is_student')}>
                      <Switch
                        name="is_student"
                        checked={values.is_student}
                        disabled={!recordData.is_student}
                        onChange={(e) => {
                          values.is_student = e;
                          // handleChangeStudent(record);
                        }}
                      />
                    </FormControl>
                  </div>
                </Col>
                <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <DivTitle>{t('screens:profile.data.registrationData')}</DivTitle>
                    </Col>
                  </Row>
                  <Row>
                    <FormControl
                      cols={{ xl: 12, xs: 12, sm: 12 }}
                      error={errors.name}
                      field="name"
                      label={t('screens:users.data.name')}
                      required
                    >
                      <Input name="name" disabled />
                    </FormControl>
                    <FormControl
                      cols={{ xl: 12, xs: 12, sm: 12 }}
                      error={errors.email}
                      field="email"
                      label={t('screens:users.data.email')}
                      required
                    >
                      <Input name="email" disabled />
                    </FormControl>
                  </Row>
                  {/* <FormControl cols={{ xs: 2 }} field="active" label={t('screens:users.data.active')}>
                      <Switch name="active" checked={recordData.active} disabled />
                    </FormControl> */}
                  <Row>
                    {!recordData.foreign ? (
                      <>
                        <FormControl
                          cols={{ xl: 8, xs: 24, sm: 24 }}
                          error={errors.cpf}
                          field="cpf"
                          label={t('screens:profile.data.cpf')}
                          required
                        >
                          <Input name="cpf" disabled value={formatCPF(recordData && recordData.cpf)} />
                        </FormControl>
                        <FormControl
                          cols={{ xl: 8, xs: 24, sm: 24 }}
                          error={errors.rg}
                          field="rg"
                          label={t('screens:profile.data.rg')}
                          required
                        >
                          <Input name="rg" disabled />
                        </FormControl>
                      </>
                    ) : (
                      <FormControl
                        cols={{ xl: 16, xs: 24, sm: 24 }}
                        error={errors.document}
                        field="document"
                        label={t('screens:profile.data.document')}
                        required
                      >
                        <Input name="document" disabled />
                      </FormControl>
                    )}

                    <FormControl
                      cols={{ xl: 8, xs: 24, sm: 24 }}
                      error={errors.dob}
                      field="dob"
                      label={t('screens:profile.data.dob')}
                      required
                    >
                      <DatePicker format="DD/MM/YYYY" name="dob" placeholder={t('messages:select')} locale={locale} />
                    </FormControl>
                  </Row>
                  {/* {recordData.is_evaluator && (
                      <FormControl cols={{ xs: 2 }} field="is_evaluator" label={t('screens:profile.data.is_evaluator')}>
                        <Switch name="is_evaluator" checked={recordData.is_evaluator} disabled />
                      </FormControl>
                    )} */}
                  <Row>
                    <FormControl
                      cols={{ xl: 12, xs: 12, sm: 12 }}
                      error={errors.profession}
                      field="profession"
                      label={t('screens:profile.data.profession')}
                    >
                      <Input name="profession" />
                    </FormControl>
                    <FormControl
                      cols={{ xl: 12, xs: 12, sm: 12 }}
                      error={errors.company}
                      field="company"
                      label={t('screens:profile.data.company')}
                    >
                      <Input name="company" />
                    </FormControl>
                  </Row>
                  <Row>
                    <FormControl
                      cols={{ xl: 8, xs: 24, sm: 24 }}
                      error={errors.department}
                      field="department"
                      label={t('screens:profile.data.department')}
                    >
                      <Input name="department" />
                    </FormControl>
                    <FormControl
                      cols={{ xl: 8, xs: 24, sm: 24 }}
                      error={errors.academic_title}
                      field="academic_title"
                      label={t('screens:profile.data.academic_title')}
                    >
                      <Input name="academic_title" />
                    </FormControl>
                    <FormControl
                      cols={{ xl: 8, xs: 24, sm: 24 }}
                      error={errors.nationality}
                      field="nationality"
                      label={t('fields:nationality')}
                    >
                      <Select name="nationality" allowClear showSearch optionFilterProp="children">
                        {nationalities &&
                          nationalities.map((item) => (
                            <Select.Option key={item.nome} value={item.nome}>
                              {item.nome}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                  </Row>
                  <Row>
                    <FormControl
                      cols={{ xl: 8, xs: 24, sm: 24 }}
                      error={errors.specialization_1}
                      field="specialization_1"
                      label={t('screens:profile.data.specialization_1')}
                    >
                      <Input name="specialization_1" />
                    </FormControl>
                    <FormControl
                      cols={{ xl: 8, xs: 24, sm: 24 }}
                      error={errors.specialization_2}
                      field="specialization_2"
                      label={t('screens:profile.data.specialization_2')}
                    >
                      <Input name="specialization_2" />
                    </FormControl>
                    <FormControl
                      cols={{ xl: 8, xs: 24, sm: 24 }}
                      error={errors.specialization_3}
                      field="specialization_3"
                      label={t('screens:profile.data.specialization_3')}
                    >
                      <Input name="specialization_3" />
                    </FormControl>
                  </Row>
                  {gift.enable && gift.name && (
                    <Row>
                      <FormControl cols={{ xl: 24, xs: 24, sm: 24 }} error={errors.gift} field="gift" label={gift.name}>
                        <Input name="gift" />
                      </FormControl>
                    </Row>
                  )}
                  <Row>
                    <Col xl={12} xs={24} sm={24} className="address-grid">
                      <DivTitle>{t('screens:profile.data.address')}</DivTitle>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.zipcode}
                        field="address.zipcode"
                        label={values.foreign ? 'Zip Code' : t('screens:profile.data.zipcode')}
                        required
                      >
                        <Field
                          name="address.zipcode"
                          render={({ field }) => (
                            <MaskedInput
                              mask="11.111-111"
                              {...field}
                              disabled={permissions && !permissions.includes('@people/edit')}
                              placeholder="__.___-__"
                              autoComplete="dontshow"
                              onBlur={(e) => {
                                const zipcode = e.target.value.replace(/[^0-9,]*/g, '');
                                if (zipcode.length === 8) {
                                  handleFindAddress(zipcode, values, setErrors, setValues);
                                  // handleFindAddress(zipcode, values);
                                }
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.address}
                        field="address.address"
                        label={t('screens:profile.data.address')}
                        required
                      >
                        <Input name="address.address" disabled={permissions && !permissions.includes('@people/edit')} />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.address_number}
                        field="address.address_number"
                        label={t('screens:profile.data.address_number')}
                        required
                      >
                        <Input
                          name="address.address_number"
                          disabled={permissions && !permissions.includes('@people/edit')}
                        />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.address_complement}
                        field="address.address_complement"
                        label={t('screens:profile.data.address_complement')}
                      >
                        <Input name="address.address_complement" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.city}
                        field="address.city"
                        label={t('screens:profile.data.city')}
                        required
                      >
                        <Input name="address.city" disabled={permissions && !permissions.includes('@people/edit')} />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.state}
                        field="address.state"
                        label={t('screens:profile.data.state')}
                        required
                      >
                        <Input
                          name="address.state"
                          disabled={permissions && !permissions.includes('@people/edit')}
                          maxLength={2}
                        />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.country}
                        field="address.country"
                        label={t('screens:profile.data.country')}
                        required
                      >
                        <Input name="address.country" disabled={permissions && !permissions.includes('@people/edit')} />
                      </FormControl>
                    </Col>
                    <Col xl={12} xs={24} sm={24}>
                      <DivTitle>{t('screens:profile.data.contact')}</DivTitle>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.phone}
                        field="address.phone"
                        label={t('screens:profile.data.phone')}
                      >
                        {PhoneInput('address.phone', '(__) ____-____', '(11) 1111-1111')}
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.phone_extension}
                        field="address.phone_extension"
                        label={t('screens:profile.data.phone_extension')}
                      >
                        <Input name="address.phone_extension" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.fax}
                        field="address.fax"
                        label={t('screens:profile.data.fax')}
                      >
                        <Input name="address.fax" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.fax_extension}
                        field="address.fax_extension"
                        label={t('screens:profile.data.fax_extension')}
                      >
                        <Input name="address.fax_extension" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.mobile}
                        field="address.mobile"
                        label={t('screens:profile.data.mobile')}
                        required
                      >
                        {PhoneInput('address.mobile', '(__) _____-____', '(11) 11111-1111')}
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <DivTitle>{t('screens:profile.data.features')}</DivTitle>
                      <AnnuityCheckout recordData={recordData} onCheckout={() => onClose()} />
                      {/* {recordData.isAssociated ? (
                        <>
                          <Tag color="green">
                            <b>{t('fields:status')}</b> {t('fields:associated')}
                          </Tag>
                          <Tag color="green">
                            <b>{t('fields:validity')}</b> {new Date(recordData.validity).toLocaleDateString()}
                          </Tag>
                          <Button color="primary" onClick={handleCheckoutAnnuity} style={{ marginTop: '0.5rem' }}>
                            <FaCoins style={{ fontSize: '14px' }} /> Pagar próxima anuidade
                          </Button>
                        </>
                      ) : (
                        <>
                          <Tag color="red">{t('fields:non_associated')}</Tag>
                          <Button color="primary" onClick={handleCheckoutAnnuity} style={{ marginTop: '0.5rem' }}>
                            <FaCoins style={{ fontSize: '14px' }} /> Pagar
                          </Button>
                        </>
                      )} */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
