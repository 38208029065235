/* eslint-disable */
import React, { useState, useEffect } from 'react';
import api from '~/services/api';
import { Formik } from 'formik';
import { Form, Input, Select, Switch } from 'formik-antd';
import { message, Spin, Col, Button as ButtonAntd, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import 'moment/locale/pt-br';
import errorHandler from '~/Utils/errorHandler';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import moment from 'moment';
import { formatPrice } from '~/Utils/format';
import PixForm from './pixForm';
import BoletoForm from './boletoForm';
import { DivTitle, Global } from './styles';
import DefaultLayout from '../_layouts/full';
import countryCodes from './countryCodes.json'; // ISO 3166-1 Alfa-3
import Paypal from './paypal';
import axios from 'axios';

const createHistory = require('history').createBrowserHistory;

const history = createHistory();

const ufsBrasil = [
  { label: 'Acre', code: 'AC' },
  { label: 'Alagoas', code: 'AL' },
  { label: 'Amapá', code: 'AP' },
  { label: 'Amazonas', code: 'AM' },
  { label: 'Bahia', code: 'BA' },
  { label: 'Ceará', code: 'CE' },
  { label: 'Distrito Federal', code: 'DF' },
  { label: 'Espírito Santo', code: 'ES' },
  { label: 'Goiás', code: 'GO' },
  { label: 'Maranhão', code: 'MA' },
  { label: 'Mato Grosso', code: 'MT' },
  { label: 'Mato Grosso do Sul', code: 'MS' },
  { label: 'Minas Gerais', code: 'MG' },
  { label: 'Pará', code: 'PA' },
  { label: 'Paraíba', code: 'PB' },
  { label: 'Paraná', code: 'PR' },
  { label: 'Pernambuco', code: 'PE' },
  { label: 'Piauí', code: 'PI' },
  { label: 'Rio de Janeiro', code: 'RJ' },
  { label: 'Rio Grande do Norte', code: 'RN' },
  { label: 'Rio Grande do Sul', code: 'RS' },
  { label: 'Rondônia', code: 'RO' },
  { label: 'Roraima', code: 'RR' },
  { label: 'Santa Catarina', code: 'SC' },
  { label: 'São Paulo', code: 'SP' },
  { label: 'Sergipe', code: 'SE' },
  { label: 'Tocantins', code: 'TO' },
];

let initialCardValues = {
  personId: '',
  subscriptionId: '',
  customerName: '',
  customerEmail: '',
  customerPhoneCountry: '55',
  customerPhoneArea: '11',
  customerPhoneNumber: '',
  customerPhoneType: 'MOBILE',
  customerTaxId: '',
  itemType: '',
  itemReferenceId: '',
  itemName: '',
  itemQuantity: 1,
  itemUnitAmount: 0,
  shippingAddressStreet: '',
  shippingAddressNumber: '',
  shippingAddressComplement: '',
  shippingAddressLocality: '',
  shippingAddressCity: '',
  shippingAddressRegionCode: 'SP',
  shippingAddressCountry: 'BRA',
  shippingAddressPostalCode: '',
  notificationUrl: '',
  chargerReferenceId: '',
  chargerDescription: '',
  chargerAmountValue: 0,
  chargerAmountCurrency: 'BRL',
  chargerPaymentMethodType: 'CREDIT_CARD',
};

/*
PIX Transaction amount                          Scenario
---------------------------------------------------------------------------
Menor ou igual a R$ 100,00                      Pago instantaneamente
Maior que R$ 100,00 e menor igual a R$ 200,00   Pago com delay de 5 minutos
Maior que R$ 200,00                             Aguardando Pagamento
*/

const initialQRCodePaymentMethod = {
  qrCodeAmountValue: 0,
  qrCodeExpirationDate: '', // 2021-08-29T20:15:59-03:00
};

const initialCreditCardPaymentMethod = {
  // chargerPaymentMethodType: 'CREDIT_CARD',
  chargerPaymentMethodInstallments: 1,
  chargerPaymentMethodCapture: true,
  chargerPaymentMethodSoftDescriptor: '',
  chargerPaymentMethodCardEncrypted: '',
  chargerPaymentMethodCardNumber: '',
  chargerPaymentMethodCardExpMonth: '',
  chargerPaymentMethodCardExpYear: '',
  chargerPaymentMethodCardSecurityCode: '',
  chargerPaymentMethodCardHolderName: '',
  chargerPaymentMethodCardStore: false,
};

const initialBoletoPaymentMethod = {
  // chargerPaymentMethodType: 'BOLETO',
  chargerPaymentMethodBoletoDueDate: '',
  chargerPaymentMethodBoletoInstructionLine1: '',
  chargerPaymentMethodBoletoInstructionLine2: '',
  chargerPaymentMethodBoletoHolderName: '',
  chargerPaymentMethodBoletoHolderTaxId: '',
  chargerPaymentMethodBoletoHolderEmail: '',
  chargerPaymentMethodBoletoHolderAddressCountry: 'Brasil',
  chargerPaymentMethodBoletoHolderAddressRegion: 'São Paulo',
  chargerPaymentMethodBoletoHolderAddressRegionCode: 'SP',
  chargerPaymentMethodBoletoHolderAddressCity: '',
  chargerPaymentMethodBoletoHolderAddressPostalCode: '',
  chargerPaymentMethodBoletoHolderAddressStreet: '',
  chargerPaymentMethodBoletoHolderAddressNumber: '',
  chargerPaymentMethodBoletoHolderAddressLocality: '',
  inter_payment: false,
};

// add payment methods to initialCardValues
initialCardValues = { ...initialCardValues, ...initialQRCodePaymentMethod };
initialCardValues = { ...initialCardValues, ...initialCreditCardPaymentMethod };
initialCardValues = { ...initialCardValues, ...initialBoletoPaymentMethod };

export default function Payment(dataReceived) {
  const { type, typeId } = dataReceived?.location?.state;
  const { t } = useTranslation();
  const [orderData, setOrderData] = useState(initialCardValues);
  const [loading, setLoading] = useState(false);
  const [formDisabled, setFormDisabled] = useState(true);
  const [showPix, setShowPix] = useState(false);
  const [pixData, setPixData] = useState({});
  const [showBoleto, setShowBoleto] = useState(false);
  const [boletoData, setBoletoData] = useState({});
  const [usdConvert, setUsdConvert] = useState('');
  const referenceId = Math.floor(Math.random() * 1000000000);
  initialCardValues.chargerReferenceId = referenceId;

  useEffect(() => {
    try {
      setLoading(true);
      api.get(`/me`).then((response) => {
        const { data } = response;
        setOrderData((prevOrderData) => ({
          ...prevOrderData,
          personId: data.id,
          customerName: data.name,
          customerEmail: data.email,
          shippingAddressComplement: data.address.address_complement,
        }));

        let typeIdData = typeId;
        if (type === 'annuity' && !(typeId > 0)) {
          typeIdData = data.id;
        }
        api
          .post('/checkout/getData', { type, id: typeIdData })
          .then((response2) => {
            const { data: data2 } = response2;
            const dataItemReference = {};

            if (JSON.parse(data2.reference).type === 'annuity') {
              dataItemReference.t = 'annuity';
              dataItemReference.tid = JSON.parse(data2.reference).annuity;
              dataItemReference.pid = JSON.parse(data2.reference).person;
            }
            if (JSON.parse(data2.reference).type === 'course') {
              dataItemReference.t = 'course';
              dataItemReference.tid = JSON.parse(data2.reference).course;
              dataItemReference.sid = JSON.parse(data2.reference).subscription_id;
              dataItemReference.pid = JSON.parse(data2.reference).person;
            }
            if (JSON.parse(data2.reference).type === 'congress') {
              dataItemReference.t = 'congress';
              dataItemReference.tid = JSON.parse(data2.reference).congress;
              dataItemReference.sid = JSON.parse(data2.reference).subscription_id;
              dataItemReference.pid = JSON.parse(data2.reference).person;
            }
            const findCountryCode = countryCodes.countries.find(
              (country) => country.name === data2.shipping.address.country
            );
            const dataShippingAddressCountry = findCountryCode ? findCountryCode.code : 'BRA';

            // Verify if amount is greater than zero
            let amount = data2.items[0].amount;
            amount = parseFloat(amount / 100).toFixed(2);
            const disabled = amount === 0;
            setFormDisabled(disabled);

            setOrderData((prevOrderData) => ({
              ...prevOrderData,
              // CUSTOMER
              customerPhoneNumber: data2.sender.phone.number ? data2.sender.phone.number : '',
              customerPhoneCountry: dataShippingAddressCountry === 'BRA' ? '55' : null,
              customerPhoneArea: data2.sender.phone.areaCode,
              customerPhoneType: 'MOBILE',
              customerTaxId: data2.sender.documents[0].number,
              // SHIPPING ADDRESS
              shippingAddressStreet: data2.shipping.address.street,
              shippingAddressNumber: data2.shipping.address.number,
              shippingAddressLocality: data2.shipping.address.district,
              shippingAddressCity: data2.shipping.address.city,
              shippingAddressRegionCode: data2.shipping.address.state,
              shippingAddressCountry: dataShippingAddressCountry,
              shippingAddressPostalCode: data2.shipping.address.postalCode,
              // ITEM
              itemType: JSON.parse(data2.reference).type,
              itemReferenceId: JSON.stringify(dataItemReference),
              subscriptionId: JSON.parse(data2.reference).subscription_id,
              itemName: data2.items[0].description, // String (1-64 characters)
              itemQuantity: data2.items[0].quantity, // Int (5 characters)
              itemUnitAmount: data2.items[0].amount, //  Int (9 characters)

              // chargerReferenceId: data2.reference
              chargerDescription: data2.items[0].description,
              chargerAmountValue: data2.items[0].amount,

              // PagSeguro advise: "O PagSeguro permite que o lojista envie um texto complementar de até 17 caracteres que será impresso na fatura do comprador junto com a identificação do nome da loja que consta na conta PagSeguro."
              chargerPaymentMethodSoftDescriptor: 'SBFV', // required if credit card

              // PIX PAYMENT METHOD
              qrCodeAmountValue: data2.items[0].amount,
              qrCodeExpirationDate: moment(Date.now() + 3600000).toISOString(), // data now + 1 hour

              // BOLETO PAYMENT METHOD
              chargerPaymentMethodBoletoDueDate: moment().add(1, 'days').format('YYYY-MM-DD'),
              chargerPaymentMethodBoletoInstructionLine1: 'Nao receber apos o vencimento',
              chargerPaymentMethodBoletoInstructionLine2:
                'Todas as Informacoes desse boleto sao de responsabilidade do beneficiario',
              chargerPaymentMethodBoletoHolderName: data2.sender.name,
              chargerPaymentMethodBoletoHolderTaxId: data2.sender.documents[0].number,
              chargerPaymentMethodBoletoHolderEmail: data2.sender.email,
              chargerPaymentMethodBoletoHolderAddressCountry: data2.shipping.address.country,
              chargerPaymentMethodBoletoHolderAddressRegion: data2.shipping.address.state,
              chargerPaymentMethodBoletoHolderAddressRegionCode: data2.shipping.address.state,
              chargerPaymentMethodBoletoHolderAddressCity: data2.shipping.address.city,
              chargerPaymentMethodBoletoHolderAddressPostalCode: data2.shipping.address.postalCode,
              chargerPaymentMethodBoletoHolderAddressStreet: data2.shipping.address.street,
              chargerPaymentMethodBoletoHolderAddressNumber: data2.shipping.address.number,
              chargerPaymentMethodBoletoHolderAddressLocality: data2.shipping.address.district,
            }));
          })
          .catch((error) => {
            errorHandler(error);
          });
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      // wait 10 seconds
      await new Promise((resolve) => setTimeout(resolve, 3000));

      if (values.chargerPaymentMethodType === 'CREDIT_CARD') {
        // Função para criptografar o cartão de crédito
        try {
          // Verificar se o objeto PagSeguro está definido
          if (typeof PagSeguro === 'undefined') {
            // Aguardar até que o script seja carregado
            await new Promise((resolve) => {
              const script = document.createElement('script');
              script.src = 'https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js';
              script.onload = resolve;
              document.body.appendChild(script);
            });
          }
          const holder = values.chargerPaymentMethodCardHolderName;
          const number = values.chargerPaymentMethodCardNumber;
          const expMonth = values.chargerPaymentMethodCardExpMonth;
          const expYear = values.chargerPaymentMethodCardExpYear;
          const securityCode = values.chargerPaymentMethodCardSecurityCode;

          const useSandbox = process.env.REACT_APP_PAGSEGURO_USE_SANDBOX === 'true';
          const publicKey = useSandbox
            ? process.env.REACT_APP_SANDBOX_PUBLICKEY
            : process.env.REACT_APP_PAGSEGURO_PUBLICKEY;

          const dataToEncrypt = {
            publicKey,
            holder,
            number,
            expMonth,
            expYear,
            securityCode,
          };

          // Agora o objeto PagSeguro deve estar definido
          const card = PagSeguro.encryptCard(dataToEncrypt);
          const encrypted = card.encryptedCard;
          const hasErrors = card.hasErrors;
          const errors = card.errors;

          // resultados
          if (hasErrors) {
            errorHandler(error);
          } else {
            // dados do cartão criptografado que vai ser enviado a API
            values.chargerPaymentMethodCardEncrypted = encrypted;

            try {
              api
                .post('/orders/', values)
                .then((response) => {
                  // Envio bem-sucedido redireciona para a PaymentTanks ( página de agradecimento )
                  history.push('/pagamento/obrigado');
                  const pathUrl = window.location.href;
                  window.location.href = pathUrl;
                })
                .catch((error) => {
                  errorHandler(error);
                });
              return;
            } catch (error) {
              errorHandler(error);
            }
          }
        } catch (error) {
          errorHandler(error);
        }
      } else {
        // Boleto or PIX
        if (values.chargerPaymentMethodType === 'BOLETO') {
          values.chargerPaymentMethodBoletoHolderAddressStreet = values.shippingAddressStreet;
          values.chargerPaymentMethodBoletoHolderAddressNumber = values.shippingAddressNumber;
          values.chargerPaymentMethodBoletoHolderAddressComplement = values.shippingAddressComplement;
          values.chargerPaymentMethodBoletoHolderAddressLocality = values.shippingAddressLocality;
          values.chargerPaymentMethodBoletoHolderAddressCity = values.shippingAddressCity;
          values.chargerPaymentMethodBoletoHolderAddressRegion =
            values.shippingAddressCountry === 'BRA'
              ? ufsBrasil.filter((uf) => uf.code === values.shippingAddressRegionCode)[0].label
              : values.shippingAddressRegionCode;
          values.chargerPaymentMethodBoletoHolderAddressRegionCode = values.shippingAddressRegionCode;
          values.chargerPaymentMethodBoletoHolderAddressCountry = values.shippingAddressCountry;
          values.chargerPaymentMethodBoletoHolderAddressPostalCode = values.shippingAddressPostalCode;
        }
        api
          .post('/orders/', values)
          .then((response) => {
            const { data } = response;
            if (data.payment_method_type === 'PIX') {
              setPixData(data);
              setShowPix(true);
            } else if (data.payment_method_type === 'BOLETO') {
              setBoletoData(data);
              setShowBoleto(true);
            }
          })
          .catch((error) => {
            message.error('Erro ao enviar, tente novamente mais tarde');
            errorHandler(error);
          });
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const luhnCheck = (value) => {
    // accept only digits, dashes or spaces
    if (/[^0-9-\s]+/.test(value)) {
      return false;
    }
    let nCheck = 0;
    let bEven = false;
    value = value.replace(/\D/g, '');
    // eslint-disable-next-line no-plusplus
    for (let n = value.length - 1; n >= 0; n--) {
      const cDigit = value.charAt(n);
      let nDigit = parseInt(cDigit, 10);
      if (bEven) {
        nDigit *= 2;
        if (nDigit > 9) {
          nDigit -= 9;
        }
      }
      nCheck += nDigit;
      bEven = !bEven;
    }
    return nCheck % 10 === 0;
  };

  const validationSchema = Yup.object().shape({
    // https://dev.pagbank.uol.com.br/reference/objeto-order
    customerEmail: Yup.string()
      .email('Insira um e-mail válido')
      .required('e-mail campo obrigatório')
      .min(10, 'O email tem que ter pelo menos 10 caracteres')
      .max(255, 'O email só pode ter até 255 caracteres'),

    // must be a string with length 1-30
    customerName: Yup.string()
      .required('campo obrigatório')
      .min(1, 'O nome tem que ter pelo menos 1 caracteres')
      .max(30, 'O nome só pode ter até 30 caracteres')
      .test('customerName', 'É necessário pelo menos um sobrenome', (value) => {
        if (value?.includes(' ')) {
          return true;
        }
        return false;
      })
      .test('customerName', 'O nome não pode incluir espaços duplos', (value) => {
        if (value?.includes('  ')) {
          return false;
        }
        return true;
      })
      .test('customerName', 'O nome não pode acabar com um espaço', (value) => {
        if (value?.endsWith(' ')) {
          return false;
        }
        return true;
      })
      .test('customerName', 'O nome não pode começar com um espaço', (value) => {
        if (value?.startsWith(' ')) {
          return false;
        }
        return true;
      }),

    //  customerTaxId must be a string with lenght 11 or 14 caracteres (CPF or CNPJ)
    customerTaxId: Yup.string()
      .required('campo obrigatório')
      .matches(/^[0-9]+$/, 'customerTaxId must contain only numbers')
      .test('test-customerTaxId', 'deve ter de 11 ou 14 caracteres', (value) => {
        if (value && (value.length === 11 || value.length === 14)) {
          return true;
        }
        return false;
      }),

    // must be a string with max of 3 caracters, only numbers
    customerPhoneCountry: Yup.string()
      .required('campo obrigatório')
      .max(3, 'deve ter no máximo 3 caracteres')
      .test('test-customerPhoneCountry', 'deve conter apenas números', (value) => {
        if (value && /^\d+$/.test(value)) {
          return true;
        }
        return false;
      }),

    // must be a string with length 2, only numbers
    customerPhoneArea: Yup.string()
      .required('campo obrigatório')
      .test('test-customerPhoneArea', 'deve ter exatamente 2 caracteres', (value) => {
        if (value && /^\d{2}$/.test(value)) {
          return true;
        }
        return false;
      }),

    // must be a string with length 8 or 9, only numbers
    customerPhoneNumber: Yup.string()
      .required('campo obrigatório')
      .matches(/^[0-9]+$/, 'deve conter apenas números')
      .test('test-customerPhoneNumber', 'deve ter 8 ou 9 caracteres', (value) => {
        if (value && (value.length === 8 || value.length === 9)) {
          return true;
        }
        return false;
      }),
    // must be a enum with values MOBILE, BUSINESS or HOME
    customerPhoneType: Yup.string()
      .required('campo obrigatório')
      .oneOf(['MOBILE', 'BUSINESS', 'HOME'], 'deve ser MOBILE, BUSINESS or HOME'),

    // payment method type (CREDIT_CARD, BOLETO, PIX)
    chargerPaymentMethodType: Yup.string()
      .required('campo obrigatório')
      .oneOf(['CREDIT_CARD', 'BOLETO', 'PIX'], 'deve ser CARTÃO DE CRÉDITO, BOLETO ou PIX'),

    // ******************
    // ADDRESS VALIDATION
    // ******************

    // street String (1-160 characters). Example: Avenida Brigadeiro Faria Lima
    shippingAddressStreet: Yup.string().when('chargerPaymentMethodType', {
      is: 'BOLETO',
      then: Yup.string()
        .required('campo obrigatório')
        .min(1, 'deve ter pelo menos xx caracteres')
        .max(160, 'deve ter até 160 caracteres'),
    }),

    // number String (1-20 characters). Example: 1384
    shippingAddressNumber: Yup.string().when('chargerPaymentMethodType', {
      is: 'BOLETO',
      then: Yup.string()
        .required('campo obrigatório')
        .min(1, 'deve ter pelo menos 1 caracteres')
        .max(20, 'deve ter até 20 caracteres'),
    }),

    // locality String (1-60 characters). Example: Pinheiros
    shippingAddressLocality: Yup.string().when('chargerPaymentMethodType', {
      is: 'BOLETO',
      then: Yup.string()
        .required('campo obrigatório')
        .min(1, 'deve ter pelo menos 1 caracteres')
        .max(60, 'deve ter até 60 caracteres'),
    }),
    // city String (1-90 characters). Example: Sao Paulo
    shippingAddressCity: Yup.string().when('chargerPaymentMethodType', {
      is: 'BOLETO',
      then: Yup.string()
        .required('campo obrigatório')
        .min(1, 'deve ter pelo menos 1 caracteres')
        .max(90, 'deve ter até 90 caracteres'),
    }),

    // region_code String (2 characters). State code (ISO 3166-2 standard). Example: SP
    shippingAddressRegionCode: Yup.string().when('chargerPaymentMethodType', {
      is: 'BOLETO',
      then: Yup.string()
        .required('campo obrigatório')
        .test('test-regionCode', 'apenas 2 caracteres', (value) => {
          if (value && value.length === 2) {
            return true;
          }
          return false;
        }),
    }),

    // country String (1-50 characters). Address country (ISO 3166-1 alpha-3 standard). Example: BR
    shippingAddressCountry: Yup.string().when('chargerPaymentMethodType', {
      is: 'BOLETO',
      then: Yup.string()
        .required('campo obrigatório')
        .test('test-country', 'apenas 3 caracteres', (value) => {
          if (value && value.length === 3) {
            return true;
          }
          return false;
        }),
    }),

    // postal_code String (8 characters). Address zip code. Example: 01452002 the zipcode can not fini
    shippingAddressPostalCode: Yup.string().when('chargerPaymentMethodType', {
      is: 'BOLETO',
      then: Yup.string()
        .required('campo obrigatório')
        .test('test-postalCode', 'CEP inválido', (value) => {
          if (value && value.length === 8) {
            if (/^\d+$/.test(value)) {
              if (!value.endsWith('000')) {
                return true;
              }
            }
          }
          return false;
        }),
    }),

    // **********************
    // CREDIT CARD VALIDATION
    // **********************

    // card holder name (1-30 characters)
    chargerPaymentMethodCardHolderName: Yup.string().when('chargerPaymentMethodType', {
      is: 'CREDIT_CARD',
      then: Yup.string()
        .required('campo obrigatório')
        .min(1, 'deve ter pelo menos 1 caracteres')
        .max(30, 'deve ter até 30 caracteres'),
    }),

    // validade card number
    chargerPaymentMethodCardNumber: Yup.string().when('chargerPaymentMethodType', {
      is: 'CREDIT_CARD',
      then: Yup.string()
        .required('campo obrigatório')
        .test(
          'test-number',
          'número do cartão de crédito é inválido',
          (value) => value && luhnCheck(value.replace(/\s/g, ''))
        ),
    }),

    // validade month (1-12)
    chargerPaymentMethodCardExpMonth: Yup.string().when('chargerPaymentMethodType', {
      is: 'CREDIT_CARD',
      then: Yup.string()
        .required('campo obrigatório')
        .test(
          'test-expMonth',
          'mês de vencimento do cartão de crédito é inválido',
          (value) => value && value >= 1 && value <= 12
        ),
    }),

    // validade year (4 digits)
    chargerPaymentMethodCardExpYear: Yup.string().when('chargerPaymentMethodType', {
      is: 'CREDIT_CARD',
      then: Yup.string()
        .required('campo obrigatório')
        .test('test-expYear', 'ano de validade do cartão de crédito é inválido', (value) => value && value.length === 4)
        .test(
          'test-expYear',
          'ano de validade do cartão de crédito é inválido',
          (value) => value && value >= new Date().getFullYear()
        ),
    }),

    // security code (3-4 digits)
    chargerPaymentMethodCardSecurityCode: Yup.string().when('chargerPaymentMethodType', {
      is: 'CREDIT_CARD',
      then: Yup.string()
        .required('campo obrigatório')
        .test(
          'test-securityCode',
          'código de segurança do cartão de crédito é inválido',
          (value) => value && value.length >= 3 && value.length <= 4
        ),
    }),
  });

  const handleConvertion = (value) => {
    setLoading(true);
    axios
      .get('https://economia.awesomeapi.com.br/json/last/USD-BRL')
      .then((response) => {
        const usdToBrl = response.data.USDBRL.bid;
        console.log(usdToBrl, value);
        const usdConvert = parseFloat(value) / parseFloat(usdToBrl);
        setUsdConvert(usdConvert.toFixed(2));
      })
      .catch((error) => {
        console.error('Erro ao buscar a taxa de conversão:', error);
        errorHandler(error);
      });
    setLoading(false);
  };

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:payments.title')} />
      <Box>
        {!formDisabled ? (
          <Formik
            validateOnMount={false}
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={orderData}
            enableReinitialize
            onSubmit={handleSubmit}
            validator={() => ({})}
            validationSchema={validationSchema}
            disabled={loading}
          >
            {({ errors, isSubmitting, values, setValues }) => (
              <>
                <Global />
                <Spin spinning={loading || isSubmitting} tip="Aguarde processando dados..." size="large">
                  {isSubmitting || loading ? <p>Aguarde processando dados...</p> : null}
                  <Form>
                    {/* <Steps current={current} items={items} /> */}
                    {/* <div className="steps-content" style={{ display: current === 0 ? 'block' : 'none' }}> </div> */}
                    <DivTitle>{t('screens:payments.data.items')}</DivTitle>
                    <Row gutter={24}>
                      <Col span={12}>
                        {/*
                          <p><b>{t('screens:payments.data.itemType')}</b>: {values.itemType}</p>
                          <p><b>{t('screens:payments.data.itemReferenceId')}</b>: {values.itemReferenceId}</p>
                          <p><b>{t('screens:payments.data.itemName')}itemName</b>: {values.itemName}</p>
                          <p><b>{t('screens:payments.data.itemQuantity')}itemQuantity</b>: {values.itemQuantity}</p>
                          <p><b>{t('screens:payments.data.itemUnitAmount')}itemUnitAmount</b>: {values.itemUnitAmount}</p>
                          <p><b>{t('screens:payments.data.chargerReferenceId')}</b>: {values.chargerReferenceId}</p>
                          <p><b>{t('screens:payments.data.chargerPaymentMethodSoftDescriptor')}</b>: {values.chargerPaymentMethodSoftDescriptor}</p>
                      */}
                        <p>
                          <b>{t('screens:payments.data.chargerDescription')}</b>: {values.chargerDescription}
                        </p>
                        <p>
                          <b>{t('screens:payments.data.chargerAmountValue')}</b>:{' '}
                          {formatPrice(values.chargerAmountValue)}
                        </p>
                      </Col>
                    </Row>
                    <DivTitle>{t('screens:payments.data.user')}</DivTitle>
                    <Row gutter={24}>
                      <Col
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 12, offset: 0 }}
                        md={{ span: 8, offset: 0 }}
                        lg={{ span: 8, offset: 0 }}
                        xl={{ span: 6, offset: 0 }}
                        xxl={{ span: 6, offset: 0 }}
                      >
                        <FormControl
                          error={errors.customerName}
                          field="customerName"
                          label={t('screens:payments.customerName')}
                          required
                        >
                          <Input name="customerName" />
                        </FormControl>
                      </Col>
                      <Col
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 12, offset: 0 }}
                        md={{ span: 8, offset: 0 }}
                        lg={{ span: 8, offset: 0 }}
                        xl={{ span: 6, offset: 0 }}
                        xxl={{ span: 6, offset: 0 }}
                      >
                        <FormControl
                          error={errors.customerEmail}
                          field="customerEmail"
                          label={t('screens:payments.customerEmail')}
                          required
                        >
                          <Input name="customerEmail" />
                        </FormControl>
                      </Col>
                      <Col
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 12, offset: 0 }}
                        md={{ span: 8, offset: 0 }}
                        lg={{ span: 8, offset: 0 }}
                        xl={{ span: 6, offset: 0 }}
                        xxl={{ span: 6, offset: 0 }}
                      >
                        <FormControl
                          error={errors.customerTaxId}
                          field="customerTaxId"
                          label={t('screens:payments.customerTaxId')}
                          required
                        >
                          <Input
                            name="customerTaxId"
                            value={values.customerTaxId}
                            onChange={(e) => {
                              const { value } = e.target;
                              setValues({ ...values, customerTaxId: value });
                            }}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                    <DivTitle>{t('screens:payments.data.phone')}</DivTitle>
                    <Row gutter={24}>
                      {/* PHONE COUNTRY CODE */}
                      <Col
                        xs={{ span: 12, offset: 0 }}
                        sm={{ span: 6, offset: 0 }}
                        md={{ span: 4, offset: 0 }}
                        lg={{ span: 4, offset: 0 }}
                        xl={{ span: 3, offset: 0 }}
                        xxl={{ span: 3, offset: 0 }}
                      >
                        <FormControl
                          error={errors.customerPhoneCountry}
                          field="customerPhoneCountry"
                          label={t('screens:payments.customerPhoneCountry')}
                          required
                        >
                          <Input name="customerPhoneCountry" />
                        </FormControl>
                      </Col>
                      {/* PHONE AREA CODE  */}
                      <Col
                        xs={{ span: 12, offset: 0 }}
                        sm={{ span: 6, offset: 0 }}
                        md={{ span: 4, offset: 0 }}
                        lg={{ span: 4, offset: 0 }}
                        xl={{ span: 3, offset: 0 }}
                        xxl={{ span: 3, offset: 0 }}
                      >
                        <FormControl
                          error={errors.customerPhoneArea}
                          field="customerPhoneArea"
                          label={t('screens:payments.customerPhoneArea')}
                          required
                        >
                          <Input name="customerPhoneArea" />
                        </FormControl>
                      </Col>
                      {/* PHONE NUMBER  */}
                      <Col
                        xs={{ span: 12, offset: 0 }}
                        sm={{ span: 12, offset: 0 }}
                        md={{ span: 8, offset: 0 }}
                        lg={{ span: 8, offset: 0 }}
                        xl={{ span: 6, offset: 0 }}
                        xxl={{ span: 6, offset: 0 }}
                      >
                        <FormControl
                          error={errors.customerPhoneNumber}
                          field="customerPhoneNumber"
                          label={t('screens:payments.customerPhoneNumber')}
                          required
                        >
                          <Input name="customerPhoneNumber" />
                        </FormControl>
                      </Col>
                      {/* PHONE TYPE (MOBILE - if it's a cell phone, BUSINESS - if it's a business phone, HOME - if it's a residential phone.) */}
                      <Col
                        xs={{ span: 12, offset: 0 }}
                        sm={{ span: 12, offset: 0 }}
                        md={{ span: 8, offset: 0 }}
                        lg={{ span: 8, offset: 0 }}
                        xl={{ span: 6, offset: 0 }}
                        xxl={{ span: 6, offset: 0 }}
                      >
                        <FormControl
                          error={errors.customerPhoneType}
                          field="customerPhoneType"
                          label={t('screens:payments.customerPhoneType')}
                          required
                        >
                          <Select name="customerPhoneType" allowClear showSearch>
                            <Select.Option key="MOBILE" value="MOBILE">
                              Celular
                            </Select.Option>
                            <Select.Option key="BUSINESS" value="BUSINESS">
                              Comercial
                            </Select.Option>
                            <Select.Option key="HOME" value="HOME">
                              Residencial
                            </Select.Option>
                          </Select>
                        </FormControl>
                      </Col>
                    </Row>

                    {/* SHIPPING/ BOLETO ADDRESS */}
                    <div
                      style={{
                        display: values.chargerPaymentMethodType === 'BOLETO' ? 'block' : 'none',
                      }}
                    >
                      <DivTitle>{t('screens:payments.data.address')}</DivTitle>
                      <Row gutter={24}>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 12, offset: 0 }}
                          md={{ span: 12, offset: 0 }}
                          lg={{ span: 8, offset: 0 }}
                          xl={{ span: 6, offset: 0 }}
                          xxl={{ span: 6, offset: 0 }}
                        >
                          <FormControl
                            error={errors.shippingAddressStreet}
                            field="shippingAddressStreet"
                            label={t('screens:payments.shippingAddressStreet')}
                            required
                          >
                            <Input name="shippingAddressStreet" />
                          </FormControl>
                        </Col>
                        <Col
                          xs={{ span: 12, offset: 0 }}
                          sm={{ span: 12, offset: 0 }}
                          md={{ span: 6, offset: 0 }}
                          lg={{ span: 4, offset: 0 }}
                          xl={{ span: 3, offset: 0 }}
                          xxl={{ span: 3, offset: 0 }}
                        >
                          <FormControl
                            error={errors.shippingAddressNumber}
                            field="shippingAddressNumber"
                            label={t('screens:payments.shippingAddressNumber')}
                            required
                          >
                            <Input name="shippingAddressNumber" />
                          </FormControl>
                        </Col>
                        <Col
                          xs={{ span: 12, offset: 0 }}
                          sm={{ span: 12, offset: 0 }}
                          md={{ span: 6, offset: 0 }}
                          lg={{ span: 4, offset: 0 }}
                          xl={{ span: 3, offset: 0 }}
                          xxl={{ span: 3, offset: 0 }}
                        >
                          <FormControl
                            field="shippingAddressComplement"
                            label={t('screens:payments.shippingAddressComplement')}
                          >
                            <Input name="shippingAddressComplement" />
                          </FormControl>
                        </Col>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 12, offset: 0 }}
                          md={{ span: 12, offset: 0 }}
                          lg={{ span: 8, offset: 0 }}
                          xl={{ span: 6, offset: 0 }}
                          xxl={{ span: 6, offset: 0 }}
                        >
                          <FormControl
                            error={errors.shippingAddressLocality}
                            field="shippingAddressLocality"
                            label={t('screens:payments.shippingAddressLocality')}
                            required
                          >
                            <Input name="shippingAddressLocality" />
                          </FormControl>
                        </Col>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 12, offset: 0 }}
                          md={{ span: 12, offset: 0 }}
                          lg={{ span: 8, offset: 0 }}
                          xl={{ span: 6, offset: 0 }}
                          xxl={{ span: 6, offset: 0 }}
                        >
                          <FormControl
                            error={errors.shippingAddressCity}
                            field="shippingAddressCity"
                            label={t('screens:payments.shippingAddressCity')}
                            required
                          >
                            <Input name="shippingAddressCity" />
                          </FormControl>
                        </Col>
                        <Col
                          xs={{ span: 12, offset: 0 }}
                          sm={{ span: 12, offset: 0 }}
                          md={{ span: 6, offset: 0 }}
                          lg={{ span: 4, offset: 0 }}
                          xl={{ span: 3, offset: 0 }}
                          xxl={{ span: 3, offset: 0 }}
                        >
                          <FormControl
                            error={errors.shippingAddressRegionCode}
                            field="shippingAddressRegionCode"
                            label={t('screens:payments.shippingAddressRegionCode')}
                            required
                          >
                            <Input name="shippingAddressRegionCode" />
                          </FormControl>
                        </Col>
                        <Col
                          xs={{ span: 12, offset: 0 }}
                          sm={{ span: 12, offset: 0 }}
                          md={{ span: 6, offset: 0 }}
                          lg={{ span: 8, offset: 0 }}
                          xl={{ span: 6, offset: 0 }}
                          xxl={{ span: 6, offset: 0 }}
                        >
                          <FormControl
                            error={errors.shippingAddressCountry}
                            field="shippingAddressCountry"
                            label={t('screens:payments.shippingAddressCountry')}
                            required
                          >
                            <Select name="shippingAddressCountry" allowClear showSearch>
                              {countryCodes.countries.map((country) => (
                                <Select.Option key={country.code} value={country.code}>
                                  {country.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </FormControl>
                        </Col>
                        <Col
                          xs={{ span: 12, offset: 0 }}
                          sm={{ span: 12, offset: 0 }}
                          md={{ span: 6, offset: 0 }}
                          lg={{ span: 4, offset: 0 }}
                          xl={{ span: 3, offset: 0 }}
                          xxl={{ span: 3, offset: 0 }}
                        >
                          <FormControl
                            error={errors.shippingAddressPostalCode}
                            field="shippingAddressPostalCode"
                            label={t('screens:payments.shippingAddressPostalCode')}
                            required
                          >
                            <Input name="shippingAddressPostalCode" />
                          </FormControl>
                        </Col>
                      </Row>
                    </div>
                    <Row gutter={24}>
                      <Col
                        xs={{ span: 3, offset: 0 }}
                        sm={{ span: 12, offset: 0 }}
                        md={{ span: 12, offset: 0 }}
                        lg={{ span: 5, offset: 0 }}
                        xl={{ span: 5, offset: 0 }}
                        xxl={{ span: 5, offset: 0 }}
                      >
                        <FormControl
                          field="inter_payment"
                          label="Pagamento internacional?"
                          error={errors.inter_payment}
                        >
                          <Switch
                            name="inter_payment"
                            onChange={(checked) => {
                              if (checked) {
                                handleConvertion(values.chargerAmountValue);
                              }
                            }}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                    {values.inter_payment ? (
                      <>
                        <Row gutter={24}>
                          <Col span={24}>
                            <p>Valor em R$: {values.chargerAmountValue}</p>
                          </Col>
                          <Col span={24}>
                            <p>Valor em USD: {usdConvert}</p>
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Paypal price={usdConvert} setLoading={setLoading} orderData={orderData} />
                          <Alert
                            message="Atenção"
                            description="O método de pagamento via PayPal é destinado apenas a contas que não sejam brasileiras."
                            type="warning"
                            showIcon
                            style={{ marginLeft: '40px', height: '80px' }}
                          />
                        </Row>
                      </>
                    ) : (
                      <>
                        <Row gutter={24}>
                          <Col
                            xs={{ span: 24, offset: 0 }}
                            sm={{ span: 12, offset: 0 }}
                            md={{ span: 12, offset: 0 }}
                            lg={{ span: 6, offset: 0 }}
                            xl={{ span: 6, offset: 0 }}
                            xxl={{ span: 6, offset: 0 }}
                          >
                            {
                              /* DISPLAY ALL FORM ERRORS */
                              // Object.keys(errors).length > 0 && (
                              //   <ul>
                              //     {Object.keys(errors).map((key) => (
                              //       <li key={key}>{errors[key]}</li>
                              //     ))}
                              //   </ul>
                              // )
                            }
                            <FormControl
                              error={errors.chargerPaymentMethodType}
                              field="chargerPaymentMethodType"
                              label={t('screens:payments.chargerPaymentMethodType')}
                              required
                            >
                              <Select
                                name="chargerPaymentMethodType"
                                onChange={(value) => {
                                  setValues({ ...values, chargerPaymentMethodType: value });
                                }}
                              >
                                <Select.Option value="CREDIT_CARD">Cartão de Crédito</Select.Option>
                                <Select.Option value="BOLETO">Boleto</Select.Option>
                                <Select.Option value="PIX">PIX</Select.Option>
                              </Select>
                            </FormControl>
                          </Col>
                        </Row>
                        <div
                          style={{
                            display: values.chargerPaymentMethodType === 'CREDIT_CARD' ? 'block' : 'none',
                            backgroundColor: '#efffef',
                            padding: '10px',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          <DivTitle>{t('screens:payments.data.credit_card')}</DivTitle>
                          <Row gutter={24}>
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 12, offset: 0 }}
                              md={{ span: 12, offset: 0 }}
                              lg={{ span: 6, offset: 0 }}
                              xl={{ span: 6, offset: 0 }}
                              xxl={{ span: 6, offset: 0 }}
                            >
                              <FormControl
                                error={errors.chargerPaymentMethodCardHolderName}
                                field="chargerPaymentMethodCardHolderName"
                                label={t('screens:payments.chargerPaymentMethodCardHolderName')}
                                required
                              >
                                <Input name="chargerPaymentMethodCardHolderName" />
                              </FormControl>
                            </Col>
                            <Col
                              xs={{ span: 24, offset: 0 }}
                              sm={{ span: 12, offset: 0 }}
                              md={{ span: 12, offset: 0 }}
                              lg={{ span: 6, offset: 0 }}
                              xl={{ span: 6, offset: 0 }}
                              xxl={{ span: 6, offset: 0 }}
                            >
                              <FormControl
                                error={errors.chargerPaymentMethodCardNumber}
                                field="chargerPaymentMethodCardNumber"
                                label={t('screens:payments.chargerPaymentMethodCardNumber')}
                                required
                              >
                                <Input name="chargerPaymentMethodCardNumber" />
                              </FormControl>
                            </Col>
                            <Col
                              xs={{ span: 8, offset: 0 }}
                              sm={{ span: 8, offset: 0 }}
                              md={{ span: 8, offset: 0 }}
                              lg={{ span: 4, offset: 0 }}
                              xl={{ span: 4, offset: 0 }}
                              xxl={{ span: 3, offset: 0 }}
                            >
                              <FormControl
                                error={errors.chargerPaymentMethodCardExpMonth}
                                field="chargerPaymentMethodCardExpMonth"
                                label={t('screens:payments.chargerPaymentMethodCardExpMonth')}
                                required
                              >
                                <Select name="chargerPaymentMethodCardExpMonth" allowClear showSearch>
                                  <Select.Option key="01" value="01">
                                    01
                                  </Select.Option>
                                  <Select.Option key="02" value="02">
                                    02
                                  </Select.Option>
                                  <Select.Option key="03" value="03">
                                    03
                                  </Select.Option>
                                  <Select.Option key="04" value="04">
                                    04
                                  </Select.Option>
                                  <Select.Option key="05" value="05">
                                    05
                                  </Select.Option>
                                  <Select.Option key="06" value="06">
                                    06
                                  </Select.Option>
                                  <Select.Option key="07" value="07">
                                    07
                                  </Select.Option>
                                  <Select.Option key="08" value="08">
                                    08
                                  </Select.Option>
                                  <Select.Option key="09" value="09">
                                    09
                                  </Select.Option>
                                  <Select.Option key="10" value="10">
                                    10
                                  </Select.Option>
                                  <Select.Option key="11" value="11">
                                    11
                                  </Select.Option>
                                  <Select.Option key="12" value="12">
                                    12
                                  </Select.Option>
                                </Select>
                              </FormControl>
                            </Col>
                            <Col
                              xs={{ span: 8, offset: 0 }}
                              sm={{ span: 8, offset: 0 }}
                              md={{ span: 8, offset: 0 }}
                              lg={{ span: 4, offset: 0 }}
                              xl={{ span: 4, offset: 0 }}
                              xxl={{ span: 3, offset: 0 }}
                            >
                              <FormControl
                                error={errors.chargerPaymentMethodCardExpYear}
                                field="chargerPaymentMethodCardExpYear"
                                label={t('screens:payments.chargerPaymentMethodCardExpYear')}
                                required
                              >
                                <Input name="chargerPaymentMethodCardExpYear" placeholder="YYYY" />
                              </FormControl>
                            </Col>
                            <Col
                              xs={{ span: 8, offset: 0 }}
                              sm={{ span: 8, offset: 0 }}
                              md={{ span: 8, offset: 0 }}
                              lg={{ span: 4, offset: 0 }}
                              xl={{ span: 4, offset: 0 }}
                              xxl={{ span: 3, offset: 0 }}
                            >
                              <FormControl
                                error={errors.chargerPaymentMethodCardSecurityCode}
                                field="chargerPaymentMethodCardSecurityCode"
                                label={t('screens:payments.chargerPaymentMethodCardSecurityCode')}
                                required
                              >
                                <Input name="chargerPaymentMethodCardSecurityCode" />
                              </FormControl>
                            </Col>
                          </Row>
                        </div>
                        <Row gutter={24}>
                          <Col span={12}>
                            <ButtonAntd type="primary" htmlType="submit" disabled={isSubmitting || loading}>
                              {t('screens:payments.data.submit')}
                            </ButtonAntd>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Form>
                </Spin>
              </>
            )}
          </Formik>
        ) : (
          <p>Item não disponível para pagamento.</p>
        )}
      </Box>
      {pixData && <PixForm pixData={pixData} visible={showPix} onClose={() => setShowPix(false)} />}
      {boletoData && <BoletoForm boletoData={boletoData} visible={showBoleto} onClose={() => setShowBoleto(false)} />}
    </DefaultLayout>
  );
}
