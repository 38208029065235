import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field } from 'formik';
import { Spin, message, Col, Upload, Modal, Radio as RadioAntd } from 'antd';
import { Input, Form, DatePicker, Select, Radio } from 'formik-antd';
import MaskedInput from 'antd-mask-input';
import * as Yup from 'yup';
import * as cep from 'cep-promise';
import { useHistory } from 'react-router-dom';

import locale from 'antd/es/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';

import { FaUpload, FaInfoCircle } from 'react-icons/fa';

import Thumb from '~/components/Thumb';
import FormActions from '~/components/Form/FormActions';
import Row from '~/components/Row';
import Box from '~/components/Box';
import Logo from '~/components/Logo';
import Button from '~/components/Button';
import BaseLayout from '~/pages/_layouts/base';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import errorHandler from '~/Utils/errorHandler';

import nationalities from '~/data/nationalities.json';

import api from '~/services/api';

import { validateCpf, getBase64, crypt, decrypt } from '~/Utils';
import { Container, GlobalStyle, DivTitle } from './styles';

const initialValues = {
  name: '',
  cpf: '',
  rg: '',
  dob: '',
  profession: '',
  email: '',
  nationality: '',
  company: '',
  department: '',
  academic_title: '',
  graduation_type: '',
  foreign: false,
  document: '',
  is_student: false,
  specialization_1: '',
  specialization_2: '',
  specialization_3: '',
  address: {
    zipcode: '',
    address: '',
    address_number: '',
    address_complement: '',
    city: '',
    state: '',
    country: '',
    neighborhood: '',
    phone: '',
    phone_extension: '',
    fax: '',
    fax_extension: '',
    mobile: '',
  },
};

export default function Register() {
  const history = useHistory();
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState({});
  const [loading, setLoading] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [file, setFile] = useState(null);
  const [graduationTypes, setGraduationTypes] = useState({});
  // const [fereign, setForeign] = useState(false);

  const handleFetchUserEmail = async (email) => {
    setLoading(true);
    try {
      const { data } = await api.get(`/auth/register/validate/email/${email}`);

      if (data?.exists) {
        setUserExists(true);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchScreenData = async () => {
    setLoading(true);

    setFile(false);
    setRecordData(initialValues);

    if (localStorage.getItem('@Portal:needs')) {
      const { needs } =
        JSON.parse(decrypt(localStorage.getItem('@Portal:needs'))) !== null &&
        JSON.parse(decrypt(localStorage.getItem('@Portal:needs')));

      setGraduationTypes(needs.graduation_types);
    } else {
      const { data } = await api.get('auth/register/fetch');
      const { graduation_types } = JSON.parse(data);
      setGraduationTypes(graduation_types);

      localStorage.setItem('@Portal:needs', crypt(JSON.stringify({ needs: { graduation_types } })));
    }

    setLoading(false);
  };

  const setFieldError = (setErrors, msg, field) => {
    if (msg) {
      message.error(msg);
    }
    const errors = {};
    errors[field] = msg;
    setErrors(errors);
  };

  const handleCPF = async (cpf, values, setErrors, setValues) => {
    if (!validateCpf(cpf)) {
      setFieldError(setErrors, 'CPF inválido', 'cpf');
      setValues({
        ...values,
        cpf: null,
      });
      return false;
    }

    setFieldError(setErrors, null, 'cpf');
    return true;
  };

  const PhoneInput = (inputName, inputPlaceholder, inputMask) => (
    <Field
      name={inputName}
      render={({ field }) => <MaskedInput mask={inputMask} {...field} id={inputName} placeholder={inputPlaceholder} />}
    />
  );

  const handleRemoveFile = () => {
    document.getElementById('uploadButton').style.cursor = 'pointer';
    setFile(null);
  };

  const beforeUpload = (newFile) => {
    setLoading(true);

    let justOne = true;
    if (file) {
      justOne = false;
      message.error('Você só pode anexar 1 arquivo');
    }

    const isValid =
      newFile.type === 'image/jpeg' ||
      newFile.type === 'image/jpg' ||
      newFile.type === 'image/png' ||
      newFile.type === 'application/pdf';
    if (!isValid) {
      message.error('Você só pode anexar arquivos do tipo: JPG/JPEG/PNG/PDF');
      setTimeout(() => {
        handleRemoveFile();
      }, 100);
    }
    const isLt2M = newFile.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('O arquivo deve ter menos de 20MB!');
      setTimeout(() => {
        handleRemoveFile();
      }, 100);
    }

    setLoading(false);
    return isValid && isLt2M && justOne;
  };

  const handleUpload = (event) => {
    setLoading(true);

    try {
      if (!event.file.originFileObj) {
        setLoading(false);
        return;
      }

      let extension = '';
      let name = '';
      if (event.file.name.substring(event.file.name.length - 4) === 'jpeg') {
        extension = event.file.name.substring(event.file.name.length - 5);
        name = event.file.name.replaceAll('.', '');
        name = name.substring(0, name.length - 4);
      } else {
        extension = event.file.name.substring(event.file.name.length - 4);
        name = event.file.name.replaceAll('.', '');
        name = name.substring(0, name.length - 3);
      }
      event.file.name = name + extension;

      if (!event.file.id) {
        getBase64(event.file.originFileObj, (image) => {
          event.file.base64Url = image;
        });
      }
      document.getElementById('uploadButton').style.cursor = 'no-drop';
      setTimeout(() => {
        setFile(event.file);
      }, 500);
    } catch (error) {
      errorHandler(error);
    }

    setLoading(false);
  };

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Insira um e-mail válido')
      .required('e-mail campo obrigatório')
      .max(60, 'O email só pode ter até 60 caracteres'),
    name: Yup.string()
      .required()
      .min(5, 'O nome tem que ter pelo menos 05 caracteres')
      .max(50, 'O nome só pode ter até 50 caracteres')
      .test('name', 'É necessário pelo menos um sobrenome', (value) => {
        if (value.includes(' ')) {
          return true;
        }
        return false;
      })
      .test('name', 'O nome não pode incluir espaços duplos', (value) => {
        if (value.includes('  ')) {
          return false;
        }
        return true;
      })
      .test('name', 'O nome não pode acabar com um espaço', (value) => {
        if (value.endsWith(' ')) {
          return false;
        }
        return true;
      })
      .test('name', 'O nome não pode começar com um espaço', (value) => {
        if (value.startsWith(' ')) {
          return false;
        }
        return true;
      }),

    academic_title: Yup.string().nullable(),
    specialization_1: Yup.string().nullable(),
    specialization_2: Yup.string().nullable(),
    specialization_3: Yup.string().nullable(),
    department: Yup.string().nullable(),
    company: Yup.string().nullable(),
    graduation_type: Yup.string()
      .nullable()
      .when('is_student', {
        is: true,
        then: Yup.string()
          .matches(/(graduate|doctor|master|postdoctoral)/, 'Insira um tipo válido!', { excludeEmptyString: true })
          .required(),
      }),
    nationality: Yup.string().required(),
    dob: Yup.date().required(),
    cpf: Yup.string().nullable().when('foreign', {
      is: false,
      then: Yup.string().required(),
    }),
    document: Yup.string()
      .nullable()
      .when('foreign', {
        is: true,
        then: Yup.string().required().min(4),
      }),
    rg: Yup.string().nullable(),
    // rg: Yup.string().nullable().min(8).max(8),
    foreign: Yup.boolean().required(),
    is_student: Yup.boolean().required(),
    address: Yup.object().shape({
      zipcode: Yup.string().required('CEP é obrigatório'),
      address: Yup.string().required().max(80, 'O endereço só pode ter até 80 caracteres'),
      address_number: Yup.string().required().max(20, 'O número só pode ter até 20 caracteres'),
      address_complement: Yup.string().nullable().max(40, 'O complemento só pode ter até 40 caracteres'),
      city: Yup.string()
        .required()
        .max(60, 'A cidade só pode ter até 60 caracteres')
        .min(2, 'A cidade tem que ter pelo menos 2 caracteres'),
      state: Yup.string().required().max(2).min(2),
      country: Yup.string().required(),
      neighborhood: Yup.string().required(),
      phone: Yup.string()
        .nullable()
        .test(
          'phone',
          'Insira um formato de telefone válido.',
          (value) => (!value ? true : value?.replace(/\D/g, '')?.length < 15)
          // !value ? true : value?.replace(/\D/g, '')?.length === 10
        ),
      phone_extension: Yup.string().nullable(),
      fax: Yup.string().nullable(),
      fax_extension: Yup.string().nullable(),
      mobile: Yup.string()
        .when('phone', {
          is: (value) => !value,
          then: Yup.string().required(),
        })
        .test('mobile', 'Insira um formato de celular válido.', (value) => value?.replace(/\D/g, '')?.length === 11),
    }),
  });

  const handleFindAddress = async (value, values, setErrors, setValues) => {
    setLoading(true);
    try {
      if (!values.foreign) {
        // const address = await cep(value);
        const address = await cep(value, { timeout: 10000 });
        const data = {
          ...values,
          address: {
            ...values.address,
            zipcode: value,
            city: address.city,
            state: address.state,
            neighborhood: address.neighborhood === '' ? '-' : address.neighborhood,
            address: address.street,
            /* address: address.street ( values.address.address.toString() === "" ? address.street : values.address.address.toString() ), */
            country: 'Brasil',
          },
        };
        setValues(data);
      } else {
        const data = {
          ...values,
          address: {
            ...values.address,
            zipcode: value,
            city: values.address.city,
            state: values.address.state,
            neighborhood: values.address.neighborhood === '' ? '-' : values.address.neighborhood,
            address: values.address.address,
            /* address: address.street ( values.address.address.toString() === "" ? address.street : values.address.address.toString() ), */
            country: 'Brasil',
          },
        };
        setValues(data);
      }
    } catch (error) {
      message.error('Endereço não localizado!');
      setErrors({
        address: {
          zipcode: 'CEP não encontrado',
        },
      });
    }
    setLoading(false);
  };

  const handleSubmit = async (values, { setErrors, setValues }) => {
    setLoading(true);
    const validCpf = values.cpf && (await handleCPF(values.cpf.replace(/[^0-9,]*/g, ''), values, setErrors, setValues));
    if (validCpf || values.foreign) {
      try {
        const { data } = await api.post('/auth/register', values);
        const person = JSON.parse(data);

        localStorage.setItem('@Portal:login', crypt(JSON.stringify({ email: values.email, remember: true })));

        if (file && file.originFileObj && person.id) {
          const files = new FormData();
          files.append('file', file.originFileObj);
          await api.post(`/auth/register/${person.id}/upload/receipt`, files);
        }

        message.success(t('messages:successRegister'));
        setFile(null);
        history.push('/');
      } catch (error) {
        setErrors(errorHandler(error));
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchScreenData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCategory = (isStudent, graduationType) => {
    if (isStudent) return 'student';
    if (!isStudent && graduationType === 'postdoctoral') return 'postdoctoral';
    if (!isStudent && graduationType === null) return 'professional';
    return 'professional';
  };

  // const handleForeignChange = (e) => {
  //   setForeign(e.target.value); // Atualiza o estado "foreign" com o novo valor
  // };

  return (
    <BaseLayout>
      <Container className="register-container">
        <GlobalStyle />
        <Logo isLogin height="45px" width="202px" margin="0 0 40px 0" />

        <div className="info-section">
          <h2>Registre-se</h2>
          <p>Preencha corretamente os campos, para se cadastrar e acessar o nosso Portal!</p>
        </div>
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={recordData}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={schema}
        >
          {({ errors, values, setErrors, setValues, isSubmitting, setFieldValue }) => (
            <Spin spinning={loading || isSubmitting}>
              <Form>
                <Box>
                  <DivTitle>{t('screens:people.data.data')}</DivTitle>
                  <Row>
                    <Col xs={8}>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.name}
                        field="name"
                        label={t('screens:people.data.name')}
                        required
                      >
                        <Input name="name" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.email}
                        field="email"
                        label={t('screens:people.data.email')}
                        required
                      >
                        <Input
                          name="email"
                          onBlur={(e) => {
                            if (e.target.value && /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/.test(e.target.value)) {
                              handleFetchUserEmail(e.target.value);
                            }
                          }}
                          type="email"
                        />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.profession}
                        field="profession"
                        label={t('screens:people.data.profession')}
                      >
                        <Input name="profession" />
                      </FormControl>
                      <FormControl
                        error={errors.foreign}
                        cols={{ xs: 24 }}
                        field="foreign"
                        label={t('screens:people.data.foreign')}
                        required
                      >
                        <Radio.Group name="foreign" buttonStyle="solid">
                          <Radio.Button value>{t('messages:yes')}</Radio.Button>
                          <Radio.Button value={false}>{t('messages:no')}</Radio.Button>
                        </Radio.Group>
                      </FormControl>
                      {!values.foreign ? (
                        <>
                          <FormControl
                            cols={{ xs: 24 }}
                            error={errors.rg}
                            field="rg"
                            label={t('screens:people.data.rg')}
                          >
                            <Input name="rg" />
                            {/* <Field
                                name="rg"
                                render={({ field }) => (
                                  <MaskedInput mask="11.111.111-1" {...field} id="rg" placeholder="__.___.___-_" />
                                )}
                              /> */}
                          </FormControl>
                          <FormControl
                            cols={{ xs: 24 }}
                            error={errors.cpf}
                            field="cpf"
                            label={t('screens:people.data.cpf')}
                            required
                          >
                            <Field
                              name="cpf"
                              render={({ field }) => (
                                <MaskedInput
                                  mask="111.111.111-11"
                                  {...field}
                                  id="cpf"
                                  onBlur={(e) => {
                                    const cpf = e.target.value.replace(/[^0-9,]*/g, '');
                                    handleCPF(cpf, values, setErrors, setValues);
                                  }}
                                  placeholder="___.___.___-__"
                                />
                              )}
                            />
                          </FormControl>
                        </>
                      ) : (
                        <>
                          <FormControl
                            cols={{ xs: 24 }}
                            error={errors.document}
                            field="document"
                            label={t('screens:people.data.document')}
                            required
                          >
                            <Input name="document" />
                          </FormControl>
                        </>
                      )}
                    </Col>
                    <Col xs={8}>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.dob}
                        field="dob"
                        label={t('screens:people.data.dob')}
                        required
                      >
                        <DatePicker format="DD/MM/YYYY" name="dob" placeholder={t('messages:select')} locale={locale} />
                      </FormControl>

                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.nationality}
                        field="nationality"
                        label={t('fields:nationality')}
                      >
                        <Select name="nationality" allowClear showSearch optionFilterProp="children">
                          {nationalities &&
                            nationalities.map((item) => (
                              <Select.Option key={item.nome} value={item.nome}>
                                {item.nome}
                              </Select.Option>
                            ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.company}
                        field="company"
                        label={t('screens:people.data.company')}
                      >
                        <Input name="company" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.department}
                        field="department"
                        label={t('screens:people.data.department')}
                      >
                        <Input name="department" />
                      </FormControl>
                    </Col>
                    <Col xs={8}>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.academic_title}
                        field="academic_title"
                        label={t('screens:people.data.academic_title')}
                      >
                        <Input name="academic_title" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.specialization_1}
                        field="specialization_1"
                        label={t('screens:people.data.specialization_1')}
                      >
                        <Input name="specialization_1" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.specialization_2}
                        field="specialization_2"
                        label={t('screens:people.data.specialization_2')}
                      >
                        <Input name="specialization_2" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.specialization_3}
                        field="specialization_3"
                        label={t('screens:people.data.specialization_3')}
                      >
                        <Input name="specialization_3" />
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <DivTitle>{t('screens:people.data.address')}</DivTitle>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.zipcode}
                        field="address.zipcode"
                        label={!values.foreign ? t('screens:people.data.zipcode') : 'Zip Code'}
                        required
                      >
                        <Field
                          name="address.zipcode"
                          render={({ field }) => (
                            <MaskedInput
                              mask="11.111-111"
                              {...field}
                              placeholder="__.___-__"
                              autoComplete="dontshow"
                              onBlur={(e) => {
                                const zipcode = e.target.value.replace(/[^0-9,]*/g, '');
                                if (zipcode.length === 8) {
                                  handleFindAddress(zipcode, values, setErrors, setValues);
                                }
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.address}
                        field="address.address"
                        label={t('screens:people.data.address')}
                        required
                      >
                        <Input name="address.address" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.address_number}
                        field="address.address_number"
                        label={t('screens:people.data.address_number')}
                        required
                      >
                        <Input name="address.address_number" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.address_complement}
                        field="address.address_complement"
                        label={t('screens:people.data.address_complement')}
                      >
                        <Input name="address.address_complement" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.city}
                        field="address.city"
                        label={t('screens:people.data.city')}
                        required
                      >
                        <Input name="address.city" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.state}
                        field="address.state"
                        label={t('screens:people.data.state')}
                        required
                      >
                        <Input name="address.state" maxLength={2} />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.country}
                        field="address.country"
                        label={t('screens:people.data.country')}
                        required
                      >
                        <Input name="address.country" />
                      </FormControl>
                    </Col>
                    <Col xs={8}>
                      <DivTitle>{t('screens:people.data.contact')}</DivTitle>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.phone}
                        field="address.phone"
                        label={t('screens:people.data.phone')}
                      >
                        <Input name="address.phone" />
                        {/* {PhoneInput('address.phone', '(__) ____-____', '(11) 1111-1111')} */}
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.phone_extension}
                        field="address.phone_extension"
                        label={t('screens:people.data.phone_extension')}
                      >
                        <Input name="address.phone_extension" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.fax}
                        field="address.fax"
                        label={t('screens:people.data.fax')}
                      >
                        <Input name="address.fax" />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.address && errors.address.fax_extension}
                        field="address.fax_extension"
                        label={t('screens:people.data.fax_extension')}
                      >
                        <Input name="address.fax_extension" />
                      </FormControl>
                      <FormControl
                        error={errors.address && errors.address.mobile}
                        field="address.mobile"
                        label={t('screens:people.data.mobile')}
                        required
                      >
                        {PhoneInput('address.mobile', '(__) _____-____', '(11) 11111-1111')}
                      </FormControl>
                    </Col>
                    <Col xs={8}>
                      <DivTitle>{t('screens:people.data.features')}</DivTitle>
                      <FormControl error={errors.is_student} field="is_student" label="Categoria">
                        <RadioAntd.Group
                          name="category"
                          buttonStyle="solid"
                          value={handleCategory(values.is_student, values.graduation_type)}
                          onChange={(e) => {
                            if (e.target.value === 'student') {
                              setFieldValue('is_student', true);
                              setFieldValue('graduation_type', 'graduate');
                            } else {
                              setFieldValue('is_student', false);
                            }
                            if (e.target.value === 'postdoctoral') {
                              setFieldValue('graduation_type', 'postdoctoral');
                            }
                            if (e.target.value === 'professional') {
                              setFieldValue('graduation_type', null);
                            }
                          }}
                        >
                          <RadioAntd.Button value="student">Estudante</RadioAntd.Button>
                          <RadioAntd.Button value="professional">Profissional</RadioAntd.Button>
                          <RadioAntd.Button value="postdoctoral">Pós-Doc</RadioAntd.Button>
                        </RadioAntd.Group>
                        <Input name="is_student" type="hidden" />
                      </FormControl>
                      {values.is_student && (
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.graduation_type}
                          field="graduation_type"
                          label={t('screens:people.data.graduation_type')}
                          required
                        >
                          <Select
                            name="graduation_type"
                            allowClear
                            autoComplete="dontshow"
                            showSearch
                            optionFilterProp="children"
                          >
                            {graduationTypes &&
                              graduationTypes.map((item) => {
                                if (item.id !== 'postdoctoral')
                                  return (
                                    <Select.Option key={item.id} value={item.id}>
                                      {item.name}
                                    </Select.Option>
                                  );
                                return null;
                              })}
                          </Select>
                        </FormControl>
                      )}
                      {/* <FormControl
                        error={errors.is_student}
                        cols={{ xs: 24 }}
                        field="is_student"
                        label={t('screens:people.data.is_student')}
                      >
                        <Radio.Group name="is_student" buttonStyle="solid">
                          <Radio.Button value>{t('messages:yes')}</Radio.Button>
                          <Radio.Button value={false}>{t('messages:no')}</Radio.Button>
                        </Radio.Group>
                      </FormControl> */}
                      {/* {values.is_student && (
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.graduation_type}
                          field="graduation_type"
                          label={t('screens:people.data.graduation_type')}
                          required
                        >
                          <Select
                            name="graduation_type"
                            allowClear
                            autoComplete="dontshow"
                            showSearch
                            optionFilterProp="children"
                          >
                            {graduationTypes &&
                              graduationTypes.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </FormControl>
                      )} */}
                      <Row>
                        <Upload
                          disabled={file}
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          onChange={handleUpload}
                          // accept="image/png, image/jpeg, image/jpg, application/pdf"
                        >
                          <Button style={{ marginLeft: '1rem' }} id="uploadButton" color="default">
                            Anexar arquivo
                            <FaUpload />
                          </Button>
                        </Upload>
                      </Row>
                      <Row>
                        <Thumb
                          file={file}
                          fileURL={recordData.receipt_url}
                          onDelete={() => {
                            handleRemoveFile();
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <FormActions>
                      <Button type="submit" color="primary">
                        Salvar
                      </Button>
                      <Button
                        onClick={() => {
                          history.push('/');
                        }}
                      >
                        Cancelar
                      </Button>
                    </FormActions>
                  </Row>
                </Box>
              </Form>
              <Modal
                info
                title={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <div className="modal-title">
                    <FaInfoCircle />
                    Alerta de registro existente!
                  </div>
                }
                onCancel={() => {
                  history.push('/');
                }}
                style={{ maxWidth: '450px' }}
                visible={userExists}
                loading={loading}
                centered
                footer={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <ModalFooter
                    onCancel={() => {
                      history.push('/');
                    }}
                    onOk={() => {
                      message.info('Recupere sua senha para acessar ao portal!');
                      history.push({ pathname: '/senha/link', state: { email: values.email } });
                    }}
                    cancelText="Não"
                    okText="Sim"
                  />
                }
              >
                <h3>Deseja recuperar a senha para entrar no portal?</h3>
              </Modal>
            </Spin>
          )}
        </Formik>
      </Container>
    </BaseLayout>
  );
}
