/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Modal, Spin, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import errorHandler from '~/Utils/errorHandler';
import { Table } from '~/components/Table';
import Row from '~/components/Row';
import { ModalFooter } from '~/components/Modal';
import { SpeechesGlobalStyle } from './styles';

export default function SpeechesModal({ visible, onClose, speeches }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      setRecordData(speeches);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const tableColumns = [
    {
      title: t('screens:congresses.data.speeches.theme'),
      dataIndex: 'theme',
      key: 'theme',
      render: (text, record) => record.theme,
    },
    {
      title: t('screens:congresses.data.speeches.presenter'),
      dataIndex: 'presenter',
      key: 'presenter',
      render: (text, record) => record.presenter,
    },
    {
      title: t('screens:congresses.data.speeches.date_time'),
      dataIndex: 'date_time',
      key: 'date_time',
      render: (text, record) => new Date(record.date_time).toLocaleString(),
    },
  ];

  useEffect(() => {
    setLoading(true);
    if (visible && speeches && speeches.length > 0) {
      fetchScreenData();
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title={recordData ? `${t('screens:congresses.data.speeches.title')}` : null}
      onCancel={onClose}
      style={{ maxWidth: '550px' }}
      visible={visible}
      loading={loading}
      centered
      width="90%"
      footer={<ModalFooter type="info" loading={loading} onCancel={onClose} cancelText="Voltar" />}
    >
      <SpeechesGlobalStyle />
      <Spin spinning={loading}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row>
              <Table columns={tableColumns} loading={loading} dataSource={recordData} rowKey="id" />
            </Row>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
}
