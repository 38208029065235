import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineUser } from 'react-icons/ai';

import Avatar from '~/components/Avatar';

import { decrypt } from '~/Utils/index';
import { Container } from './styles';

export default function UserInfo({ children }) {
  const profile =
    JSON.parse(decrypt(localStorage.getItem('@Portal:person'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@Portal:person')));

  return (
    <Container>
      {children}
      <Avatar image={profile && profile.avatar_url}>
        <AiOutlineUser />
      </Avatar>
    </Container>
  );
}

UserInfo.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
};
