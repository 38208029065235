/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useMemo } from 'react';
import { Formik } from 'formik';
import { Input, Form, Select, Switch, Radio } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { Spin, message, Popconfirm, Col, Tag, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { FaRegTrashAlt, FaPencilAlt, FaPlus, FaChevronUp, FaChevronDown, FaCapsules } from 'react-icons/fa';
import PropTypes from 'prop-types';

import Row from '~/components/Row';
import api from '~/services/api';
import DefaultLayout from '~/pages/_layouts/full';
import Button from '~/components/Button';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import FormControl from '~/components/Form/FormControl';
import FormActions from '~/components/Form/FormActions';
import errorHandler from '~/Utils/errorHandler';
import { decrypt, getColorByAbstractStatus } from '~/Utils/index';
import { Table, TableActions } from '~/components/Table';
import { DivTitle, GlobalStyle } from './styles';
import Comparison from './comparison';

const initialValues = {
  congress_id: '',
  subarea_id: '',
  resume: '',
  word_1: '',
  word_2: '',
  word_3: '',
  word_4: '',
  word_5: '',
  acknowledgement: '',
  sponsors: '',
  uf: 'SP',
};
const initialAuthorValues = {
  name: '',
  scientific_name: '',
  institution: '',
  presenter: false,
};

function transformToScientificName(name) {
  // Divide o nome em partes
  const nameParts = name.trim().split(/\s+/);

  // Separa o último nome (sobrenome) das iniciais
  const lastName = nameParts.pop();
  const initials = nameParts.map(part => `${part[0].toUpperCase()}.`);

  // Junta as iniciais e o sobrenome
  return `${lastName}, ${initials.join(' ')}`;
}

export default function AbstractForm(props) {
  const { id } = props.match.params;
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState({});
  const [authorsList, setAuthorList] = useState([]);
  const [actualAuthor, setActualAuthor] = useState(initialAuthorValues);
  const [congressesOptions, setCongressesOptions] = useState([]);
  const [subareaOptions, setSubareaOptions] = useState([]);
  const [states, setStates] = useState([]);
  const [text, setText] = useState('');
  const maxWords = 300;

  // get the name of logged user
  const { name, company } = JSON.parse(decrypt(localStorage.getItem('@Portal:person')));
  const scientificName = transformToScientificName(name);
  // console.log('logged user name', name, scientificName, company);
  // console.log('recordData', recordData);
  // console.log('authorsList', authorsList);

  const insertUserAsAuthor = () => {
    const user = { name, scientific_name: scientificName, institution: company, presenter: true };
    setAuthorList([user]);
  };

  const { cities } =
    JSON.parse(decrypt(localStorage.getItem('@Portal:needs'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@Portal:needs')));

  const person =
    JSON.parse(decrypt(localStorage.getItem('@Portal:person'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@Portal:person')));

  const fetchRecordData = async () => {
    try {
      if (id) {
        const response = await api.get(`/resumes/${id}`);
        const data = JSON.parse(response.data);
        console.log(data);
        setSubareaOptions(data.congress?.sub_areas);
        setAuthorList(data.authors);
        setRecordData(data);
      } else {
        setRecordData(initialValues);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchCongresses = async () => {
    try {
      const { data } = await api.get('/congresses/get/availables');
      setCongressesOptions(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleCongressChange = id => {
    const selected = congressesOptions.find(congress => congress.id === id);

    if (selected) {
      setSubareaOptions(selected.sub_areas);
    } else {
      setSubareaOptions([]);
    }
  };

  const fetchStates = async () => {
    try {
      const { estados } = await cities;
      const siglas = estados.map(item => item.sigla);
      setStates(siglas);
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      await fetchCongresses();
      await fetchRecordData();
      await fetchStates();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const canPersonEdit = () =>
    (!person.is_evaluator && recordData.status === 'Aguardando correção') || (!person.is_evaluator && !recordData.id);

  const canEvaluatorEdit = () => person.is_evaluator && recordData.status === 'Pendente';

  const validate = values => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Nome campo obrigatorio';
    }

    if (values.name && values.name.length < 3) {
      errors.name = 'Nome deve ser no mínimo 3';
    }

    if (!values.scientific_name) {
      errors.scientific_name = 'Nome Cientifíco campo obrigatorio';
    }

    if (!values.institution) {
      errors.institution = 'Instituição campo obrigatorio';
    }

    if (values.institution && values.institution.length < 3) {
      errors.institution = 'Instituição deve ser no mínimo 3';
    }

    return errors;
  };

  const additionalRules = values => {
    if (person.is_evaluator && !values.evaluator_observation && values.status !== 'approved') {
      message.error(t('messages:abstracts:evaluatorObservationIsRequired'));
      return;
    }

    if (!person.is_evaluator && values.status === 'Aguardando correção') {
      values.status = 'Pendente';
    }

    const atLeastOnePresenter = authorsList.some(author => author.presenter === true);

    if (!atLeastOnePresenter) {
      authorsList.forEach(author => {
        if (author.name === name) {
          author.presenter = true;
        }
      });
    }

    values.authors = authorsList
      .filter(a => a) // Remove índices vazios
      .map((author, index) => ({
        ...author,
        author_order: index + 1, // Define a ordem como índice + 1
      }));
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    additionalRules(values);

    try {
      if (values.id) {
        await api.put(`/resumes/${values.id}`, values);
        message.success(t('messages:abstracts:updated'));
      } else {
        await api.post('/resumes', values);
        message.success(t('messages:abstracts:created'));
      }
      history.push(`/resumos`);
    } catch (error) {
      if (error?.response?.status === 400) {
        message.error(t('messages:anErrorOcurred'));
      }

      const errors = errorHandler(error);
      setErrors(errors);

      if (errors && errors.authors && authorsList.length > 0) {
        const authorsErrors = authorsList.map(author => validate(author));
        if (authorsErrors.length > 0) {
          authorsErrors.forEach(index => {
            if (index.institution) {
              message.error(index.institution);
            }
            if (index.name) {
              message.error(index.name);
            }
            if (index.scientific_name) {
              message.error(index.scientific_name);
            }
          });
        }
      } else if (errors && errors.authors) {
        message.error(t('messages:abstracts:reviewAuthors'));
      }
    }

    setLoading(false);
  };

  const handleNewAuthor = async values => {
    setLoading(true);
    try {
      // NOTE: if authors has just one element, it's the presenter, uncomment the line below to allow only one presenter
      // if (authors && authors.length === 0) {
      //   values.presenter = true;
      // }
      if (authorsList?.length < 10) {
        setAuthorList(prevValues => [...prevValues, values]);
      } else {
        message.warning(t('messages:abstracts:allowedOnlyFiveAuthors'));
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleEditAuthor = async values => {
    setLoading(true);
    try {
      // Mapeia a lista de autores, substituindo o autor que foi editado
      const updatedAuthorsList = authorsList.map(author => {
        // Se o nome do autor corresponde ao que está sendo editado, substitui pelos novos valores
        if (author.name === actualAuthor.name) {
          return { ...author, ...values }; // Retorna o autor atualizado
        }
        return author; // Retorna o autor sem alterações
      });

      // Atualiza a lista de autores com a lista modificada
      setAuthorList(updatedAuthorsList);
    } catch (error) {
      // Lida com possíveis erros
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleBack = () => {
    history.push('/resumos');
  };

  const handleDeleteAuthor = async index => {
    const authorToDelete = authorsList[index];
    if (authorToDelete.presenter === true) {
      message.warning(t('messages:abstracts:deletePresenter'));
      return;
    }

    setLoading(true);
    try {
      const authors = authorsList;
      setAuthorList([]);
      delete authors[index];
      setAuthorList(authors);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleInputChange = e => {
    const inputText = e.target.value;
    const words = inputText
      .trim()
      .split(/\s+/)
      .filter(word => word !== '');
    const wordCount = words.length;

    // Verifica se a quantidade de palavras está dentro do limite permitido
    if (wordCount <= maxWords) {
      setText(inputText);
    }
  };

  const moveUp = index => {
    if (index === 0) return; // Não pode mover o primeiro item para cima
    const newList = [...authorsList];
    // Troca o item atual com o item anterior
    [newList[index - 1], newList[index]] = [newList[index], newList[index - 1]];
    setAuthorList(newList);
  };

  // Função para mover um item para baixo
  const moveDown = index => {
    if (index === authorsList.length - 1) return; // Não pode mover o último item para baixo
    const newList = [...authorsList];
    // Troca o item atual com o próximo item
    [newList[index], newList[index + 1]] = [newList[index + 1], newList[index]];
    setAuthorList(newList);
  };

  const wordCount = useMemo(
    () =>
      text
        .trim()
        .split(/\s+/)
        .filter(word => word !== '').length,
    [text]
  );

  useEffect(() => {
    insertUserAsAuthor();
    fetchScreenData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const abstractSchema = Yup.object().shape({
    congress_id: Yup.string().required(),
    subarea_id: Yup.string().required(),
    resume: Yup.string().required(),
    title: Yup.string().required(),
    word_1: Yup.string().required(),
    word_2: Yup.string().nullable(),
    word_3: Yup.string().nullable(),
    word_4: Yup.string().nullable(),
    word_5: Yup.string().nullable(),
    acknowledgement: Yup.string().required(),
    sponsors: Yup.string().nullable(),
    uf: Yup.string().required(),
  });

  const authorsTableColumns = [
    {
      title: t('screens:abstracts.authors.order'),
      dataIndex: 'order',
      key: 'order',
      render: (text, record, index) => <span>{index + 1}</span>,
      width: '150px', // Ajuste a largura conforme necessário
      align: 'center',
    },
    {
      title: t('screens:abstracts.authors.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('screens:abstracts.authors.scientific_name'),
      dataIndex: 'scientific_name',
      key: 'scientific_name',
    },
    {
      title: t('screens:abstracts.authors.institution'),
      dataIndex: 'institution',
      key: 'institution',
    },
    {
      title: t('screens:abstracts.authors.presenter'),
      dataIndex: 'presenter',
      key: 'presenter',
      render: (text, record) => (
        <Switch
          disabled
          checked={name === record.name}
          onClick={() => {
            const authors = authorsList.map(author => ({
              ...author,
              presenter: author === record,
            }));
            setAuthorList(authors);
          }}
        />
      ),
    },
    {
      title: t('screens:abstracts.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record, index) => (
        <TableActions>
          {/* NOTE: if is the first line in table, don't show the buttons below */}
          {canPersonEdit() && authorsList.length > 1 && (
            <>
              {name !== record.name && (
                <>
                  <Button size="small" title="Editar" onClick={() => setActualAuthor(record)}>
                    <FaPencilAlt />
                  </Button>

                  <Popconfirm
                    title={t('messages:confirmDelete')}
                    okText={t('messages:yes')}
                    onConfirm={() => handleDeleteAuthor(index)}
                    cancelText={t('messages:no')}
                  >
                    <Button size="small" title="Excluir">
                      <FaRegTrashAlt />
                    </Button>
                  </Popconfirm>
                </>
              )}
              <Button
                disabled={index === 0}
                onClick={() => moveUp(index)}
                style={{ width: '5px', border: '1px solid #dddddd', marginRight: '5px' }}
                color="green"
              >
                <FaChevronUp />
              </Button>
              <Button
                disabled={index === authorsList.length - 1}
                onClick={() => moveDown(index)}
                style={{ width: '5px', border: '1px solid #dddddd' }}
              >
                <FaChevronDown />
              </Button>
            </>
          )}
        </TableActions>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <GlobalStyle />
      <PageTitle
        title={t('screens:abstracts.title')}
        subtitle={recordData.name ? `editando - ${recordData.name}` : null}
      />
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={recordData}
        enableReinitialize
        onSubmit={handleSave}
        validationSchema={abstractSchema}
      >
        {({ errors, values, setErrors, setValues }) => (
          <Spin spinning={loading}>
            <Form>
              <Input type="hidden" name="id" />
              <Box>
                {recordData.id && !loading && (
                  <Row>
                    <FormControl cols={{ xl: 3, xs: 16, sm: 12 }} label={t('screens:abstracts.data.status')}>
                      <Tag color={getColorByAbstractStatus(recordData.status)}>{recordData.status}</Tag>
                    </FormControl>
                    {canEvaluatorEdit() ? (
                      <>
                        <FormControl
                          error={errors.status}
                          cols={{ xs: 24 }}
                          field="status"
                          label={t('screens:abstracts.data.situation')}
                          required
                        >
                          <Radio.Group name="status" buttonStyle="solid">
                            <Radio.Button value="Aprovado">{t('messages:approved')}</Radio.Button>
                            <Radio.Button value="Aguardando correção">{t('messages:waiting_fix')}</Radio.Button>
                            <Radio.Button className="disapproved-radio" value="Reprovado">
                              {t('messages:disapproved')}
                            </Radio.Button>
                          </Radio.Group>
                        </FormControl>
                        <FormControl
                          cols={{ xs: 24 }}
                          error={errors.evaluator_observation}
                          field="evaluator_observation"
                          label={t('screens:abstracts.data.evaluator_observation')}
                          required
                        >
                          <Input.TextArea rows={3} name="evaluator_observation" />
                        </FormControl>
                        <Divider style={{ margin: '1.5rem 0 1.5rem 0' }}>
                          <DivTitle>{t('screens:abstracts.data.resume_info')}</DivTitle>
                        </Divider>
                      </>
                    ) : (
                      canPersonEdit() &&
                      values.evaluator_observation && (
                        <>
                          <FormControl
                            cols={{ xs: 24 }}
                            error={errors.evaluator_observation}
                            field="evaluator_observation"
                            label={t('screens:abstracts.data.evaluator_observation')}
                          >
                            <p>{values.evaluator_observation}</p>
                          </FormControl>
                          <Divider style={{ margin: '1.5rem 0 1.5rem 0' }}>
                            <DivTitle>{t('screens:abstracts.data.resume_info')}</DivTitle>
                          </Divider>
                        </>
                      )
                    )}
                  </Row>
                )}
                {canEvaluatorEdit() && values?.original_values && recordData.status === 'Pendente' ? (
                  <Comparison values={values} />
                ) : (
                  <>
                    <Row>
                      <FormControl
                        cols={{ xl: 6, xs: 12, sm: 12 }}
                        error={errors.congress_id}
                        field="congress_id"
                        label={t('screens:abstracts.data.congress')}
                        required
                      >
                        <Select
                          name="congress_id"
                          onSelect={id => {
                            handleCongressChange(id);
                            let data = { ...values, congress_id: id };
                            if (values?.congress_id !== id) {
                              data = { ...data, subarea_id: '' };
                            }
                            setValues(data);
                          }}
                          onClear={() => {
                            setSubareaOptions([]);
                            setValues({ ...values, subarea_id: '' });
                          }}
                          allowClear
                          optionFilterProp="children"
                          disabled={recordData.id}
                        >
                          {congressesOptions &&
                            congressesOptions.map(item => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.description}
                              </Select.Option>
                            ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        cols={{ xl: 6, xs: 12, sm: 12 }}
                        error={errors.subarea_id}
                        field="subarea_id"
                        label={t('screens:abstracts.data.subarea')}
                        required
                      >
                        <Select
                          name="subarea_id"
                          value={values.subarea_id}
                          allowClear
                          optionFilterProp="children"
                          disabled={recordData.id}
                        >
                          {subareaOptions &&
                            subareaOptions.map(item => (
                              <Select.Option key={item.id} value={item.id}>
                                {item.name}
                              </Select.Option>
                            ))}
                        </Select>
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.title}
                        field="title"
                        label={t('screens:abstracts.data.title')}
                        required
                      >
                        <Input name="title" disabled={canPersonEdit() === false} />
                      </FormControl>
                      <FormControl
                        cols={{ xs: 24 }}
                        error={errors.resume}
                        field="resume"
                        label={t('screens:abstracts.data.abstract')}
                        required
                      >
                        {canEvaluatorEdit() ? (
                          <p>{values.resume}</p>
                        ) : (
                          <div>
                            <Input.TextArea
                              rows={5}
                              name="resume"
                              disabled={canPersonEdit() === false}
                              onChange={handleInputChange}
                            />
                            <div style={{ position: 'absolute', bottom: 140, right: 10, color: '#93c051' }}>
                              {wordCount}/{maxWords} palavras
                            </div>
                          </div>
                        )}
                      </FormControl>
                    </Row>
                    <Row style={canEvaluatorEdit() ? { justifyContent: 'space-between' } : {}}>
                      <FormControl
                        cols={{ xl: 4, xs: 24, sm: 12 }}
                        error={errors.word_1}
                        label={`${t('screens:abstracts.data.word')} 1`}
                        required
                      >
                        <Input name="word_1" disabled={canPersonEdit() === false} />
                      </FormControl>
                      <FormControl
                        cols={{ xl: 4, xs: 24, sm: 12 }}
                        error={errors.word_2}
                        label={`${t('screens:abstracts.data.word')} 2`}
                      >
                        <Input name="word_2" disabled={canPersonEdit() === false} />
                      </FormControl>
                      <FormControl
                        cols={{ xl: 4, xs: 24, sm: 12 }}
                        error={errors.word_3}
                        label={`${t('screens:abstracts.data.word')} 3`}
                      >
                        <Input name="word_3" disabled={canPersonEdit() === false} />
                      </FormControl>
                      <FormControl
                        cols={{ xl: 4, xs: 24, sm: 12 }}
                        error={errors.word_4}
                        label={`${t('screens:abstracts.data.word')} 4`}
                      >
                        <Input name="word_4" disabled={canPersonEdit() === false} />
                      </FormControl>
                      <FormControl
                        cols={{ xl: 4, xs: 24, sm: 12 }}
                        error={errors.word_5}
                        label={`${t('screens:abstracts.data.word')} 5`}
                      >
                        <Input name="word_5" disabled={canPersonEdit() === false} />
                      </FormControl>
                      {!canEvaluatorEdit() && (
                        <FormControl
                          cols={{ xl: 4, xs: 24, sm: 12 }}
                          error={errors.uf}
                          field="uf"
                          label={t('screens:abstracts.data.uf')}
                          required
                        >
                          <Select
                            name="uf"
                            allowClear
                            autoComplete="dontshow"
                            showSearch
                            optionFilterProp="children"
                            disabled={canPersonEdit() === false || recordData.id}
                          >
                            {states &&
                              states.map(item => (
                                <Select.Option key={item} value={item}>
                                  {item}
                                </Select.Option>
                              ))}
                          </Select>
                        </FormControl>
                      )}
                    </Row>
                  </>
                )}
                {!person.is_evaluator && (
                  <Row>
                    <FormControl
                      cols={{ xl: 12, xs: 24, sm: 24 }}
                      error={errors.acknowledgement}
                      label={t('screens:abstracts.data.acknowledgement')}
                      required
                    >
                      <Input name="acknowledgement" disabled={canPersonEdit() === false} />
                    </FormControl>
                    <FormControl
                      cols={{ xl: 12, xs: 24, sm: 24 }}
                      error={errors.sponsors}
                      label={t('screens:abstracts.data.sponsors')}
                    >
                      <Input name="sponsors" disabled={canPersonEdit() === false} />
                    </FormControl>
                  </Row>
                )}

                <Formik validateOnBlur={false} validateOnChange={false} initialValues={actualAuthor} enableReinitialize>
                  {({ errors, values, resetForm, setErrors, setValues }) => (
                    <Spin spinning={loading}>
                      <div style={person.is_evaluator ? { display: 'none' } : {}}>
                        <Input type="hidden" name="id" />
                        <Box>
                          <DivTitle>{t('screens:abstracts.authors.title')}</DivTitle>
                          <Row>
                            <FormControl
                              cols={{ xl: 6, xs: 24, sm: 12 }}
                              error={errors.name}
                              label={t('screens:abstracts.authors.name')}
                              required
                            >
                              <Input
                                name="name"
                                maxLength={255}
                                disabled={canPersonEdit() === false}
                                onBlur={() => {
                                  const abreviate = transformToScientificName(values.name); // const abreviate = handleAbreviate(values.name);
                                  if (!values.scientific_name) {
                                    setValues({ ...values, scientific_name: abreviate });
                                  }
                                }}
                              />
                            </FormControl>
                            <FormControl
                              cols={{ xl: 6, xs: 24, sm: 12 }}
                              error={errors.scientific_name}
                              field="scientific_name"
                              label={t('screens:abstracts.authors.scientific_name')}
                              required
                            >
                              <Input name="scientific_name" disabled={canPersonEdit() === false} maxLength={255} />
                            </FormControl>
                            <FormControl
                              cols={{ xl: 6, xs: 24, sm: 12 }}
                              error={errors.institution}
                              field="institution"
                              label={t('screens:abstracts.authors.institution')}
                              required
                            >
                              <Input name="institution" disabled={canPersonEdit() === false} maxLength={255} />
                            </FormControl>
                            {/* <FormControl
                              cols={{ xl: 2, xs: 24, sm: 6 }}
                              error={errors.presenter}
                              field="presenter"
                              label={t('screens:abstracts.authors.presenter')}
                              required
                            >
                              <Switch name="presenter" disabled={canPersonEdit() === false} />
                            </FormControl> */}
                            <Col xl={2} xs={24} sm={6} className="author-actions">
                              {canPersonEdit() &&
                                (actualAuthor && actualAuthor.name.length > 0 ? (
                                  <Button
                                    style={{ marginTop: '25px' }}
                                    color="primary"
                                    onClick={() => {
                                      const error = validate(values);
                                      setErrors(error);
                                      if (Object.keys(error).length === 0) {
                                        handleEditAuthor(values);
                                        resetForm();
                                        setActualAuthor(initialAuthorValues);
                                      }
                                    }}
                                  >
                                    Salvar
                                  </Button>
                                ) : (
                                  <Button
                                    style={{ marginTop: '25px' }}
                                    color="primary"
                                    onClick={() => {
                                      const error = validate(values);
                                      setErrors(error);
                                      if (Object.keys(error).length === 0) {
                                        handleNewAuthor(values);
                                        resetForm();
                                        setActualAuthor(initialAuthorValues);
                                      }
                                    }}
                                  >
                                    <FaPlus />
                                  </Button>
                                ))}
                            </Col>
                          </Row>
                          <Table
                            pagination={authorsList.length > 10}
                            pageSize={10}
                            rowKey={record => `${record.id}.${record.name}`}
                            loading={loading}
                            dataSource={authorsList}
                            columns={authorsTableColumns}
                          />
                        </Box>
                      </div>
                    </Spin>
                  )}
                </Formik>

                <Row>
                  <FormActions>
                    {(canPersonEdit() || canEvaluatorEdit()) && (
                      <Popconfirm
                        title={
                          person.is_evaluator
                            ? t('messages:confirmEvaluatorSaveAbstract')
                            : t('messages:confirmPersonSaveAbstract')
                        }
                        placement="topRight"
                        okText={t('messages:yes')}
                        onConfirm={() => {
                          abstractSchema.isValidSync(values);
                          abstractSchema
                            .validate(values, {
                              abortEarly: false,
                            })
                            .then(() => {
                              handleSave(values, { setErrors });
                            })
                            .catch(({ inner }) => {
                              const errors = inner.reduce(
                                (memo, { path, message }) => ({
                                  ...memo,
                                  [path]: (memo[path] || []).concat(message),
                                }),
                                {}
                              );

                              setErrors(errors);
                            });
                        }}
                        cancelText={t('messages:no')}
                      >
                        <Button className="ll-btn" type="submit" color="primary">
                          Salvar
                        </Button>
                      </Popconfirm>
                    )}

                    <Button onClick={handleBack}>Cancelar</Button>
                  </FormActions>
                </Row>
              </Box>
            </Form>
          </Spin>
        )}
      </Formik>
    </DefaultLayout>
  );
}

AbstractForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

AbstractForm.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: null,
    }),
  }),
};
