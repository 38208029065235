import React, { useEffect, useState } from 'react';

import { Col, Card, Typography } from 'antd';

import { FaUserTie } from 'react-icons/fa';
import Row from '~/components/Row';
import DefautLayout from '~/pages/_layouts/full';
import PageTitle from '~/components/PageTitle';

import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import AnnuityCheckout from '~/components/Annuity';
import { GlobalStyles } from './styles';

export default function Home() {
  const [recordData, setRecordData] = useState({
    id: null,
  });

  const fetchRecords = async () => {
    try {
      const { data } = await api.get(`/me`);
      setRecordData(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    fetchRecords();
  }, []);

  return (
    <DefautLayout>
      <GlobalStyles />
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <PageTitle title="Dashboard" subtitle="Bem vindo!" />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Card
            hoverable
            title={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Typography.Title>
                <FaUserTie /> Associe-se
              </Typography.Title>
            }
            bordered
          >
            <Typography.Title level={4}>Contribua com a SBFV!</Typography.Title>
            <Typography.Title level={4}>
              Seja um associado e participe dos cursos, congressos e eventos com mais benefícios.
            </Typography.Title>
            <br />
            <br />
            <AnnuityCheckout recordData={recordData} />
          </Card>
        </Col>
      </Row>
    </DefautLayout>
  );
}
