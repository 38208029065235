import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { formatCPF } from '@brazilian-utils/brazilian-utils';
import { Spin, Tag, Divider } from 'antd';
import BaseLayout from '~/pages/_layouts/base';

import Button from '~/components/Button';
import Logo from '~/components/Logo';
import api from '~/services/api';

import errorHandler from '~/Utils/errorHandler';
import { Container } from './styles';

export default function CertificateVerify({ match }) {
  const [recordData, setRecordData] = useState({
    subscription: {
      /*
        certificate_issued_at -> precisamos forcar o estado inicial como true,
        para depois ser atualizado pelo setter através do retorno da api.
        dessa forma, o preview inicial é mantido imútavel.
      */
      certificate_issued_at: true,
      person: {},
    },
    course: {},
    congress: {},
  });
  const [loading, setLoading] = useState(true);
  const { uuid } = match.params;
  const history = useHistory();

  const fetchRecordData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/subscriptions/show/${uuid}`);
      setRecordData(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRecordData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const issued = recordData.subscription.certificate_issued_at;

  return (
    <BaseLayout>
      <Container>
        <Logo isLogin height="45px" width="202px" margin="0 0 40px 0" />
        {!issued && (
          <div className="content">
            <h1>Verificador de Certificado</h1>
            <h2>
              <b style={{ color: '#F7A005' }}>Certificado ainda não emitido.</b>
            </h2>
          </div>
        )}
        <div className="content" style={{ display: !issued ? 'none' : '' }}>
          <h1>Verificador de Certificado</h1>
          <Divider style={{ marginBottom: '2rem' }}>
            <h2>
              <b>Inscrição</b>
            </h2>
          </Divider>
          {loading ? (
            <Spin spinning />
          ) : (
            <>
              <h2>
                <Tag color="gold">
                  <b>{recordData.subscription.uuid && recordData.subscription.uuid.toUpperCase()}</b>
                </Tag>
              </h2>
              <h2>
                Inscrição realizada em:{' '}
                <b>
                  {`${new Date(recordData.subscription.created_at).toLocaleDateString()} às
                              ${new Date(recordData.subscription.created_at).toLocaleTimeString()}`}
                </b>
              </h2>
            </>
          )}
          <Divider style={{ margin: '2rem 0 2rem 0' }}>
            <h2>
              <b>Pessoa</b>
            </h2>
          </Divider>
          {loading ? (
            <Spin spinning />
          ) : (
            <>
              <h2>
                Nome: <b>{recordData.subscription.person.name}</b>
              </h2>
              <h2>
                {recordData.subscription.person.foreign ? 'Documento' : 'CPF'}:{' '}
                <b>
                  {recordData.subscription.person.foreign
                    ? recordData.subscription.person.document
                    : formatCPF(recordData.subscription.person.cpf)}
                </b>
              </h2>
              <h2>
                Data de Nascimento: <b>{new Date(recordData.subscription.person.dob).toLocaleDateString()}</b>
              </h2>
            </>
          )}
          <Divider style={{ margin: '2rem 0 2rem 0' }}>
            <h2>
              <b>{recordData.course ? 'Curso' : 'Congresso'}</b>
            </h2>
          </Divider>
          {loading ? (
            <Spin spinning />
          ) : (
            <>
              <h2>
                Nome: <b>{recordData.course ? recordData.course.description : recordData.congress.description}</b>
              </h2>
              <h2>
                Certificado emitido em:{' '}
                <b>
                  {`${new Date(recordData.subscription.certificate_issued_at).toLocaleDateString()} às
                              ${new Date(recordData.subscription.certificate_issued_at).toLocaleTimeString()}`}
                </b>
              </h2>
            </>
          )}
        </div>

        <Button loading={loading} onClick={() => history.push('/')} color="primary">
          Voltar para Home
        </Button>
      </Container>
    </BaseLayout>
  );
}

CertificateVerify.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    }).isRequired,
  }),
};
CertificateVerify.defaultProps = {
  match: { params: { uuid: '' } },
};
