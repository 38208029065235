import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Content } from './styles';

export default function BaseLayout({ children }) {
  return (
    <Wrapper className="base-wrapper">
      <Content className="base-container">{children}</Content>
    </Wrapper>
  );
}

BaseLayout.propTypes = {
  children: PropTypes.node,
};
BaseLayout.defaultProps = {
  children: <></>,
};
