import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Input, Checkbox } from 'formik-antd';
import * as Yup from 'yup';

import Logo from '~/components/Logo';
import Button from '~/components/Button';
import BaseLayout from '~/pages/_layouts/base';
import FormControl from '~/components/Form/FormControl';

import { useAuth } from '~/contexts/auth';

import { crypt, decrypt } from '~/Utils';
import { Container, ButtonGlobalStyle } from './styles';

const storedValues =
  localStorage.getItem('@Portal:login') &&
  JSON.parse(decrypt(localStorage.getItem('@Portal:login'))) !== null &&
  JSON.parse(decrypt(localStorage.getItem('@Portal:login')));

const initialValues = storedValues || { remember: false, email: null, password: null };

export default function Login() {
  const { t } = useTranslation();
  const context = useAuth();
  const history = useHistory();

  const handleSubmit = (values) => {
    context.Login({ values, router: history });
  };

  const handleChange = async ({ email, password, remember }) => {
    if (remember) {
      const cryptedLogin = crypt(JSON.stringify({ email, password, remember }));
      localStorage.setItem('@Portal:login', cryptedLogin);
    } else {
      localStorage.removeItem('@Portal:login');
    }
  };

  const schema = Yup.object().shape({
    email: Yup.string().email('Insira um e-mail válido').required('campo obrigatório'),
    password: Yup.string().required('campo obrigatório'),
  });

  return (
    <BaseLayout>
      <ButtonGlobalStyle />

      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ errors, values, setValues }) => (
          <Container>
            <Logo isLogin height="45px" width="202px" margin="0 0 40px 0" />
            <FormControl field="email" error={errors.email}>
              <Input
                type="email"
                name="email"
                value={values.email}
                onChange={(e) => {
                  setValues({ ...values, email: e.target.value });
                  handleChange({ ...values, email: e.target.value });
                }}
                placeholder={t('fields:login.email.placeholder')}
              />
            </FormControl>
            <FormControl field="password" error={errors.email}>
              <Input
                type="password"
                name="password"
                value={values.password}
                onChange={(e) => {
                  setValues({ ...values, password: e.target.value });
                  handleChange({ ...values, password: e.target.value });
                }}
                placeholder={t('fields:login.password.placeholder')}
              />
            </FormControl>
            <Checkbox
              className="remember-me"
              name="remember"
              checked={values.remember}
              onClick={(e) => {
                setValues({ ...values, remember: e.target.checked });
                handleChange({ ...values, remember: e.target.checked });
              }}
            >
              {t('fields:login.rememberMe')}
            </Checkbox>
            <Link to="/senha/link" className="forgotPw">
              {t('fields:login.forgotMyPassword')}
            </Link>
            <Button size="large" block type="submit" margin="40px 0 0 0" loading={context.loading} color="primary">
              {t('fields:login.submit')}
            </Button>
            <Link to="/registrar" className="registerYourself">
              {t('fields:login.haventAccountYet')}
            </Link>
          </Container>
        )}
      </Formik>
    </BaseLayout>
  );
}
