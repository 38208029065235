/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { AiOutlineLogout, AiOutlineUser, AiFillLock } from 'react-icons/ai';
// import { FiBookOpen } from 'react-icons/fi';
import { Menu as AntMenu, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowDown } from 'react-icons/md';

import Logo from '~/components/Logo';
import UserInfo from '~/pages/_partials/UserInfo';
import PasswordForm from '~/pages/Profile/password';
import Profile from '~/pages/Profile/index';

// import MySubscriptions from '~/pages/MySubscriptions/index';
import { useAuth } from '~/contexts/auth';

import { decrypt } from '~/Utils/index';
import styled from 'styled-components';
import { Container } from './styles';

// Styled-components para Container personalizado
const StyledContainer = styled(Container)`
  .ant-row {
    // align-items: center; // Alinha verticalmente os itens no centro
  }
  .logo-col {
    display: flex;
    justify-content: flex-start; // Alinha o logo à esquerda
  }
  .user-info-col {
    display: flex;
    justify-content: flex-end; // Alinha UserInfo à direita
  }
`;

export default function Top() {
  const { t } = useTranslation();
  const [showProfile, setShowProfile] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  // const [showSubscriptions, setShowSubscriptions] = useState(false);
  const { name, id } =
    JSON.parse(decrypt(localStorage.getItem('@Portal:person'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@Portal:person')));
  const context = useAuth();

  function handleSignOut() {
    context.Logout();
  }

  function handleProfile() {
    setShowProfile(true);
  }

  function handlePasswordForm() {
    setShowPasswordForm(true);
  }

  // function handleSubscriptions() {
  //   setShowSubscriptions(true);
  // }

  const renderSubMenuTitle = (label) => (
    <>
      <span>{label}</span>
      <MdKeyboardArrowDown size={13} className="ant-menu-submenu-caret" />
    </>
  );

  return (
    <StyledContainer>
      <Row gutter={[0, 0]} style={{ width: '100%' }}>
        <Col className="logo-col" xs={24} sm={24} md={12} lg={12} xl={12}>
          <Logo margin="0 40px 0 40px" />
        </Col>
        <Col className="user-info-col" xs={24} sm={24} md={12} lg={12} xl={12}>
          <UserInfo>
            <AntMenu mode="horizontal" defaultSelectedKeys={['perfil']}>
              <AntMenu.SubMenu key="perfil" title={renderSubMenuTitle(name)}>
                <AntMenu.Item key="profile" onClick={handleProfile}>
                  <AiOutlineUser /> {t('menus:user_profile')}
                </AntMenu.Item>{' '}
                <AntMenu.Item key="password" onClick={handlePasswordForm}>
                  <AiFillLock /> {t('menus:user_password')}
                </AntMenu.Item>
                {/* <AntMenu.Item key="subscriptions" onClick={handleSubscriptions}>
              <FiBookOpen /> {t('menus:my_subscriptions')}
            </AntMenu.Item> */}
                <AntMenu.Item key="logout" onClick={handleSignOut}>
                  <AiOutlineLogout /> {t('menus:user_logout')}
                </AntMenu.Item>
              </AntMenu.SubMenu>
            </AntMenu>
            <Profile userID={id} visible={showProfile} onClose={() => setShowProfile(false)} />
            <PasswordForm userID={id} visible={showPasswordForm} onClose={() => setShowPasswordForm(false)} />
            {/* <MySubscriptions visible={showSubscriptions} onClose={() => setShowSubscriptions(false)} /> */}
          </UserInfo>
        </Col>
      </Row>
    </StyledContainer>
  );
}
