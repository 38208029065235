/* eslint-disable no-nested-ternary */
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Tag, message, Space } from 'antd';
import { FaEye, FaScroll, FaCoins, FaPaperclip, FaComments, FaExternalLinkAlt, FaPlus, FaBook } from 'react-icons/fa';
import DefaultLayout from '~/pages/_layouts/full';
import PageTitle from '~/components/PageTitle';
import Box from '~/components/Box';
import Button from '~/components/Button';
import { Table } from '~/components/Table';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import { decrypt, capitalize } from '~/Utils';
import ImageModal from './ImageModal';
import SpeechesModal from './SpeechesModal';
import { Title, GlobalStyle } from './styles';

export default function Congress() {
  const [recordData, setRecordData] = useState([]);
  const [loading, setLoading] = useState();
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showSpeechesModal, setShowSpeechesModal] = useState(false);
  const [selectedSpeeches, setSelectedSpeeches] = useState(null);
  // const [verifySubs, setVerifySubs] = useState(false);

  const person =
  JSON.parse(decrypt(localStorage.getItem('@Portal:person'))) !== null &&
  JSON.parse(decrypt(localStorage.getItem('@Portal:person')));

  const history = useHistory();

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const response = await api.get('/congresses');
      const congresses = response.data;

      // Criar um array de promises para todas as chamadas /by-congress/{id}
      const congressPromises = congresses.map(async (congress) => {
        const responseCourse = await api.get(`/courses/by-congress/${congress.id}`);
        return responseCourse.data;
      });

      // Esperar por todas as promises para serem resolvidas
      const courseData = await Promise.all(congressPromises);

      // Adicionar os dados do curso ao congresso correspondente
      const updatedCongresses = congresses.map((congress, index) => {
        congress.courseData = courseData[index]; // Adiciona os dados do curso ao objeto do congresso
        if (congress.free) {
          congress.remaining_subscriptions = congress.max_subscriptions - congress.total_subscriptions;
        } else {
          congress.remaining_subscriptions = congress.max_subscriptions - congress.paid_subscriptions;
        }
        return congress;
      });
      setRecordData(updatedCongresses);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleNewSubscription = (slug) => {
    history.push(`/inscricao/${slug}`);
    // history.push(`/minhas-inscricoes`);
    // history.push(`/inscricao/detalhes/${slug}`);
  };

  const handleNewCourseSubscription = (congressId) => {
    history.push(`/congressos/cursos/${congressId}`);
  };

  useEffect(() => {
    fetchRecords();
  }, []);

  const tableColumns = [
    {
      render: (text, record) => {
        return (
          <>
            <Title style={{ marginBottom: 32 }}>{capitalize(record.description)}</Title>
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    float: 'none',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    paddingBottom: '25px',
                  }}
                >
                  <Space size={[0, 6]} wrap>
                    {record.subscription &&
                      !record.subscription.paid_out &&
                      !record.subscription.tax_exempt &&
                      record.remaining_subscriptions > 0 && (
                        <Button
                          onClick={() => {
                            history.push({
                              pathname: '/pagamento',
                              state: { type: 'congress', typeId: record.id },
                            });
                          }}
                          color="primary"
                        >
                          <FaCoins style={{ fontSize: '14px' }} /> Pagar
                        </Button>
                      )}
                    {record.evaluators.length > 0 && record.subscription && (record.subscription.paid_out || record.subscription.tax_exempt) && !person.is_evaluator && (
                      <Button
                        onClick={() => history.push('/resumos/novo')}
                        color="primary"
                        style={{ marginLeft: '0.5rem' }}
                      >
                        <FaPlus /> Enviar Resumo
                      </Button>
                    )}

                    {/* if person subscribe show this */}
                    {record.subscription && (record.subscription?.paid_out || record.subscription?.tax_exempt) && (
                      <>
                        <Button
                          onClick={() => handleNewSubscription(record.slug)}
                          color="primary"
                          style={{ marginLeft: '0.5rem' }}
                        >
                          <FaEye /> Visualizar
                        </Button>
                      </>
                    )}
                    {record.courseData.length > 0 &&
                      record.subscription &&
                      (record.subscription.paid_out || record.free === 1 || record.subscription.tax_exempt === true ? (
                        <Button
                          onClick={() => handleNewCourseSubscription(record.id)}
                          color="primary"
                          style={{ marginLeft: '0.5rem' }}
                        >
                          <FaBook /> Cursos
                        </Button>
                      ) : (
                        <Button
                          onClick={() =>
                            message.info('Para liberar a área de cursos, efetue o pagamento do congresso.')
                          }
                          ghost
                          style={{ marginLeft: '0.5rem' }}
                          title="Para liberar a área de cursos, efetue o pagamento do congresso."
                        >
                          <FaBook /> Cursos
                        </Button>
                      ))}
                    {/* if person not subscribe show this and there are remains subscriptions show this */}
                    {!record.subscription && record.remaining_subscriptions > 0 && (
                      <Button
                        onClick={() => handleNewSubscription(record.slug)}
                        color="ghost"
                        style={{ marginLeft: '0.5rem' }}
                      >
                        <FaScroll /> Inscrição
                      </Button>
                    )}

                    {/* if person not subscribe show this and there are no remains subscriptions show this */}
                    {!record.subscription && record.remaining_subscriptions <= 0 && <p> Inscrições encerradas </p>}
                  </Space>
                </div>
                <Space size={[0, 6]} wrap>
                  <Tag color="cyan">
                    De <b>{new Date(record.initial_date).toLocaleString()}</b>
                  </Tag>
                  <Tag color="blue">
                    Até <b>{new Date(record.final_date).toLocaleString()}</b>
                  </Tag>
                  {record.place && (
                    <Tag color="geekblue">
                      Local <b>{record.place}</b>
                    </Tag>
                  )}

                  {record.courseData.length > 0 ? (
                    <Tag color="lime">Possui Curso(s)</Tag>
                  ) : (
                    <Tag color="red">Não Possui Curso(s)</Tag>
                  )}

                  {record.subscription && (record.subscription.paid_out || record.subscription.tax_exempt) ? (
                    <Tag color="green">{record.subscription.paid_out ? 'Pago' : 'Isento'}</Tag>
                  ) : (
                    <Tag color={record.subscription ? 'red' : 'gold'}>
                      {record.subscription
                        ? record.remaining_subscriptions > 0
                          ? 'Aguardando pagamento'
                          : 'Inscrição expirada'
                        : 'Inscrição não realizada'}
                    </Tag>
                  )}
                </Space>

                <p style={{ marginTop: '0.5rem', whiteSpace: 'pre-wrap', textAlign: 'justify' }}>{record.info}</p>

                <div style={{ display: 'flex', float: 'none', justifyContent: 'flex-start' }}>
                  {record.flyer_url && (
                    <Button
                      style={{ marginRight: '0.5rem' }}
                      onClick={() => {
                        setShowImageModal(true);
                        setSelectedImage(record.flyer_url);
                      }}
                    >
                      <FaPaperclip /> Mais informações
                    </Button>
                  )}
                  {record.url && (
                    <Button style={{ marginRight: '0.5rem' }} onClick={() => window.open(record.url)}>
                      <FaExternalLinkAlt /> Saiba mais
                    </Button>
                  )}
                  {record.speeches && record.speeches.length > 0 && (
                    <Button
                      onClick={() => {
                        setShowSpeechesModal(true);
                        setSelectedSpeeches(record.speeches);
                      }}
                    >
                      <FaComments /> Palestras
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {/* division line */}
            <div style={{ width: '100%', borderTop: '1px solid #666666', marginTop: '1rem' }} />
          </>
        );
      },
    },
  ];

  return (
    <DefaultLayout>
      <GlobalStyle />
      <PageTitle
        title="Eventos"
        // subtitle={recordData && recordData.length > 0 && t('screens:subscriptions.openedTitle')}
      />
      <Box>
        <div className="congresses-list">
          <Table columns={tableColumns} loading={loading} dataSource={recordData} rowKey="id" />
        </div>
      </Box>

      <ImageModal visible={showImageModal} image={selectedImage} onClose={() => setShowImageModal(false)} />
      <SpeechesModal
        visible={showSpeechesModal}
        speeches={selectedSpeeches}
        onClose={() => setShowSpeechesModal(false)}
      />
    </DefaultLayout>
  );

}
