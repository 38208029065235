/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import { Formik } from 'formik';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { Form, Input, DatePicker, Radio } from 'formik-antd';
import { Modal, Spin, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import locale from 'antd/es/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';

import errorHandler from '~/Utils/errorHandler';

import Row from '~/components/Row';
import { ModalFooter } from '~/components/Modal';
import InputCurrency from '~/components/Form/InputCurrency';
import FormControl from '~/components/Form/FormControl';

import api from '~/services/api';

const initialValues = {
  description: '',
  type: 'input',
  value: '',
  paid_out: '',
};

export default function MovementForm({ movementID, visible, onClose }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/movements/${movementID}`);
      setRecordData(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Formik validateOnBlur={false} validateOnChange={false} initialValues={recordData} enableReinitialize>
      {() => (
        <Modal
          title={recordData.id && `${t('screens:viewing')}: ${recordData.description}`}
          onCancel={onClose}
          style={{ maxWidth: '850px' }}
          visible={visible}
          loading={loading}
          centered
          width="100%"
          footer={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <ModalFooter type="info" loading={loading} onCancel={onClose} />
          }
        >
          <Spin spinning={loading}>
            <Form>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    <FormControl cols={{ xs: 24 }} field="type" label={t('screens:movements.data.type')}>
                      <Radio.Group disabled name="type" buttonStyle="solid">
                        <Radio.Button value="input">
                          <FaPlus /> {t('screens:movements.data.input')}
                        </Radio.Button>
                        <Radio.Button value="output">
                          <FaMinus /> {t('screens:movements.data.output')}
                        </Radio.Button>
                      </Radio.Group>
                    </FormControl>
                    <FormControl cols={{ xs: 24 }} field="description" label={t('screens:movements.data.description')}>
                      <Input name="description" disabled />
                    </FormControl>

                    {/* <FormControl
                      cols={{ xs: 24 }}
                      field="bank_statement"
                      label={t('screens:movements.data.bank_statement')}
                    >
                      <Input name="bank_statement" disabled />
                    </FormControl> */}
                    <FormControl cols={{ xs: 12 }} field="due_date" label={t('screens:movements.data.due_date')}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        name="due_date"
                        disabled
                        placeholder={t('messages:select')}
                        locale={locale}
                      />
                    </FormControl>
                    <FormControl cols={{ xs: 12 }} field="pay_day" label={t('screens:movements.data.pay_day')}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        disabled
                        name="pay_day"
                        placeholder={t('messages:select')}
                        locale={locale}
                      />
                    </FormControl>
                    <FormControl cols={{ xs: 12 }} field="value" label={t('screens:movements.data.value')}>
                      <InputCurrency name="value" currency="R$" number={false} maxLength="9" disabled />
                    </FormControl>
                    <FormControl cols={{ xs: 12 }} field="paid_out" label={t('screens:movements.data.paid_out')}>
                      <Radio.Group name="paid_out" disabled buttonStyle="solid">
                        <Radio.Button value>{t('messages:yes')}</Radio.Button>
                        <Radio.Button value={false}>{t('messages:no')}</Radio.Button>
                      </Radio.Group>
                    </FormControl>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
