/* eslint-disable no-nested-ternary */
import { Button as ButtonAntd, Card, Col, Descriptions, Row, Space, Tag, Typography } from 'antd';
import React from 'react';
import { formatPrice } from '~/Utils/format';
import { FaNewspaper } from 'react-icons/fa';
import {
  getRefuseMessage,
  handleReceiptClick,
  handleReceiptClickMovement,
  statusColor,
  translateNameStatus,
} from './functions';
import { Button } from './styles';

export const tableColumns = t => [
  {
    key: 'id',
    title: t('screens:orders.data.id'),
    dataIndex: 'id',
    align: 'left',
    responsive: ['sm', 'md', 'lg'],
    width: 150,
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: t('screens:orders.data.created_at'),
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'left',
    responsive: ['sm', 'md', 'lg'],
    // width: 200,
    ellipsis: {
      showTitle: true,
    },
    render: (_, record) =>
      `${new Date(record.created_at).toLocaleDateString()} às ${new Date(record.created_at).toLocaleTimeString()}`,
  },
  {
    title: t('screens:orders.data.payment_method_type'),
    dataIndex: 'payment_method_type',
    key: 'payment_method_type',
    align: 'left',
    responsive: ['sm', 'md', 'lg'],
    // sorter: true,
    // width: 200,
    ellipsis: {
      showTitle: true,
    },
    render: (text, record) =>
      // change color based on method type (credit card, boleto or pix)
      record?.uuid ? (
        <Tag color="pink">RECEBIDO</Tag>
      ) : (
        <Tag
          color={
            record.payment_method_type === 'CREDIT_CARD'
              ? 'purple'
              : record.payment_method_type === 'BOLETO'
              ? 'pink'
              : 'blue'
          }
        >
          {record.payment_method_type === 'CREDIT_CARD'
            ? 'CARTÃO DE CRÉDITO'
            : record.payment_method_type === 'BOLETO'
            ? 'BOLETO'
            : 'PIX'}
        </Tag>
      ),
  },
  {
    title: t('screens:orders.data.itemName'),
    dataIndex: 'items.name',
    key: 'items.name',
    responsive: ['sm', 'md', 'lg'],
    width: '600px',
    ellipsis: {
      showTitle: true,
    },
    render: (text, record) => (record?.items ? record?.items[0]?.name : record?.description),
  },
  {
    title: t('screens:orders.data.totalAmount'),
    dataIndex: 'items.unit_amount',
    key: 'items.unit_amount',
    responsive: ['sm', 'md', 'lg'],
    ellipsis: {
      showTitle: true,
    },
    render: (text, record) => {
      if (Array.isArray(record.items) && record.items.length > 0) {
        return formatPrice(record.items[0]?.unit_amount / 100);
      }
      return formatPrice(record.value);
    },
  },
  {
    title: t('screens:orders.data.charges'),
    dataIndex: 'charges',
    key: 'charges',
    align: 'center',
    ellipsis: {
      showTitle: true,
    },
    responsive: ['sm', 'md', 'lg'],
    render: (text, record) => {
      if (!record.charges) {
        if (record.uuid) {
          return (
            <>
              <p>
                <Tag color="pink">RECEBIDO</Tag>
              </p>
              <p>
                (
                {`${new Date(record.created_at).toLocaleDateString()} às ${new Date(
                  record.created_at
                ).toLocaleTimeString()}`}
                )
              </p>
            </>
          );
        }
        return <p>N/A</p>;
      }
      const charges = record?.charges.map(charge => (
        <div key={charge.id}>
          <p>
            <Tag color={statusColor(charge.status)}>{translateNameStatus(charge.status)}</Tag>
          </p>
          {charge.status === 'DECLINED' && charge?.payment_response?.code && (
            <>
              <p style={{ color: statusColor(charge.status), fontSize: 10, marginBottom: '5px' }}>
                {`${charge.payment_response.code} - ${charge.payment_response.message}`}
              </p>
              <p style={{ color: statusColor(charge.status), fontSize: 10, marginTop: '0px' }}>
                ({getRefuseMessage(charge.payment_response.code)})
              </p>
            </>
          )}
          {charge.paid_at !== null && charge.paid_at !== undefined ? (
            <p>
              ({`${new Date(charge.paid_at).toLocaleDateString()} às ${new Date(charge.paid_at).toLocaleTimeString()}`})
            </p>
          ) : null}
        </div>
      ));
      return charges;
    },
  },
  {
    title: 'Dados',
    key: 'all',
    align: 'left',
    width: '100%',
    responsive: ['xs'],
    render: (text, record) => (
      <>
        <b>Data </b>{' '}
        {`${new Date(record.created_at).toLocaleDateString()} às ${new Date(record.created_at).toLocaleTimeString()}`}
        <br />
        <b>Método </b>
        <Tag
          color={
            record.payment_method_type === 'CREDIT_CARD'
              ? 'purple'
              : record.payment_method_type === 'BOLETO'
              ? 'pink'
              : 'blue'
          }
        >
          {record.payment_method_type === 'CREDIT_CARD'
            ? 'CARTÃO DE CRÉDITO'
            : record.payment_method_type === 'BOLETO'
            ? 'BOLETO'
            : 'PIX'}
        </Tag>
        <br />
        <b>Item </b>{' '}
        {record.items[0].name && record.items[0].name.length > 25
          ? `${record.items[0].name.substring(0, 20)}...`
          : record.items[0].name}
        <br />
        <b>Total </b> {formatPrice(record.items[0].unit_amount / 100)}
        <br />
        <b>Status </b>
        {!record.charges ? (
          <p>N/A</p>
        ) : (
          record.charges.map(charge => (
            <React.Fragment key={charge.id}>
              <Tag color={statusColor(charge.status)}>{translateNameStatus(charge.status)}</Tag>
              {charge.status === 'DECLINED' && charge?.payment_response?.code && (
                <>
                  <p style={{ color: statusColor(charge.status), fontSize: 10, marginBottom: '5px' }}>
                    {`${charge.payment_response.code} - ${charge.payment_response.message}`}
                  </p>
                  <p style={{ color: statusColor(charge.status), fontSize: 10, marginTop: '0px' }}>
                    ({getRefuseMessage(charge.payment_response.code)})
                  </p>
                </>
              )}
              {charge.paid_at ? (
                <p style={{ fontSize: 10 }}>
                  (
                  {`${new Date(charge.paid_at).toLocaleDateString()} às ${new Date(
                    charge.paid_at
                  ).toLocaleTimeString()}`}
                  )
                </p>
              ) : null}
            </React.Fragment>
          ))
        )}
      </>
    ),
  },
  {
    title: 'Comprovante',
    key: 'actions',
    align: 'center',
    responsive: ['xs', 'sm', 'md', 'lg'],
    render: (text, record) => {
      const isMovement = !!record.uuid;
      const isAnyChargeValid = record?.charges?.some(
        charge =>
          charge.status === 'PAID' ||
          charge.status === 'AUTHORIZED' ||
          charge.status === 'AVAILABLE' ||
          charge.status === 'DEBITED' ||
          record.movement?.paid_out
      );
      const whatStyle = () => {
        if (isMovement && record.paid_out) {
          return '#93c06a';
        }

        if (!isMovement && isAnyChargeValid) {
          return '#93c06a';
        }

        return 'grey';
      };
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            type="primary"
            title="RECIBO"
            disabled={isMovement ? !record.paid_out : !isAnyChargeValid}
            onClick={() => {
              if (isMovement) {
                handleReceiptClickMovement(
                  record.value,
                  record.description,
                  record.created_at,
                  record.person.name,
                  record.person.cpf,
                  true
                );
              } else {
                handleReceiptClick(
                  record.items[0].unit_amount,
                  record.items[0].name,
                  record.created_at,
                  record.items[0].reference_id
                );
              }
            }}
          >
            <FaNewspaper color={whatStyle()} size="25px" />
          </Button>
        </div>
      );
    },
  },
];

export const expandedRowRender = record => (
  <Space direction="vertical" style={{ display: 'flex' }}>
    <Row gutter={16}>
      <Col span={24}>
        {/* <Space direction="vertical" style={{ display: 'flex' }}> */}
        <Descriptions
          title={<Typography.Title level={5}>DETALHES DO PEDIDO</Typography.Title>}
          layout="vertical"
          bordered
          colon={false}
          size="small"
          column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
        >
          <Descriptions.Item label="ORDER ">
            {/* <b>Id: </b> {record.id}
            <br /> */}
            {record.order_id && (
              <>
                <b>Order Id: </b> {record.order_id}
                <br />
              </>
            )}
            <b>Criada em </b>{' '}
            {`${new Date(record.created_at).toLocaleDateString()} às ${new Date(
              record.created_at
            ).toLocaleTimeString()}`}
            <br />
            <b>Alterada em: </b>{' '}
            {`${new Date(record.updated_at).toLocaleDateString()} às ${new Date(
              record.updated_at
            ).toLocaleTimeString()}`}
          </Descriptions.Item>
          <Descriptions.Item label="ITEM">
            <b>Nome: </b> {record.items ? record.items[0].name : record.description}
            <br />
            {/* <b>Quantidade: </b> {record.items[0].quantity}
            <br />
            <b>Referência: </b> {record.items[0].reference_id}
            <br /> */}
            <b>Valor Unitário: </b>{' '}
            {record.items ? formatPrice(record.items[0].unit_amount / 100) : formatPrice(record.value / 100)}
          </Descriptions.Item>
          {/* <Descriptions.Item label="CLIENTE">
            <b>Nome: </b> {record.customer.name}
            <br />
            <b>E-mail: </b> {record.customer.email}
            <br />
            {record.customer.phones.map((phone) => (
              <>
                <b>Telefone {phone.type === 'MOBILE' ? ' Celular' : ' Fixo'}: </b> {phone.country} {phone.area}{' '}
                {phone.number}
                <br />
              </>
            ))}
            <b>CPF/CNPJ (TaxId): </b> {record.customer.tax_id}
          </Descriptions.Item> */}
          {record.shipping && record.shipping !== null ? (
            <Descriptions.Item label="ENDEREÇO DE ENTREGA">
              <b>Logradouro: </b>
              {record.shipping.address.street} <br />
              <b>Número: </b> {record.shipping.address.number} <br />
              <b>Localidade: </b> {record.shipping.address.locality} <br />
              <b>Cidade: </b> {record.shipping.address.city}-{record.shipping.address.region_code} <br />
              <b>País: </b> {record.shipping.address.country} <br />
              <b>Código Postal: </b> {record.shipping.address.postal_code}
            </Descriptions.Item>
          ) : null}
        </Descriptions>
        {/* </Space> */}
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      <Col span={24}>COBRANÇAS</Col>
      {record.charges !== null ? (
        <>
          {record?.charges?.map((charge, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} style={{ width: '100%' }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                {/* CHARGES */}
                <Card
                  title={<Typography.Text style={{ fontSize: 14 }}>ID {charge.id}</Typography.Text>}
                  bordered
                  bodyStyle={{ padding: 0, backgroundColor: '#ffffff' }}
                  style={{ fontSize: 12, backgroundColor: '#fafafa' }}
                >
                  <Descriptions
                    layout="horizontal"
                    bordered
                    column={1}
                    contentStyle={{ backgroundColor: '#ffffff' }}
                    labelStyle={{ backgroundColor: '#ffffff', paddingLeft: 40, fontWeight: 'bold' }}
                    size="small"
                  >
                    <Descriptions.Item label="Status">
                      <Tag color={statusColor(charge.status)}>{translateNameStatus(charge.status)}</Tag>
                      {charge.status === 'DECLINED' && charge?.payment_response?.code && (
                        <>
                          <p> </p>
                          <p style={{ color: statusColor(charge.status), fontSize: 12, marginBottom: '5px' }}>
                            {`${charge.payment_response.code} - ${charge.payment_response.message}`}
                          </p>
                          <p style={{ color: statusColor(charge.status), fontSize: 12, marginTop: '0px' }}>
                            ({getRefuseMessage(charge.payment_response.code)})
                          </p>
                        </>
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Paga em">
                      {charge.paid_at
                        ? `${new Date(charge.paid_at).toLocaleDateString()} às ${new Date(
                            charge.paid_at
                          ).toLocaleTimeString()}`
                        : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Criada em">
                      {`${new Date(charge.created_at).toLocaleDateString()} às ${new Date(
                        charge.created_at
                      ).toLocaleTimeString()}`}
                    </Descriptions.Item>
                    <Descriptions.Item label="Descrição">{charge.description}</Descriptions.Item>
                    <Descriptions.Item label="Reference Id">{charge.reference_id}</Descriptions.Item>
                    <Descriptions.Item label="Quantia">
                      <>
                        <b>Valor:</b> {formatPrice(charge?.amount?.value / 100)}
                      </>
                      <br />
                      <>
                        <b>Total:</b> {formatPrice(charge?.amount?.summary?.total / 100)}
                      </>
                      <br />
                      <>
                        <b>Pago:</b> {formatPrice(charge?.amount?.summary?.paid / 100)}
                      </>
                      <br />
                      <>
                        <b>Reembolsado:</b> {formatPrice(charge?.amount?.summary?.refunded / 100)}
                      </>
                    </Descriptions.Item>
                    <Descriptions.Item label="Método de Pagamento">
                      {charge.payment_method.type === 'CREDIT_CARD' ? (
                        <>
                          <Tag color="purple">CARTÃO DE CRÉDITO</Tag>
                          <br />
                          <b>Bandeira: </b> {charge.payment_method.card.brand}
                          <br />
                          {/* <b>Últimos dígitos: </b> {charge.payment_method.card.first_digits}{' '}
                          {charge.payment_method.card.last_digits}
                          <br /> */}
                          <b>Nome do titular: </b> {charge.payment_method.card.holder.name}
                          <br />
                          <b>Expiração: </b> {charge.payment_method.card.exp_month}/
                          {charge.payment_method.card.exp_year}
                        </>
                      ) : null}
                      {charge.payment_method.type === 'BOLETO' ? (
                        <>
                          <Tag color="pink">{record.payment_method_type}</Tag>
                          <br />
                          <b>Id: </b> {charge.payment_method.boleto.id}
                          <br />
                          <b>Nome do titular: </b> {charge.payment_method.boleto.holder.name}
                          <br />
                          <b>E-mail: </b> {charge.payment_method.boleto.holder.email}
                          <br />
                          <b>CPF/CNPJ: </b> {charge.payment_method.boleto.holder.tax_id}
                          <br />
                          {/* <b>Logradouro: </b> {charge.payment_method.boleto.holder.address.street}
                          <br />
                          <b>Número: </b> {charge.payment_method.boleto.holder.address.number}
                          <br />
                          <b>Localidade: </b> {charge.payment_method.boleto.holder.address.locality}
                          <br />
                          <b>Cidade: </b> {charge.payment_method.boleto.holder.address.city}-
                          {charge.payment_method.boleto.holder.address.region_code}
                          <br />
                          <b>País: </b> {charge.payment_method.boleto.holder.address.country}
                          <br />
                          <b>Código Postal: </b> {charge.payment_method.boleto.holder.address.postal_code}
                          <br /> */}
                          <b>Vencimento: </b> {charge.payment_method.boleto.due_date}
                          <br />
                          {/* <b>Linha digitável: </b> {charge.payment_method.boleto.barcode}
                          <br /> */}
                          <b>Linha digitável: </b>
                          {charge.payment_method.boleto.formatted_barcode}
                          <br />
                          <b>Instruções (linha 1): </b> {charge.payment_method.boleto.instruction_lines.line_1}
                          <br />
                          <b>Instruções (linha 2): </b> {charge.payment_method.boleto.instruction_lines.line_2}
                          <br />
                          <br />
                          <ButtonAntd type="primary" onClick={() => window.open(charge.links[0].href)}>
                            Download do boleto
                          </ButtonAntd>
                        </>
                      ) : null}
                      {charge.payment_method.type === 'PIX' ? (
                        <>
                          <Tag color="blue">PIX</Tag>
                          <br />
                        </>
                      ) : null}
                    </Descriptions.Item>
                    {/* <Descriptions.Item label="Resposta do Pagamento">{charge.payment_response}</Descriptions.Item>
                    <Descriptions.Item label="Links">{charge.links}</Descriptions.Item> */}
                  </Descriptions>
                </Card>
              </Col>
            </div>
          ))}
          {record?.uuid && (
            <div style={{ width: '100%' }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                {/* CHARGES */}
                <Card
                  title={<Typography.Text style={{ fontSize: 14 }}>ID {record.id}</Typography.Text>}
                  bordered
                  bodyStyle={{ padding: 0, backgroundColor: '#ffffff' }}
                  style={{ fontSize: 12, backgroundColor: '#fafafa' }}
                >
                  <Descriptions
                    layout="horizontal"
                    bordered
                    column={1}
                    contentStyle={{ backgroundColor: '#ffffff' }}
                    labelStyle={{ backgroundColor: '#ffffff', paddingLeft: 40, fontWeight: 'bold' }}
                    size="small"
                  >
                    <Descriptions.Item label="Status">
                      <Tag color="green">DISPONÍVEL</Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label="Recebido em">
                      {new Date(record.created_at).toLocaleDateString()} às $
                      {new Date(record.created_at).toLocaleTimeString()}`
                    </Descriptions.Item>
                    <Descriptions.Item label="Descrição">{record.description}</Descriptions.Item>
                    <Descriptions.Item label="UUID">{record.uuid}</Descriptions.Item>
                    <Descriptions.Item label="Quantia">
                      <>
                        <b>Valor:</b> {formatPrice(record?.value)}
                      </>
                    </Descriptions.Item>
                    <Descriptions.Item label="Método de Pagamento">
                      <Tag color="pink">RECEBIDO</Tag>
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </Col>
            </div>
          )}
        </>
      ) : null}
    </Row>
  </Space>
);
