import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';

import i18n from '~/config/i18n';
import Hotjar from '@hotjar/browser';
import App from './App';

const siteId = 3560000;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const consoleError = console.error;
console.error = (...args) => {
  if (/ResizeObserver loop limit exceeded/.test(args[0])) {
    return; // Ignora esse erro específico
  }
  consoleError(...args);
};

ReactDOM.render(
  <>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
    <script src="https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js" />
  </>,
  document.getElementById('root')
);
