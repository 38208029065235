import React, { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Input, message, Select } from 'antd';
import { useHistory } from 'react-router-dom';

import { decrypt } from '~/Utils';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import PageTitle from '~/components/PageTitle';
import Button from '~/components/Button';
import { TableHeader, Table } from '~/components/Table';
import Box from '~/components/Box';
import Pagination from '~/components/Pagination';

import DefaultLayout from '../_layouts/full';
import { tableColumns } from './table';

export default function Abstract() {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(false);
  const [subareaOptions, setSubareaOptions] = useState([]);
  const [recordData, setRecordData] = useState([]);
  const [search, setSearch] = useState(null);
  const [searchBySubarea, setSearchBySubarea] = useState('');
  const { t } = useTranslation();
  const history = useHistory();
  const person =
    JSON.parse(decrypt(localStorage.getItem('@Portal:person'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@Portal:person')));

  const fetchResumes = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/resumes', {
        params: {
          page,
          search,
          subarea_id: searchBySubarea,
        },
      });

      setMeta(data.meta);
      setRecordData(data.data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchSubareaOptions = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/resumes/available/subareas');
      console.log(data);
      setSubareaOptions(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleNew = () => {
    history.push('/resumos/novo');
  };

  const handleEdit = async id => {
    history.push(`/resumos/${id}/edicao`);
  };

  const handleAssign = async id => {
    setLoading(true);
    try {
      await api.put(`resumes/assign/${id}`);
      message.success(t('messages:successAssigned'));
      await fetchResumes();
      handleEdit(id);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleDelete = async id => {
    setLoading(true);
    try {
      await api.delete(`resumes/${id}`);
      message.success(t('messages:success'));
      await fetchResumes();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleDownloadCertificate = record => {
    api
      .post(`subscriptions/resume/certificate/${record.id}`)
      .then(response => {
        window.open(response.data, '_blank');
      })
      .catch(error => {
        console.error('Erro ao baixar o certificado:', error);
      });
  };

  const handleDownloadApproved = recordId => {
    api
      .get(`/resumes/approved/${recordId}`)
      .then(response => {
        window.open(response.data, '_blank');
      })
      .catch(error => {
        console.error('Erro ao baixar o certificado:', error);
      });
  };

  const params = {
    person,
    t,
    handleAssign,
    handleEdit,
    handleDelete,
    handleDownloadCertificate,
    handleDownloadApproved,
  };

  useEffect(() => {
    fetchSubareaOptions();
  }, []);

  useEffect(() => {
    fetchResumes();
  }, [page, search, searchBySubarea]);

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:abstracts.title')}>
        {!person.is_evaluator && (
          <Button loading={loading} color="primary" onClick={handleNew}>
            <FaPlus />
            {t('screens:abstracts.btnNew')}
          </Button>
        )}
      </PageTitle>
      <Box>
        <TableHeader>
          <Select
            placeholder={t('screens:abstracts.data.subarea')}
            style={{ minWidth: 150, marginRight: 10 }}
            name="subarea_id"
            onChange={id => setSearchBySubarea(id)}
            allowClear
            optionFilterProp="children"
          >
            {subareaOptions &&
              subareaOptions.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
          <Input.Search onSearch={value => setSearch(value)} className="search-field" />
        </TableHeader>
        <Table
          pagination={false}
          rowKey="id"
          loading={loading}
          dataSource={recordData}
          columns={tableColumns(params)}
          // scroll={{ x: 800 }}
        />
        <Pagination
          onChange={value => {
            setPage(value);
          }}
          meta={meta}
        />
      </Box>
    </DefaultLayout>
  );
}
