import React, { useState, useContext, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, matchPath } from 'react-router-dom';

import { crypt, decrypt } from '~/Utils/index';
import errorHandler from '~/Utils/errorHandler';

import cities from '~/data/cities.json';
import api from '../services/api';

const AuthContext = createContext({});
export function AuthProvider({ children }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [person, setPerson] = useState(null);
  const [loading, setLoading] = useState(false);

  const Login = async ({ values, router }) => {
    setLoading(true);
    try {
      const response = await api.post('/auth', values);
      const data = JSON.parse(response.data);
      api.defaults.headers.Authorization = `Bearer ${data.token}`;

      setPerson(data.person);

      const cryptedPerson = crypt(JSON.stringify(data.person));
      const cryptedToken = crypt(data.token);
      const cryptedVersion = crypt(data.version);
      const cryptedNeeds = crypt(JSON.stringify({ cities, needs: data.needs }));

      localStorage.setItem('@Portal:needs', cryptedNeeds);
      localStorage.setItem('@Portal:person', cryptedPerson);
      localStorage.setItem('@Portal:token', cryptedToken);
      localStorage.setItem('@Portal:version', cryptedVersion);

      const redirect = localStorage.getItem('@Portal:redirectTo');

      if (redirect) {
        localStorage.removeItem('@Portal:redirectTo');
        message.info(t('messages:redirectedSuccesfully'));
        setTimeout(() => {
          window.location.replace(decrypt(redirect));
        }, 1000);
      }
    } catch (error) {
      if (error?.response?.status === 406) {
        message.info(t('messages:inactivePerson'));
        router.push({ pathname: '/senha/link', state: { email: values.email } });
        setLoading(false);
        return;
      }

      const errors = errorHandler(error);

      if (errors.email) {
        message.error(errors.email);
      }
    }
    setLoading(false);
  };

  async function Logout() {
    try {
      setPerson(null);
      localStorage.removeItem('@Portal:person');
      localStorage.removeItem('@Portal:token');
      localStorage.removeItem('@Portal:version');
      localStorage.removeItem('@Portal:needs');

      message.success(t('messages:successLogout'));
      history.push('/');
      await api.post('/auth/logout');
    } catch (error) {
      errorHandler(error);
    }
  }

  useEffect(() => {
    const cryptedPerson = localStorage.getItem('@Portal:person');
    const storagedPerson = decrypt(cryptedPerson);

    const cryptedToken = localStorage.getItem('@Portal:token');
    const storagedToken = decrypt(cryptedToken);

    if (storagedToken && storagedPerson) {
      setPerson(JSON.parse(storagedPerson));
      api.defaults.headers.Authorization = `Bearer ${storagedToken}`;

      if (window.location.pathname === '/registrar') {
        message.info(t('messages:alreadyLoggedIn'));
        setTimeout(() => {
          window.location.replace('/');
        }, 1000);
      }
    }

    if (!storagedPerson && !storagedToken && window.location.pathname !== '/') {
      const match =
        matchPath(window.location.pathname, { path: '/inscricao/:slug', exact: true, strict: false }) ||
        matchPath(window.location.pathname, { path: '/inscricao/curso/:slug', exact: true, strict: false });

      if (match) {
        localStorage.setItem('@Portal:redirectTo', crypt(window.location.pathname));
        message.warning(t('messages:logInPlease'));
        setTimeout(() => {
          window.location.replace('/');
        }, 1000);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider value={{ signed: !!person, person, loading, Login, Logout }}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};

AuthProvider.defaultProps = {
  children: <></>,
};
