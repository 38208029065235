import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { formatCPF } from '@brazilian-utils/brazilian-utils';
import { Spin, Tag, Divider } from 'antd';
import BaseLayout from '~/pages/_layouts/base';

import Button from '~/components/Button';
import Logo from '~/components/Logo';
import api from '~/services/api';

import errorHandler from '~/Utils/errorHandler';
import { Container } from './styles';

export default function ReceiptVerify({ match }) {
  const [recordData, setRecordData] = useState({
    person: {},
    paid_out: true,
  });
  const [loading, setLoading] = useState(true);
  const { uuid } = match.params;
  const history = useHistory();

  const fetchRecordData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/movements/show/${uuid}`);
      setRecordData(data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRecordData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseLayout>
      <Container>
        <Logo isLogin height="45px" width="202px" margin="0 0 40px 0" />
        {!recordData.paid_out && (
          <div className="content">
            <h1>Verificador de Comprovante</h1>
            <h2>
              <b style={{ color: '#F7A005' }}>Pagamento ainda não realizado.</b>
            </h2>
          </div>
        )}
        <div className="content" style={{ display: !recordData.paid_out ? 'none' : '' }}>
          <h1>Verificador de Comprovante</h1>
          <Divider style={{ marginBottom: '2rem' }}>
            <h2>
              <b>Transação</b>
            </h2>
          </Divider>
          {loading ? (
            <Spin spinning />
          ) : (
            <>
              <h2>
                <b>
                  <Tag color={recordData.type === 'input' ? 'green' : 'volcano'}>
                    {recordData.type === 'input' ? 'Entrada' : 'Saída'}
                  </Tag>
                </b>
              </h2>
              <h2>
                <Tag color="gold">
                  <b>{recordData.uuid && recordData.uuid.toUpperCase()}</b>
                </Tag>
              </h2>
              <h2>
                Transação gerada em:{' '}
                <b>
                  {`${new Date(recordData.created_at).toLocaleDateString()} às
                              ${new Date(recordData.created_at).toLocaleTimeString()}`}
                </b>
              </h2>
              <br />
              <br />
              <h2>
                Descrição: <b>{recordData.description}</b>
              </h2>
              <h2>
                Valor: <b>R$ {recordData.value}</b>
              </h2>
              <h2>
                Data do vencimento: <b>{`${new Date(recordData.due_date).toLocaleDateString()}`}</b>
              </h2>
              <h2>
                Data do pagamento: <b>{`${new Date(recordData.pay_day).toLocaleDateString()}`}</b>
              </h2>
            </>
          )}
          <Divider style={{ margin: '2rem 0 2rem 0' }}>
            <h2>
              <b>Pessoa</b>
            </h2>
          </Divider>
          {loading ? (
            <Spin spinning />
          ) : (
            <>
              <h2>
                Nome: <b>{recordData.person.name}</b>
              </h2>
              <h2>
                {recordData.person.foreign ? 'Documento' : 'CPF'}:{' '}
                <b>{recordData.person.foreign ? recordData.person.document : formatCPF(recordData.person.cpf)}</b>
              </h2>
            </>
          )}
        </div>
        <Button loading={loading} onClick={() => history.push('/')} color="primary">
          Voltar para Home
        </Button>
      </Container>
    </BaseLayout>
  );
}

ReceiptVerify.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    }).isRequired,
  }),
};
ReceiptVerify.defaultProps = {
  match: { params: { uuid: '' } },
};
