/* eslint-disable no-case-declarations */
import i18next from 'i18next';
import { message } from 'antd';
import history from '~/services/history';

const online = navigator.onLine;

const showMessage = (text) => {
  message.error(text);
};

const handleResponseData = (response) => {

  if (!response.data) {
    showMessage(i18next.t('messages:randomError'));
    return {};
  }
  const responseData = JSON.parse(response.data);

  if (!responseData.message) {
    showMessage(i18next.t('messages:randomError'));
    return {};
  }

  const { message: errorMessage } = responseData;
  showMessage(errorMessage);
  return {};
};

const handlePaymentError = (response) => {
  if (!response.data) {
    return false;
  }
  const responseData = JSON.parse(response.data);

  if (!responseData.message) {
    return false;
  }

  // verify if error messages are setting in the response
  if (!responseData.message.error_messages) {
    return false;
  }

  // get all errors messages from responseData.message.error_messages array
  const errorMessages = responseData.message.error_messages.map((error) => `${error.code}: ${error.description}`);
  showMessage(errorMessages.join(', '));

  return true;
};

const handleErrorStatus = (response, e422) => {
  const { status } = response;
  switch (status) {
    case 422:
      if (response.data && e422) {
        return JSON.parse(response.data).errors;
      }
      showMessage(i18next.t('messages:invalidData'));
      break;
    case 404:
      history.push('/404');
      break;
    case 403:
      showMessage(i18next.t('messages:unauthorized'));
      break;
    case 429:
      showMessage(i18next.t('messages:tooManyRequests'));
      break;
    case 401:
      showMessage(i18next.t('messages:authError'));
      break;
    case 500:
      showMessage(i18next.t('messages:randomError'));
      break;
    case 406:
      showMessage(i18next.t('messages:notAcceptable'));
      break;
    case 400:
      if (handlePaymentError(response)) {
        break;
      }
      showMessage(i18next.t('messages:badRequest'));
      break;
    default:
      handleResponseData(response);
  }

  return {};
};

const errorHandler = (exception, e422 = true) => {
  const { response } = exception;

  if (online) {
    if (response) {
      return handleErrorStatus(response, e422);
    }
    if (exception.message === 'Network Error') {
      showMessage(i18next.t('messages:backendOff'));
    } else {
      showMessage(i18next.t('messages:randomError'));
    }
  } else {
    showMessage(i18next.t('messages:offline'));
  }
  return {};
};

export default errorHandler;
