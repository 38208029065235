/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { message } from 'antd';
import { Formik } from 'formik';
import { Input } from 'formik-antd';
import * as Yup from 'yup';

import Logo from '~/components/Logo';
import FormControl from '~/components/Form/FormControl';
import BaseLayout from '~/pages/_layouts/base';
import api from '~/services/api';

import Button from '~/components/Button';
import { Container, ButtonGlobalStyle } from './styles';

const schema = Yup.object().shape({
  email: Yup.string().email('Insira um e-mail válido').required('campo obrigatório'),
});

export default function Remember({ location }) {
  const initialValues = { email: location?.state?.email || '' };

  const { t } = useTranslation();
  const history = useHistory();

  async function handleSubmit(email, { setErrors, resetForm }) {
    try {
      await api.post('/password/link', email);
      message.success(t('messages:successReset'));
      history.push('/');
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const data = JSON.parse(error.response.data);
        setErrors(data.errors);
      } else {
        message.error(t('messages:randomError'));
        resetForm();
      }
    }
  }

  return (
    <BaseLayout>
      <ButtonGlobalStyle />

      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ errors, isSubmitting }) => (
          <Container>
            <Logo isLogin height="45px" width="202px" margin="0 0 40px 0" />
            <div className="info-section">
              <h2>Esqueceu sua senha?</h2>
              <p>Digite o seu email abaixo, para resetar sua senha.</p>
            </div>
            <FormControl field="email" error={errors.email}>
              <Input type="email" name="email" placeholder={t('fields:remember.email.placeholder')} />
            </FormControl>
            <Button size="large" block type="submit" loading={isSubmitting} color="primary">
              {t('fields:remember.submit')}
            </Button>
            <Link to="/">{t('screens:rememberPassword.back')}</Link>
          </Container>
        )}
      </Formik>
    </BaseLayout>
  );
}
