import React, { useState, useEffect } from 'react';
import { FaEye, FaFilePdf } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Tag, message } from 'antd';
// import { Select } from 'formik-antd';

import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import PageTitle from '~/components/PageTitle';
import Button from '~/components/Button';
import { Table, TableActions } from '~/components/Table';
import Box from '~/components/Box';
import DefaultLayout from '../_layouts/full';
import MovementForm from './form';

export default function Movement() {
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [showMovement, setShowMovement] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const { t } = useTranslation();

  const fetchRecordData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/movements');
      setRecordData(data.data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRecordData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShow = (uuid) => {
    setShowMovement(true);
    setSelectedRecord(uuid);
  };

  const handleGenerateReceipt = async (data) => {
    setLoading(true);
    try {
      await api
        .get(`/movements/generate/receipt/${data.uuid}`, {
          method: 'GET',
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;

          link.setAttribute('download', `recibo_entrada_${data.uuid}_${new Date().toLocaleDateString()}.pdf`);
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {
      if (error.response.status === 400) {
        message.error('Comprovante incapaz de emissao.');
      }
      errorHandler(error);
    }
    setLoading(false);
  };

  const tableColumns = [
    {
      title: t('screens:movements.data.type'),
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => (
        <Tag color={record.type === 'input' ? 'green' : 'volcano'}>{record.type === 'input' ? 'Entrada' : 'Saída'}</Tag>
      ),
    },
    {
      title: t('screens:movements.data.value'),
      dataIndex: 'value',
      key: 'value',
      render: (text) => `R$ ${text}`,
    },
    {
      title: t('screens:movements.data.paid_out'),
      dataIndex: 'paid_out',
      key: 'paid_out',
      render: (record, text) =>
        text.paid_out ? (
          <div>
            {text.pay_day && (
              <p style={{ fontSize: 10, marginBottom: 5 }}>Pago em: {new Date(text.pay_day).toLocaleDateString()}</p>
            )}
            <Tag color="green">{t('messages:yes')}</Tag>
          </div>
        ) : (
          <div>
            <Tag color="volcano">{t('messages:no')}</Tag>
          </div>
        ),
    },
    {
      title: t('screens:movements.data.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('screens:movements.data.person_id'),
      dataIndex: 'person.name',
      key: 'person.name',
      render: (record, text) => text.person && text.person.name,
    },
    {
      title: t('screens:movements.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => (
        <TableActions>
          <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleShow(record.uuid)}>
            <FaEye />
          </Button>
          <Button
            style={{ margin: 'auto', visibility: record.paid_out && record.type === 'input' ? 'visible' : 'hidden' }}
            size="small"
            title="Comprovante"
            onClick={() => handleGenerateReceipt(record)}
          >
            <FaFilePdf />
          </Button>
        </TableActions>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:movements.title')} />
      <Box>
        <Table rowKey="uuid" loading={loading} dataSource={recordData} columns={tableColumns} />
      </Box>
      <MovementForm movementID={selectedRecord} visible={showMovement} onClose={() => setShowMovement(false)} />
    </DefaultLayout>
  );
}
