import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function Row({ children, ...props }) {
  return (
    <Container gutter={16} {...props}>
      {children}
    </Container>
  );
}

Row.propTypes = {
  children: PropTypes.node,
};
Row.defaultProps = {
  children: <></>,
};
