import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { formatCPF } from '@brazilian-utils/brazilian-utils';
import { Spin, Tag, Divider } from 'antd';
import BaseLayout from '~/pages/_layouts/base';

import Button from '~/components/Button';
import Logo from '~/components/Logo';
import api from '~/services/api';

import errorHandler from '~/Utils/errorHandler';
import { Container } from './styles';

export default function CertificateVerifyResume({ match }) {
  const [recordData, setRecordData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = match.params;
  const history = useHistory();

  const fetchRecordData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/resumes/verifyResume/${id}`);
      console.log(data);
      setRecordData(JSON.parse(data));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRecordData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const issued = !recordData?.is_evaluator && recordData.status === 'Aprovado' && recordData.presence;

  return (
    <BaseLayout>
      <Container>
        <Logo isLogin height="45px" width="202px" margin="0 0 40px 0" />
        {!issued && (
          <div className="content">
            <h1>Verificador de Certificado</h1>
            <h2>
              <b style={{ color: '#F7A005' }}>Certificado ainda não emitido.</b>
            </h2>
          </div>
        )}
        <div className="content" style={{ display: !issued ? 'none' : '' }}>
          <h1>Verificador de Certificado</h1>
          <Divider style={{ marginBottom: '2rem' }}>
            <h2>
              <b>Resumo</b>
            </h2>
          </Divider>
          {loading ? (
            <Spin spinning />
          ) : (
            <>
              <h2>
                <b>ID: </b>
                <Tag color="gold">
                  <b>{recordData?.id}</b>
                </Tag>
              </h2>
              <h2>
                <b>Título: </b>
                <b>{recordData?.title}</b>
              </h2>
            </>
          )}
          <Divider style={{ margin: '2rem 0 2rem 0' }}>
            <h2>
              <b>Pessoa</b>
            </h2>
          </Divider>
          {loading ? (
            <Spin spinning />
          ) : (
            <>
              <h2>
                Nome: <b>{recordData?.person?.name}</b>
              </h2>
              <h2>
                {recordData?.person?.foreign ? 'Documento' : 'CPF'}:{' '}
                <b>{recordData?.person?.foreign ? recordData?.person?.document : formatCPF(recordData?.person?.cpf)}</b>
              </h2>
              <h2>
                Data de Nascimento: <b>{new Date(recordData?.person?.dob).toLocaleDateString()}</b>
              </h2>
            </>
          )}
          <Divider style={{ margin: '2rem 0 2rem 0' }}>
            <h2>
              <b>{recordData?.course ? 'Curso' : 'Congresso'}</b>
            </h2>
          </Divider>
          {loading ? (
            <Spin spinning />
          ) : (
            <>
              <h2>
                Nome: <b>{recordData?.course ? recordData?.course?.description : recordData?.congress?.description}</b>
              </h2>
            </>
          )}
        </div>

        <Button loading={loading} onClick={() => history.push('/')} color="primary">
          Voltar para Home
        </Button>
      </Container>
    </BaseLayout>
  );
}

CertificateVerifyResume.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  }),
};
CertificateVerifyResume.defaultProps = {
  match: { params: { id: '' } },
};
