import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '~/components/Form/FormControl';
import Row from '~/components/Row';
import { Input, Select } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import { Card, Col, Divider } from 'antd';
import { DivTitle } from './styles';

export default function Comparison({ values }) {
  const { t } = useTranslation();
  const originalValues = JSON.parse(values?.original_values);

  // Função para verificar se o valor mudou
  const hasChanged = field => originalValues?.[field] !== values?.[field];

  const cardTitle = wich => (
    <Divider style={{ margin: '1.5rem 0' }}>
      <DivTitle>
        {wich === 'original' ? t('screens:abstracts.data.original_values') : t('screens:abstracts.data.new_values')}
      </DivTitle>
    </Divider>
  );

  // Estilos para campos alterados
  const changedStyle = { color: 'red' };

  return (
    <Col style={{ display: 'flex', gap: '30px' }}>
      <Row>
        <Card title={cardTitle('original')}>
          <FormControl
            cols={{ xl: 6, xs: 12, sm: 12 }}
            field="congress_id"
            label={t('screens:abstracts.data.congress')}
          >
            <Select name="congress_id" disabled value={originalValues?.congress?.description} />
          </FormControl>
          <FormControl cols={{ xl: 6, xs: 12, sm: 12 }} field="subarea_id" label={t('screens:abstracts.data.subarea')}>
            <Select name="subarea_id" value={originalValues?.sub_area?.name} disabled />
          </FormControl>
          <FormControl cols={{ xs: 24 }} field="title" label={t('screens:abstracts.data.title')}>
            <Input name="title" disabled value={originalValues?.title} />
          </FormControl>
          <FormControl cols={{ xs: 24 }} field="resume" label={t('screens:abstracts.data.abstract')}>
            <p>{originalValues?.resume}</p>
          </FormControl>
          {[1, 2, 3, 4, 5].map(n => (
            <FormControl
              key={`original_word_${n}`}
              cols={{ xl: 12, xs: 24, sm: 12 }}
              label={`${t('screens:abstracts.data.word')} ${n}`}
            >
              <Input name={`word_${n}`} disabled value={originalValues?.[`word_${n}`]} />
            </FormControl>
          ))}
        </Card>
      </Row>

      <Row>
        <Card title={cardTitle('new')}>
          <FormControl
            cols={{ xl: 6, xs: 12, sm: 12 }}
            field="congress_id"
            label={t('screens:abstracts.data.congress')}
          >
            <Select
              name="congress_id"
              disabled
              value={values?.congress?.description}
              style={hasChanged('congress') ? changedStyle : {}}
            />
          </FormControl>
          <FormControl cols={{ xl: 6, xs: 12, sm: 12 }} field="subarea_id" label={t('screens:abstracts.data.subarea')}>
            <Select
              name="subarea_id"
              disabled
              value={values?.subArea?.name}
              style={hasChanged('subArea') ? changedStyle : {}}
            />
          </FormControl>
          <FormControl cols={{ xs: 24 }} field="title" label={t('screens:abstracts.data.title')}>
            <Input name="title" disabled value={values?.title} style={hasChanged('title') ? changedStyle : {}} />
          </FormControl>
          <FormControl cols={{ xs: 24 }} field="resume" label={t('screens:abstracts.data.abstract')}>
            <p style={hasChanged('resume') ? changedStyle : {}}>{values?.resume}</p>
          </FormControl>
          {[1, 2, 3, 4, 5].map(n => (
            <FormControl
              key={`updated_word_${n}`}
              cols={{ xl: 12, xs: 24, sm: 12 }}
              label={`${t('screens:abstracts.data.word')} ${n}`}
            >
              <Input
                name={`word_${n}`}
                disabled
                value={values?.[`word_${n}`]}
                style={hasChanged(`word_${n}`) ? changedStyle : {}}
              />
            </FormControl>
          ))}
        </Card>
      </Row>
    </Col>
  );
}

Comparison.propTypes = {
  values: PropTypes.instanceOf.isRequired,
};
