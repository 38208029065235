/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { FaCopy } from 'react-icons/fa';
import { Form } from 'formik-antd';
import { Button, Col, Input, Row, Tooltip, Modal, Spin, Alert, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import 'moment/locale/pt-br';
import { formatPrice } from '~/Utils/format';
import useClipboard from './useClipboard';

const createHistory = require('history').createBrowserHistory;

const history = createHistory();

const initialValues = {
  id: '0',
  text: '',
  links: [],
};

const { TextArea } = Input;

export default function BoletoForm({ boletoData, visible, onClose }) {
  const copyToClipboard = useClipboard();
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [textCopy, setTextCopy] = useState(false);

  useEffect(() => {
    if (boletoData !== undefined && boletoData.charges !== undefined) {
      setRecordData(boletoData.charges[0]);
    }
  }, [boletoData]);

  const showAlert = (type, message) => <Alert message={message} type={type} showIcon />;

  useEffect(() => {
    if (textCopy) {
      setTimeout(() => {
        setTextCopy(false);
      }, 3000);
    }
  }, [textCopy]);

  const handleOk = () => {
    // redireciona para a PaymentTanks ( página de agradecimento )
    history.push('/pagamento/obrigado');
    const pathUrl = window.location.href;
    window.location.href = pathUrl;
  };

  return (
    <Formik validateOnBlur={false} validateOnChange={false} initialValues={recordData} enableReinitialize>
      {({ errors, isSubmitting, submitForm, values, resetForm, setValues }) => (
        <Modal
          title="SEU BOLETO"
          onCancel={handleOk}
          style={{ maxWidth: '950px' }}
          visible={visible}
          loading={loading}
          centered
          width="100%"
          onOk={handleOk}
          footer={[
            <Button key="back" onClick={handleOk}>
              Fechar
            </Button>,
          ]}
        >
          <Spin spinning={loading}>
            <Form>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p>id: {values.id}</p>
                  {/* <p>Você receberá uma cópia no seu e-mail ({values?.payment_method?.boleto?.holder?.email})</p> */}
                  <p>Valor: {formatPrice(values?.amount?.value / 100)}</p>
                  <p>Válido até: {`${new Date(values?.payment_method?.boleto?.due_date).toLocaleDateString()}`}</p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  {values.links && <Image width={200} src={values?.links[1]?.href} />}
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Button type="primary" onClick={() => window.open(values?.links[0]?.href)}>
                    Download do boleto
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p>Copiar a linha digitável.</p>
                  <Input.Group compact>
                    <Input
                      rows={2}
                      disabled
                      // size="large"
                      style={{
                        width: 'calc(100% - 32px)',
                        textAlign: 'center',
                      }} // fontSize: '0.8rem'
                      value={values?.payment_method?.boleto?.formatted_barcode}
                    />
                    <Tooltip title="copiar">
                      <Button
                        icon={<FaCopy />}
                        onClick={() => copyToClipboard(values?.payment_method?.boleto?.barcode, setTextCopy)}
                      />
                    </Tooltip>
                  </Input.Group>
                  {textCopy && showAlert('success', 'Texto copiado para área de transferência')}
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
