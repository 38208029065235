import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, message } from 'antd';
import { FaFilePdf } from 'react-icons/fa';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import errorHandler from '~/Utils/errorHandler';
import Button from '~/components/Button';
import { Container, Image, Actions } from './styles';

export default function Thumb({ file, fileURL, onDelete }) {
  const [fileToModal, setFileToModal] = useState(null);
  const [fileModal, setFileModal] = useState(false);
  const { t } = useTranslation();
  // console.log(file);

  const handleDownload = async () => {
    try {
      axios({
        url: fileURL,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name);
        document.body.appendChild(link);
        link.click();
      });

      message.success(t('messages:success'));
    } catch (error) {
      errorHandler(error);
    }
  };
  const handleDownloadWithouId = async (fileWithoutID) => {
    try {
      axios({
        url: fileWithoutID.base64Url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileWithoutID.name);
        document.body.appendChild(link);
        link.click();
      });

      message.success(t('messages:success'));
    } catch (error) {
      errorHandler(error);
    }
  };
  return (
    file && (
      <Container>
        <Image>
          {file && file.uid && file.type === 'application/pdf' && (
            <div style={{ textAlign: 'center' }}>
              <FaFilePdf size={24} color="red" />
              <p style={{ fontSize: '8px' }}>{file.name}</p>
            </div>
          )}
          {file && file.uid && file.type !== 'application/pdf' && (
            <button
              type="button"
              onClick={() => {
                setFileToModal(file);
                setFileModal(true);
              }}
            >
              <img src={file.base64Url} alt="file" />
            </button>
          )}
          {file && file.id && file.extension !== 'pdf' && (
            <button
              type="button"
              onClick={() => {
                setFileToModal(file);
                setFileModal(true);
              }}
            >
              <img src={fileURL} alt="file" />
            </button>
          )}
          {file && file.id && file.extension === 'pdf' && (
            <div style={{ textAlign: 'center' }}>
              <FaFilePdf size={24} color="red" />
              <p style={{ fontSize: '8px' }}>{file.name}</p>
            </div>
          )}
        </Image>
        <Actions>
          <Button
            color="primary"
            size="small"
            style={{ marginRight: '1rem' }}
            id={`download${file && file.id}`}
            onClick={() => {
              if (file && file.id) {
                handleDownload(file && file.id);
              } else {
                handleDownloadWithouId(file);
              }
            }}
          >
            <p>Baixar</p>
          </Button>
          <Button
            size="small"
            id={`delete${file && file.id}`}
            type="button"
            onClick={() => {
              onDelete();
            }}
          >
            <p>Excluir</p>
          </Button>
        </Actions>
        <Modal
          onCancel={() => {
            setFileToModal(null);
            setFileModal(false);
          }}
          title="Visualização"
          visible={fileModal}
          footer={null}
          onClose={() => {
            setFileToModal(null);
            setFileModal(false);
          }}
        >
          <img
            style={{
              maxWidth: '460px',
              maxHeight: '460px',
              display: 'flex',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            src={fileToModal && fileToModal.id ? fileURL : fileToModal && fileToModal.base64Url}
            alt="file"
          />
        </Modal>
      </Container>
    )
  );
}

Thumb.propTypes = {
  file: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onDelete: PropTypes.func,
  fileURL: PropTypes.string,
};

Thumb.defaultProps = {
  file: null,
  onDelete: null,
  fileURL: null,
};
