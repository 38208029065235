import { Popconfirm, Tag } from 'antd';
import React from 'react';
import { FaBook, FaCheckCircle, FaPencilAlt, FaRegFileArchive, FaRegTrashAlt } from 'react-icons/fa';
import Button from '~/components/Button';
import { TableActions } from '~/components/Table';
import { getColorByAbstractStatus } from '~/Utils';
import { ButtonCertified } from './styles';

export const tableColumns = ({
  person,
  t,
  handleAssign,
  handleEdit,
  handleDelete,
  handleDownloadCertificate,
  handleDownloadApproved,
}) => [
  {
    title: t('screens:abstracts.data.title'),
    key: 'congress.description',
    width: '45%',
    // ellipsis: true,
    responsive: ['sm', 'md', 'lg'],
    render: (text, record) => (
      <>
        {record.title && <p style={{ margin: 0, padding: 0 }}>{record.title}</p>}
        {record.congress && record.congress.description && (
          <p style={{ fontSize: 'small', margin: 0, padding: 0, color: 'gray' }}>({record.congress.description})</p>
        )}
      </>
    ),
  },
  {
    title: t('screens:abstracts.data.subarea'),
    key: 'subArea.name',
    ellipsis: true,
    responsive: ['sm', 'md', 'lg'],
    render: (text, record) => record.subArea && record.subArea.name,
  },
  {
    title: t('screens:abstracts.data.updated_at'),
    dataIndex: 'updated_at',
    key: 'updated_at',
    responsive: ['sm', 'md', 'lg'],
    width: 200,
    render: text => new Date(text).toLocaleString(),
  },
  {
    title: t('screens:abstracts.data.words'),
    dataIndex: 'word',
    key: 'word',
    ellipsis: true,
    responsive: ['md', 'lg'],
    render: (text, record) => {
      console.log(record);
      let words;
      if (record.word_1) {
        words = `${record.word_1}; `;
      }
      if (record.word_2) {
        words += `${record.word_2}; `;
      }
      if (record.word_3) {
        words += `${record.word_3}; `;
      }
      if (record.word_4) {
        words += `${record.word_4}; `;
      }
      if (record.word_5) {
        words += `${record.word_5}; `;
      }
      return words;
    },
  },
  {
    title: t('screens:abstracts.data.status'),
    dataIndex: 'status',
    key: 'status',
    width: 120,
    responsive: ['sm', 'md', 'lg'],
    render: (text, record) => (
      <Tag color={getColorByAbstractStatus(record.status, person?.is_evaluator, record.original_values)}>
        {record.original_values && record.status === 'Pendente' ? 'Corrigido/Pendente' : record.status}
      </Tag>
    ),
  },
  {
    title: t('screens:abstracts.data.actions'),
    dataIndex: 'actions',
    key: 'actions',
    width: '200px',
    align: 'center',
    responsive: ['sm', 'md', 'lg'],
    render: (text, record) => (
      <TableActions>
        {person?.is_evaluator && !record.evaluator_id && (
          <Popconfirm
            title={t('messages:confirmAssign')}
            okText={t('messages:yes')}
            onConfirm={() => handleAssign(record.id)}
            cancelText={t('messages:no')}
          >
            <Button size="small" title="Atribuir">
              <FaBook />
            </Button>
          </Popconfirm>
        )}
        {((person?.is_evaluator && record.status === 'Pendente' && record.evaluator_id === person?.id) ||
          (!person?.is_evaluator && record.status === 'Aguardando correção')) && (
          <Button size="small" title="Editar" onClick={() => handleEdit(record.id)}>
            <FaPencilAlt />
          </Button>
        )}
        {!person?.is_evaluator && record.person?.id === person?.id && (
          <Popconfirm
            title={t('messages:confirmDelete')}
            okText={t('messages:yes')}
            onConfirm={() => handleDelete(record.id)}
            cancelText={t('messages:no')}
          >
            <Button size="small" title="Excluir">
              <FaRegTrashAlt />
            </Button>
          </Popconfirm>
        )}
        {!person?.is_evaluator &&
          record.person?.id === person?.id &&
          record.status === 'Aprovado' &&
          record.presence && (
            <ButtonCertified size="small" title="Baixar Certificado" onClick={() => handleDownloadCertificate(record)}>
              <FaRegFileArchive />
            </ButtonCertified>
          )}
        {record.status === 'Aprovado' && (
          <Button size="small" title="Baixar Carta de Aceite" onClick={() => handleDownloadApproved(record.id)}>
            <FaCheckCircle />
          </Button>
        )}
      </TableActions>
    ),
  },
  {
    title: 'Dados',
    key: 'all',
    responsive: ['xs'],
    width: '100%',
    // ellipsis: true,
    render: (text, record) => (
      <>
        <p style={{ fontWeight: 'bold' }}>{record.congress && record.congress.description}</p>
        <p>{record.subArea && record.subArea.name}</p>
        <p>{new Date(record.updated_at).toLocaleString()}</p>
        <TableActions>
          {person?.is_evaluator && !record.evaluator_id && (
            <Popconfirm
              title={t('messages:confirmAssign')}
              okText={t('messages:yes')}
              onConfirm={() => handleAssign(record.id)}
              cancelText={t('messages:no')}
            >
              <Button size="small" title="Atribuir">
                <FaBook />
              </Button>
            </Popconfirm>
          )}
          {((person?.is_evaluator && record.status === 'Pendente' && record.evaluator_id === person?.id) ||
            (!person?.is_evaluator && record.status === 'Aguardando correção')) && (
            <Button size="small" title="Editar" onClick={() => handleEdit(record.id)}>
              <FaPencilAlt /> Editar
            </Button>
          )}
          {!person?.is_evaluator && record.person?.id === person?.id && (
            <Popconfirm
              title={t('messages:confirmDelete')}
              okText={t('messages:yes')}
              onConfirm={() => handleDelete(record.id)}
              cancelText={t('messages:no')}
            >
              <Button size="small" title="Excluir">
                <FaRegTrashAlt /> Excluir
              </Button>
            </Popconfirm>
          )}
          {!person?.is_evaluator &&
            record.person?.id === person?.id &&
            record.status === 'Aprovado' &&
            record.presence && (
              <ButtonCertified
                size="small"
                title="Baixar Certificado"
                onClick={() => handleDownloadCertificate(record)}
              >
                <FaRegFileArchive />
              </ButtonCertified>
            )}
          {record.status === 'Aprovado' && (
            <Button size="small" title="Baixar Carta de Aceite" onClick={() => handleDownloadApproved(record.id)}>
              <FaCheckCircle />
            </Button>
          )}
        </TableActions>
      </>
    ),
  },
];
