import api from '~/services/api';

import errorHandler from '~/Utils/errorHandler';

const sandboxURL = 'https://stc.sandbox.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.lightbox.js';
const productionURL = 'https://stc.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.lightbox.js';

export async function loadPagseguroScripts() {
  try {
    const script = document.createElement('script');

    const { data } = await api.get('settings/sandbox');

    const url = data.sandbox ? sandboxURL : productionURL;

    script.src = url;
    script.async = true;
    script.id = 'pgScript';

    document.body.appendChild(script);
  } catch (error) {
    errorHandler(error);
  }
}
