import styled, { createGlobalStyle } from 'styled-components';
import { lighten } from 'polished';

export const Title = styled.h2`
  color: ${(props) => props.theme.color1};
  font-size: 18px;
  line-height: 21px;
`;

export const GlobalStyle = createGlobalStyle`
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{
  background-color: unset !important;
}
.ant-table-tbody td .ant-card:hover{
    box-shadow: 1px 1px 15px #00000045;
  }
.ant-page-header-heading-sub-title{
  padding: 0.2rem 1rem;
  color: ${(props) => lighten(0.15, props.theme.color8)};
  border-radius: 5px;
  border: 1px solid ${(props) => lighten(0.15, props.theme.color8)};
}
// .ant-page-header-heading-sub-title:hover{
//   background-color: ${(props) => props.theme.color8};
//   color: #fff;
//   font-weight: unset;
//   border: unset;
// }
.ant-table-tbody > tr > td{
  border-bottom: unset;
}
.courses-list .ant-table-content .ant-table-thead{
  display: none;
}
`;

export const FormBox = styled.div`
  width: 100%;
  margin-left: 0.5rem;
  margin-right: auto;
`;
