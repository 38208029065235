/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { FaCopy } from 'react-icons/fa';
import { Form } from 'formik-antd';
import { Button, Col, Input, Row, Tooltip, Modal, Spin, Alert, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import 'moment/locale/pt-br';
import { formatPrice } from '~/Utils/format';
import useClipboard from './useClipboard';

const createHistory = require('history').createBrowserHistory;

const history = createHistory();

const initialValues = {
  id: '0',
  text: '',
  links: [],
};

const { TextArea } = Input;

export default function PixForm({ pixData, visible, onClose }) {
  const copyToClipboard = useClipboard();
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [textCopy, setTextCopy] = useState(false);

  useEffect(() => {
    if (pixData !== undefined && pixData.qr_codes !== undefined) {
      console.log('pixData inside pixForm', pixData.qr_codes[0].text);
      setRecordData(pixData.qr_codes[0]);
    }
  }, [pixData]);

  const showAlert = (type, message) => <Alert message={message} type={type} showIcon />;

  useEffect(() => {
    if (textCopy) {
      setTimeout(() => {
        setTextCopy(false);
      }, 3000);
    }
  }, [textCopy]);

  const handleOk = () => {
    // redireciona para a PaymentTanks ( página de agradecimento )
    history.push('/pagamento/obrigado');
    const pathUrl = window.location.href;
    window.location.href = pathUrl;
  }

  return (
    <Formik validateOnBlur={false} validateOnChange={false} initialValues={recordData} enableReinitialize>
      {({ errors, isSubmitting, submitForm, values, resetForm, setValues }) => (
        <Modal
          title="PIX. O código de pagamento é válido por 1 hora."
          onCancel={handleOk}
          style={{ maxWidth: '950px' }}
          visible={visible}
          loading={loading}
          centered
          width="100%"
          onOk={handleOk}
          footer={[
            <Button key="back" onClick={handleOk}>
              Fechar
            </Button>,
          ]}
        >
          <Spin spinning={loading}>
            <Form>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p>id: {values.id}</p>
                  <p>Valor: {formatPrice(values?.amount?.value / 100)}</p>
                  <p>Escanear o QR Code</p>
                  <ul>
                    <li>Abra o aplicativo do seu banco</li>
                    <li>Selecione a opção PIX</li>
                    <li>Selecione a opção PAGAR</li>
                    <li>Aponte a câmera do celular para o QR Code</li>
                  </ul>
                  {values.links && <Image width={200} src={values?.links[0]?.href} />}
                  <p>
                    Ou copiar o código abaixo para realizar o pagamento. Escolha a opção PIX copia e cola do seu banco.
                  </p>
                  <Input.Group compact>
                    <TextArea
                      rows={2}
                      disabled
                      // size="large"
                      style={{
                        width: 'calc(100% - 32px)',
                      }} // fontSize: '0.8rem', textAlign: 'center'
                      value={values?.text}
                    />
                    <Tooltip title="copiar">
                      <Button icon={<FaCopy />} onClick={() => copyToClipboard(values?.text, setTextCopy)} />
                    </Tooltip>
                  </Input.Group>
                  {textCopy && showAlert('success', 'Texto copiado para área de transferência')}
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
