import React from 'react';
import { useHistory } from 'react-router-dom';

import BaseLayout from '~/pages/_layouts/base';

import Button from '~/components/Button';
import Logo from '~/components/Logo';
import { Container } from './styles';

export default function PaymentThanks() {
  const history = useHistory();
  return (
    <BaseLayout>
      <Container>
        <Logo isLogin height="45px" width="202px" margin="0 0 40px 0" />
        <div className="content">
          <h1>Obrigado por comprar!</h1>
          <h2>
            Seu pagamento está em fase de análise. Você poderá receber um contato da nossa equipe para confirmar sua
            transação.
          </h2>
        </div>

        <Button onClick={() => history.push('/')} color="primary">
          Voltar para Home
        </Button>
      </Container>
    </BaseLayout>
  );
}
